import clsx from 'clsx'
import {MetronicIcon} from '../inputs/MetronicIcon'
import {Paper, PaperBackgroundVariant} from '../utils/Paper'

export interface OccupancyCounterProps {
  checkinCounter?: number
  checkoutCounter?: number
  title: string
  counter: number | string
  variant?: PaperBackgroundVariant
  className?: string
  hideCheckInOut?: boolean
}

export const OccupancyCounter = ({
  checkinCounter,
  checkoutCounter,
  counter,
  title,
  className,
  hideCheckInOut,
}: OccupancyCounterProps) => {
  return (
    <Paper rounded className={clsx('p-5', className)}>
      <div className='d-flex justify-content-center'>
        <span className='text-primary' style={{fontSize: '3rem'}}>
          {counter}
        </span>
      </div>
      {!hideCheckInOut && (
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <MetronicIcon
              size='lg'
              iconType='Navigation'
              iconName='Sign-out'
              className='svg-icon-success me-2'
            />
            <span className='fs-3 text-success'>{checkinCounter}</span>
          </div>
          <div className='d-flex align-items-center'>
            <MetronicIcon
              size='lg'
              iconType='Navigation'
              iconName='Sign-in'
              className='svg-icon-danger me-2'
            />
            <span className='fs-3 text-danger'>{checkoutCounter}</span>
          </div>
        </div>
      )}
      <div className={clsx('d-flex justify-content-center', {'mt-5': hideCheckInOut})}>
        <span className='fs-5 fw-light text-primary text-uppercase text-center'>{title}</span>
      </div>
    </Paper>
  )
}
