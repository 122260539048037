import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {WidgetF06Model} from '../../../../../../models/fnb/WidgetModel'

export interface TopOutletsTooltipProps {
  data?: WidgetF06Model[]
}

export const TopOutletsTooltip = ({data}: TopOutletsTooltipProps) => {
  const {key} = useBarChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.outletCode === key)
    }
  }, [data, key])

  if (!hoveredData) {
    return null
  }

  return (
    <MarkdownTooltip>{`
### ${hoveredData.outletName}

**Total**: ${hoveredData.total}
`}</MarkdownTooltip>
  )
}
