import {ReactNode} from 'react'
import {Badge} from '../../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../../components/inputs/Button'
import {GuestModelStatus} from '../../../../../../models/GuestModel'

export interface RsvpGuestStatusBadgeProps {
  guest: {status: GuestModelStatus}
  children?: ReactNode
  className?: string
}

export const RsvpGuestStatusBadge = ({guest, children, className}: RsvpGuestStatusBadgeProps) => {
  return (
    <Badge className={className} variant={getBadgeVariant(guest.status)} uppercase>
      {children}
    </Badge>
  )
}

const getBadgeVariant = (status: GuestModelStatus): ColorVariant => {
  switch (status) {
    case 'pending':
      return 'warning'
    case 'confirmed':
      return 'success'
    case 'active':
    case 'submitted':
      return 'primary'
    case 'sent':
      return 'secondary'
    case 'printed':
      return 'info'
    case 'declined':
    case 'cancelled':
      return 'danger'
    case 'queue':
    case 'maybe':
    case 'closed':
    default:
      return 'dark'
  }
}
