import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {CounterChart} from '../../components/charts/CounterChart'
import {OccupancyCounter} from '../../components/charts/OccupancyCounter'
import {useAuthRole} from '../../components/hooks/useAuthRole'
import {DatePickerInput} from '../../components/inputs'
import {EventModel} from '../../models/ems/EventModel'
import {WidgetModel as EmsWidgetModel} from '../../models/ems/WidgetModel'
import {WidgetModel as FnbWidgetModel} from '../../models/fnb/WidgetModel'
import {useEventDashboardWidgetData} from '../../modules/default/ems/hooks/useEventDashboardWidgetData'
import {GetDefaultEvent} from '../../modules/default/ems/redux/EmsCRUD'
import {EarningsTypePieChart} from '../../modules/default/fnb/components/dashboard/EarningsTypePieChart/EarningsTypePieChart'
import {useOutletDashboardWidgetData} from '../../modules/default/fnb/hooks/useOutletDashboardWidgetData'
import {DateRange} from '../../utils/DateRange'
import {DateUtil} from '../../utils/DateUtil'
import {Paper} from '../../components/utils/Paper'
import {TopOutletsBarChartWidget} from '../../modules/default/fnb/components/charts/TopOutletsBarChartWidget/TopOutletsBarChartWidget'
import {VoucherIssuedBarChartWidget} from '../../modules/default/fnb/components/charts/VoucherIssuedBarChartWidget/VoucherIssuedBarChartWidget'
import {OutletHourlyRevenue} from '../../modules/default/fnb/components/charts/OutletHourlyRevenue/OutletHourlyRevenue'
import {GuestCountPerProduct} from '../../modules/default/ems/components/charts/GuestCountPerProduct/GuestCountPerProduct'
import {useRsvpEventHelper} from '../../modules/default/ems/hooks/useRsvpEventHelper'
import {GuestCountPerStatus} from '../../modules/default/ems/components/charts/GuestCountPerStatus/GuestCountPerStatus'
import clsx from 'clsx'
import {EventMercatorChart} from '../../modules/default/ems/components/charts/EventMercatorChart/EventMercatorChart'
import {EventCalendar} from '../../modules/default/ems/components/charts/EventCalendar/EventCalendar'
import {Toolbar} from '../../components/layouts/DefaultLayout/Toolbar'
import {HeaderContent} from '../../components/layouts/DefaultLayout/Header/HeaderContent'
import {Button} from '../../components/inputs/Button'
import {Link, useRouteMatch} from 'react-router-dom'
import {MonthPaginationInput} from '../../components/inputs/MonthPaginationInput'
import {useBooleanState} from '../../components/hooks/useBooleanState'
import {MetronicIcon} from '../../components/inputs/MetronicIcon'
import {EventSearchInput} from '../../modules/default/ems/components/inputs/EventSearchInput'
import {ToBeDeliveredProductsChart} from '../../modules/default/ems/components/charts/ToBeDeliveredProductsChart/ToBeDeliveredProductsChart'
import {TopicCounterCards} from '../../modules/default/ems/components/charts/TopicCounterCards/TopicCounterCards'

export const DashboardWrapper = () => {
  const [selectedEvent, setSelectedEvent] = useState<EventModel | null>(null)
  const {
    enableState: showCalendarData,
    disableState: hideCalendarData,
    state: isCalendarDataShown,
  } = useBooleanState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
  const auth = useAuthRole()
  const {isGuestEvent} = useRsvpEventHelper({event: selectedEvent})
  const match = useRouteMatch()

  const hasFnb = useMemo(() => {
    return Boolean(auth?.getApplication('FNB')) && !isGuestEvent
  }, [auth, isGuestEvent])

  const eventDateRange = useMemo(() => {
    if (selectedEvent) {
      return new DateRange(
        DateUtil.getDateFromApiString(selectedEvent.startedAt),
        DateUtil.getDateFromApiString(selectedEvent.endedAt)
      )
    }
  }, [selectedEvent])
  const {data: emsData, isLoading} = useEventDashboardWidgetData({
    date: selectedDate,
    dailyWidgets: DAILY_EMS_WIDGETS,
    eventCode: selectedEvent?.code,
    month: selectedMonth,
    monthlyWidgets: MONTHLY_EMS_WIDGETS,
  })
  const {data: fnbData} = useOutletDashboardWidgetData({
    date: selectedDate,
    widgets: FNB_WIDGETS,
    eventCode: selectedEvent?.code,
  })

  const handleCalendarMonthChange = useCallback(
    (date: Date) => {
      setSelectedMonth(date)
      hideCalendarData()
    },
    [hideCalendarData]
  )

  const occupancyCounters = useMemo(() => {
    return (
      <>
        {emsData?.WIDGETE02?.map((gate) => {
          return (
            <div key={gate.gateCode} className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12'>
              <OccupancyCounter
                className='h-100'
                hideCheckInOut
                counter={Number(gate.quantity)}
                title={gate.gateName}
              />
            </div>
          )
        })}
      </>
    )
  }, [emsData?.WIDGETE02])

  const resetSelectedDate = useCallback((event: EventModel | null) => {
    if (event) {
      const today = moment()
      const eventEnd = moment(DateUtil.getDateFromApiString(event.endedAt))
      const earliestDate = moment.min(today, eventEnd).toDate()
      setSelectedDate(earliestDate)
    } else {
      setSelectedDate(null)
    }
  }, [])

  const resetDefaultEvent = useCallback(async () => {
    if (!selectedEvent) {
      const {data} = await GetDefaultEvent()
      setSelectedEvent(data)
    }
  }, [selectedEvent])

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }, [])

  useEffect(() => {
    resetDefaultEvent()
  }, [resetDefaultEvent])

  useEffect(() => {
    if (isLoading === false) {
      showCalendarData()
    }
  }, [isLoading, showCalendarData])

  useEffect(() => {
    resetSelectedDate(selectedEvent)
  }, [resetSelectedDate, selectedEvent])

  return (
    <>
      <HeaderContent>
        <div className='d-flex align-items-center h-100 mx-3' style={{width: '15rem'}}>
          <EventSearchInput
            className='w-100'
            noMargin
            onChange={setSelectedEvent}
            value={selectedEvent}
            placeholder='Search Events'
          />
        </div>
      </HeaderContent>
      <Toolbar title='Dashboard'>
        <div className='h-100 d-flex align-items-center justify-content-end'>
          <Link
            to={{
              pathname: '/ems/event/new',
              state: {breadcrumbs: [{label: 'Dashboard', link: '/dashboard'}], from: match.url},
            }}
          >
            <Button variant='primary' size='sm'>
              <MetronicIcon iconType='Navigation' iconName='Plus' />
              New Event
            </Button>
          </Link>
        </div>
      </Toolbar>
      {selectedEvent && (
        <div>
          <div className='row g-6'>
            <div className='col-xs-12 col-md-3'>
              <DatePickerInput
                noClear
                minDate={eventDateRange?.getStart() || undefined}
                maxDate={eventDateRange?.getEnd() || undefined}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
          {selectedDate && (
            <>
              <div className='row g-6 mb-6'>
                <div className='col-xs-12 col-xxl-6'>
                  <Paper rounded className='p-5 h-100'>
                    <MonthPaginationInput
                      value={selectedMonth}
                      onChange={handleCalendarMonthChange}
                    />
                    <div className='overflow-auto'>
                      <EventCalendar
                        data={isCalendarDataShown ? emsData.WIDGETE07 : undefined}
                        date={selectedMonth}
                      />
                    </div>
                  </Paper>
                </div>
                <div className='col-xs-12 col-xxl-6'>
                  <Paper rounded className='p-5 h-100'>
                    <div style={{height: '100%', minHeight: '50vh'}}>
                      <EventMercatorChart />
                    </div>
                  </Paper>
                </div>
                <div className='col-md-6 col-xs-12'>
                  <CounterChart label='Total visitors for this day' value={emsData.WIDGETE01} />
                </div>
                <div className='col-md-6 col-xs-12'>
                  <CounterChart label='Total visitors' value={emsData.WIDGETE03} />
                </div>
              </div>
              <div className='row g-6 mb-6'>{occupancyCounters}</div>
            </>
          )}
          {isGuestEvent && (
            <>
              <div className='row g-6 mb-6'>
                <div className='col-xs-12'>
                  <CounterChart label='Total guests' value={emsData.WIDGETE05} />
                </div>
                {emsData.WIDGETE08?.length ? (
                  <div
                    className={clsx('col-xs-12 col-xl-6')}
                    style={{
                      height: '50rem',
                    }}
                  >
                    <GuestCountPerProduct
                      className='h-100 w-100'
                      event={selectedEvent}
                      data={emsData.WIDGETE08}
                    />
                  </div>
                ) : null}
                {emsData.WIDGETE09?.length ? (
                  <div
                    className={clsx('col-xs-12 col-xl-6')}
                    style={{
                      height: '50rem',
                    }}
                  >
                    <ToBeDeliveredProductsChart
                      className='h-100 w-100'
                      event={selectedEvent}
                      data={emsData.WIDGETE09}
                    />
                  </div>
                ) : null}
                {emsData.WIDGETE06?.length ? (
                  <div
                    className={clsx('col-xs-12', {
                      'col-xl-6': emsData.WIDGETE04?.length,
                    })}
                    style={{
                      height: '50rem',
                    }}
                  >
                    <Paper className='h-100 w-100 p-10 pt-8 d-flex flex-column' rounded>
                      <p className='mb-3 fs-1'>Guest status</p>
                      <div className='flex-grow-1 p-10'>
                        <GuestCountPerStatus data={emsData.WIDGETE06} event={selectedEvent} />
                      </div>
                    </Paper>
                  </div>
                ) : null}
              </div>
            </>
          )}
          {hasFnb && (
            <div className='row g-6 mb-6'>
              {(fnbData?.WIDGETF07?.length && (
                <div
                  className='col-xs-12'
                  style={{
                    height: '50rem',
                  }}
                >
                  <Paper className='h-100 w-100 p-10 pt-8 d-flex flex-column' rounded>
                    <p className='fs-1 mb-3'>Vouchers issued</p>
                    <div className='flex-grow-1 p-10'>
                      <VoucherIssuedBarChartWidget data={fnbData?.WIDGETF07} />
                    </div>
                  </Paper>
                </div>
              )) ||
                null}
              {selectedDate && (
                <>
                  {(fnbData.WIDGETF06?.length && (
                    <div
                      className='col-xs-12'
                      style={{
                        height: '50rem',
                      }}
                    >
                      <Paper className='h-100 w-100 p-10 pt-8 d-flex flex-column' rounded>
                        <p className='mb-3 fs-1'>Top outlets</p>
                        <div className='flex-grow-1 p-10'>
                          <TopOutletsBarChartWidget data={fnbData?.WIDGETF06} />
                        </div>
                      </Paper>
                    </div>
                  )) ||
                    null}
                  <div className='col-md-6 col-xs-12'>
                    <CounterChart
                      label='Total outlet sales for this day'
                      value={fnbData.WIDGETF01}
                    />
                  </div>
                  <div className='col-md-6 col-xs-12'>
                    <CounterChart label='Total outlet sales' value={fnbData.WIDGETF05} />
                  </div>
                  {(fnbData.WIDGETF02?.length && (
                    <div className='col-xs-12'>
                      <EarningsTypePieChart datum={fnbData.WIDGETF02} />
                    </div>
                  )) ||
                    null}
                  {(fnbData.WIDGETF09?.length && (
                    <div className='col-xs-12'>
                      <div style={{height: '40rem'}}>
                        <Paper className='h-100 w-100 p-10 pt-8 d-flex flex-column' rounded>
                          <p className='mb-3 fs-1'>Hourly outlet sales</p>
                          <div className='flex-grow-1 p-10'>
                            <OutletHourlyRevenue datum={fnbData.WIDGETF09} />
                          </div>
                        </Paper>
                      </div>
                    </div>
                  )) ||
                    null}
                </>
              )}
            </div>
          )}
          {isGuestEvent && selectedDate && (
            <TopicCounterCards
              className='mb-6'
              date={selectedDate}
              event={selectedEvent}
              data={emsData.WIDGETE10}
            />
          )}
        </div>
      )}
    </>
  )
}

const DAILY_EMS_WIDGETS: (keyof EmsWidgetModel)[] = [
  'WIDGETE01',
  'WIDGETE02',
  'WIDGETE03',
  'WIDGETE05',
  'WIDGETE06',
  'WIDGETE08',
  'WIDGETE09',
  'WIDGETE10',
]

const MONTHLY_EMS_WIDGETS: (keyof EmsWidgetModel)[] = ['WIDGETE07']

const FNB_WIDGETS: (keyof FnbWidgetModel)[] = [
  'WIDGETF01',
  'WIDGETF02',
  'WIDGETF05',
  'WIDGETF06',
  'WIDGETF07',
  'WIDGETF09',
]
