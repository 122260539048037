import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {EventModel} from '../../../../models/ems/EventModel'
import {FilterModel} from '../../../../models/FilterModel'
import {FromProp} from '../../components/tables/BookingProductTable/CustomerProductBookingDetailTable'
import {TicketTable} from '../../components/tables/TicketTable/TicketTable'
import {TicketCardGrid} from '../../components/TicketCard/TicketCardGrid'
import {actions} from '../../redux/CustomerPortalRedux'

interface TicketsPageByProductAndEventProps {
  event?: EventModel
  bookingProductCode?: string
  toPath?: FromProp
}

export const TicketsPageByProductAndEvent = ({
  event,
  bookingProductCode,
  toPath,
}: TicketsPageByProductAndEventProps) => {
  const tickets = useRootStateSelector((state) => state.customerPortal.ticketsByBookingProduct)
  const searchDebounce = useDebounce(200)
  const {setFilter} = useEntityFilter('customer-portal-ticket-by-bookingProduct')
  const dispatch = useDispatch()
  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.ticketsByBookingProduct.search())
    })
  }, [dispatch, searchDebounce])

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        type: ['e-ticket'],
        eventCode: event?.code,
        bookingProductCode,
      },
    }
    return filters
  }, [bookingProductCode, event?.code])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  return (
    <>
      <div className='d-none d-md-block'>
        <TicketTable
          bookingProductCode={bookingProductCode}
          initialFilters={initialFilters}
          data={tickets}
          onFilter={handleFilter}
          onRefresh={refresh}
          event={event}
          toPath={toPath}
        />
      </div>
      <TicketCardGrid
        initialFilters={initialFilters}
        className='d-md-none'
        onRefresh={refresh}
        data={tickets}
        onFilter={handleFilter}
      />
    </>
  )
}
