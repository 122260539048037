import {ReactNode, useCallback, useMemo, useRef} from 'react'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Bar} from '@visx/shape'
import {scaleBand, scaleLinear} from '@visx/scale'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import {GridColumns} from '@visx/grid'
import {AxisBottom, AxisLeft} from '@visx/axis'
import {TooltipContextProvider} from '../TooltipContext/TooltipContextProvider'
import {useDefaultColorScalePreset} from '../hooks/useDefaultColorScalePreset'

export interface BarChartDatum {
  key: string
  value: number
  label: string
}

export interface BarChartProps {
  datum: BarChartDatum[]
  tooltip?: ReactNode
  yLabel?: string
  xLabel?: string
}

export const HorizontalBarChart = ({datum, tooltip, xLabel, yLabel}: BarChartProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const domain = useMemo(() => datum.map((d) => d.key), [datum])

  const getBarColor = useDefaultColorScalePreset({domain})

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handleBarSegmentMouseMove = useCallback(
    (datum: BarChartDatum) => {
      showTooltip({
        tooltipData: String(datum.key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const leftAxisTickFormatter = useCallback(
    (key: string, index: number) => {
      return datum[index]?.label || ''
    },
    [datum]
  )

  return (
    <div
      className={'h-100 w-100 d-flex'}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
    >
      <ParentSize>
        {({width, height}) => {
          const xMax = width - width * 0.1
          const yMax = height
          const yScale = scaleBand<string>({
            range: [0, yMax],
            round: true,
            domain: datum.map((d) => d.key),
            padding: 0.4,
          })
          const xScale = scaleLinear<number>({
            range: [0, xMax],
            round: true,
            domain: [0, Math.max(...datum.map((d) => d.value))],
          })
          const numTicks = xMax < 500 ? 5 : 10
          return (
            <svg
              className='overflow-visible'
              style={{paddingLeft: `${width * 0.1}px`}}
              width={width}
              height={height}
            >
              <GridColumns
                strokeDasharray='2,2'
                stroke='#A0A0A0'
                scale={xScale}
                width={xMax}
                height={yMax}
                numTicks={numTicks}
              />
              {yLabel && (
                <text
                  x={xMax / 2}
                  y={yMax + 35}
                  fill='white'
                  fontSize={12}
                  fontWeight='bold'
                  textAnchor='middle'
                >
                  {yLabel}
                </text>
              )}
              {xLabel && (
                <text x={-5} y={-10} fill='white' fontSize={12} fontWeight='bold' textAnchor='end'>
                  {xLabel}
                </text>
              )}
              <AxisLeft
                scale={yScale}
                stroke='white'
                tickFormat={leftAxisTickFormatter}
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 11,
                  textAnchor: 'end',
                  width: width * 0.2,
                })}
              />
              <AxisBottom
                top={yMax}
                numTicks={numTicks}
                tickFormat={xScale.tickFormat(1)}
                scale={xScale}
                stroke='white'
                tickStroke='white'
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
              <Group>
                {datum.map((d) => {
                  const height = yScale.bandwidth()
                  const constrainedHeight = Math.min(height, 20)
                  const width = xScale(d.value) ?? 0
                  const barY = (yScale(d.key) ?? 0) + height / 2 - constrainedHeight / 2
                  return (
                    <Bar
                      onMouseMove={() => handleBarSegmentMouseMove(d)}
                      key={d.key}
                      x={0}
                      y={barY}
                      width={width}
                      height={constrainedHeight}
                      fill={getBarColor(d.key)}
                    />
                  )
                })}
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && tooltip && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
            {tooltip}
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}
