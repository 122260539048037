import {useCallback} from 'react'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {BookingModel} from '../../../../../models/ems/BookingModel'

export interface BookingActionsProps {
  selected: TableRowId[]
  bookings: BookingModel[]
  onCancel?: (booking: BookingModel[]) => void
  loading?: boolean
}

export const BookingTableActions = ({
  onCancel,
  bookings,
  selected,
  loading,
}: BookingActionsProps) => {
  const idExtractor = useCallback((item: BookingModel) => item.code, [])

  return (
    <>
      <TableSelectionAction
        disabled={loading}
        variant='danger'
        onClick={onCancel}
        idExtractor={idExtractor}
        items={bookings}
        selected={selected}
        filter={bookingIsCancellable}
      >
        Cancel
      </TableSelectionAction>
    </>
  )
}

export const bookingIsCancellable = (booking: BookingModel) => {
  return booking.status !== 'cancelled'
}

export const bookingIsEditable = (booking: BookingModel) => {
  return booking.status === 'confirmed'
}
