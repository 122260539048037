import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {
  ReservationPortalCreateParams,
  ReservationPortalModel,
} from '../../../models/ems/ReservationMedel'
import {HasCode} from '../../../utils/idExtractor'
import {Plural} from '../../../utils/Plural'
import {SwalUtils} from '../../../utils/SwalUtils'
import {ReservationFormValues} from '../components/DrawerForm/ReservationFormDrawer/ReservationForm'
import {releaseReservation} from '../redux/CustomerPortalCRUD'

export interface UseReservationDataOptions {
  onRefresh?: () => void
  onRefreshCallback?: () => void
}

export const initialValues: ReservationFormValues = {
  customer: null,
  eventCode: '',
  products: [],
  locations: [],
}

export const useReservationData = ({
  onRefresh,
  onRefreshCallback,
}: UseReservationDataOptions = {}) => {
  const {pushError, push} = useAlerts()
  const {setIsLoading, isKeyLoading, isLoading} = useLoadingState()
  const mapReservationDataToFormValues = useCallback(
    (reservation: ReservationPortalModel): ReservationFormValues => {
      if (!reservation.customer) {
        throw new Error('Reservation has no customer data.')
      }
      if (!reservation.event) {
        throw new Error('Reservation has no event data.')
      }
      if (!reservation.products) {
        throw new Error('Reservation has no product data.')
      }

      if (!reservation.locations) {
        throw new Error('Reservation has no location data.')
      }

      const formValues: ReservationFormValues = {
        customer: reservation.customer,
        eventCode: reservation.event.code,
        products: [],
        locations: [],
      }

      if (reservation.products) {
        reservation.products.forEach((product) => {
          formValues.products.push({
            count: product.qty || 1,
            value: product.code,
            type: 'product',
            id: product.code,
          })
        })
      }

      return formValues
    },
    []
  )

  const release = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncSpecifyReason(
        async (reason) => {
          const doneLoading = setIsLoading(codes)
          try {
            await releaseReservation(codes, reason)
            push({
              message: `${pluralizeReservation(codes)} successfully released.`,
              timeout: 5000,
              variant: 'success',
            })
            onRefresh && onRefresh()
            onRefreshCallback && onRefreshCallback()
          } catch (e) {
            pushError(e)
          } finally {
            doneLoading()
          }
        },
        {
          titleText: 'Release reservation',
          text: 'Please specify a reason.',
          confirmButtonText: 'Release!',
          icon: 'question',
          cancelButtonText: "Don't Cancel!",
        }
      )
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleReleaseBulk = useCallback(
    (data: HasCode[]) => {
      release(data.map((reservations) => reservations.code))
    },
    [release]
  )

  const handleReleaseSingle = useCallback(
    (data: HasCode) => {
      release([data.code])
    },
    [release]
  )

  const isReservationLoading = useCallback(
    (reservation: HasCode) => {
      return isKeyLoading(reservation.code)
    },
    [isKeyLoading]
  )

  return useMemo(
    () => ({
      releaseSingle: handleReleaseSingle,
      releaseBulk: handleReleaseBulk,
      isReservationLoading,
      mapReservationDataToFormValues,
      isLoading,
    }),
    [
      handleReleaseSingle,
      mapReservationDataToFormValues,
      handleReleaseBulk,
      isReservationLoading,
      isLoading,
    ]
  )
}

const pluralizeReservation = (array: unknown[]) => {
  return Plural.pluralize(array.length, 'Reservation', 'Reservations')
}

export const getReservationPayload = (
  values: ReservationFormValues
): ReservationPortalCreateParams => {
  if (!values.customer) {
    throw new Error('Invalid form data.')
  }
  const payload: ReservationPortalCreateParams = {
    customerCode: values.customer.code,
    eventCode: values.eventCode || '',
    products: [],
    locations: values.locations || [],
  }

  values.products.forEach((product) => {
    if (product.type && product.value && product.count > 0) {
      if (product.type === 'product') {
        payload.products.push({code: product.value, qty: product.count})
      }
    }
  })

  return payload
}
