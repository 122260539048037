import {useMemo} from 'react'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {usePieChartTooltipContext} from '../../../../../../components/charts/PieChart/usePieChartTooltip'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {GuestCountPerStatusModel} from '../../../../../../models/ems/WidgetModel'
import {GuestModelStatus} from '../../../../../../models/GuestModel'
import {RsvpGuestStatusBadge} from '../../../../svc/components/tables/RSVPGuestTable/RSVPGuestStatusBadge'

export interface GuestCountPerStatusTooltipProps {
  data?: GuestCountPerStatusModel[]
  event?: EventModel | null
}

export const GuestCountPerStatusTooltip = ({data, event}: GuestCountPerStatusTooltipProps) => {
  const {key} = usePieChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.status === key)
    }
  }, [data, key])

  if (!hoveredData || !key) {
    if (key) {
      return (
        <div>
          <RsvpGuestStatusBadge className='mb-1' guest={{status: key as GuestModelStatus}}>
            {key}
          </RsvpGuestStatusBadge>
          <MarkdownTooltip>{`**Event**: ${hoveredData?.eventName || event?.name || 'Unknown'}
**Total**: ${hoveredData?.total || 0}
`}</MarkdownTooltip>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <RsvpGuestStatusBadge className='mb-1' guest={{status: hoveredData.status}}>
        {hoveredData.status}
      </RsvpGuestStatusBadge>
      <MarkdownTooltip>{`**Event**: ${hoveredData.eventName}

**Total**: ${hoveredData.total}
`}</MarkdownTooltip>
    </div>
  )
}

export const getLabel = (key: GuestModelStatus) => {
  return key.charAt(0).toUpperCase() + key.slice(1)
}
