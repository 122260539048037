import {useMemo} from 'react'
import {
  HorizontalBarChart,
  BarChartDatum,
} from '../../../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {WidgetF06Model} from '../../../../../../models/fnb/WidgetModel'
import {TopOutletsTooltip} from './TopOutletsTooltip'

export interface TopOutletsBarChartWidgetProps {
  data?: WidgetF06Model[]
}

export const TopOutletsBarChartWidget = ({data}: TopOutletsBarChartWidgetProps) => {
  const datum = useMemo((): BarChartDatum[] => {
    if (data) {
      return data.map((d) => ({
        key: d.outletCode,
        label: d.outletName,
        value: d.total,
      }))
    }
    return []
  }, [data])

  return (
    <HorizontalBarChart
      datum={datum}
      yLabel='Sales'
      xLabel='Outlet'
      tooltip={<TopOutletsTooltip data={data} />}
    />
  )
}
