import {useCallback, useState, useMemo, useEffect} from 'react'
import {FileInputValueCollection} from '../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {
  CustomerModel,
  CustomerModelCreateFormDataParams,
  CustomerModelCreateParams,
} from '../../../models/CustomerModel'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
import {ApiFormData} from '../../../utils/ApiFormData'
import {DateUtil} from '../../../utils/DateUtil'
import {GetISOCountries} from '../redux/CustomerPortalCRUD'
import {CustomerFormValues} from '../components/DrawerForm/CustomerFormDrawer/CustomerForm'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'

export const initialValues: CustomerFormValues = {
  salutation: null,
  firstName: '',
  lastName: '',
  avatar: null,
  email: '',
  type: 'customer',
  mobile: '',
  gender: '',
  nationality: '',
  residence: '',
  dob: null,
  addressName: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressStateOrProvince: '',
  addressPostCode: '',
  addressCountryCode: '',
  bio: null,
  parentCode: '',
}

export const useFormDrawerCustomerData = () => {
  const {staticUrls} = useAppConfig()
  const [countries, setCountries] = useState<ISOCountryModel[]>([])
  const resetCountries = useCallback(async () => {
    const {data} = await GetISOCountries()
    setCountries(data)
  }, [])

  const mapCustomerDataToFormValues = useCallback(
    (customer: CustomerModel): CustomerFormValues => {
      return {
        salutation: customer.salutation || '',
        email: customer.email,
        avatar: customer.photo ? new ImageInputValue(staticUrls.public, customer.photo) : null,
        type: customer.type,
        mobile: customer.mobile || '',
        firstName: customer.firstName,
        lastName: customer.lastName,
        dob: customer.dob ? DateUtil.getDateFromApiString(customer.dob) : null,
        gender: customer.gender || '',
        nationality: customer.nationality || '',
        residence: customer.residence || '',
        addressCity: customer.address?.city || '',
        addressCountryCode: customer.address?.countryCode || '',
        addressLine1: customer.address?.line1 || '',
        addressLine2: customer.address?.line2 || '',
        addressName: customer.address?.name || '',
        addressPostCode: customer.address?.postCode || '',
        addressStateOrProvince: customer.address?.stateOrProvince || '',
        bio: customer.bio || null,
      }
    },
    [staticUrls.public]
  )

  useEffect(() => {
    resetCountries()
  }, [resetCountries])

  return useMemo(
    () => ({
      countries,
      mapCustomerDataToFormValues,
    }),
    [countries, mapCustomerDataToFormValues]
  )
}

interface getCustomerPayloadProps {
  values: CustomerFormValues
  editing?: boolean
}

export const getCustomerPayload = ({values}: getCustomerPayloadProps) => {
  const photo = new FileInputValueCollection<ImageInputValue>()
  if (values.avatar) {
    photo.add(values.avatar)
  }

  const payload: CustomerModelCreateParams = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    salutation: values.salutation || null,
    type: values.type,
    mobile: values.mobile || null,
    gender: values.gender || null,
    nationality: values.nationality || null,
    parentCode: values.parentCode || null,
    residence: values.residence || null,
    dob: values.dob ? DateUtil.convertDateToApiString(values.dob) : null,
    bio: values.bio,
    address: null,
  }

  const hasAddressSpecified = values.addressCity || values.addressCountryCode || values.addressLine1

  if (hasAddressSpecified) {
    payload.address = {
      city: values.addressCity,
      countryCode: values.addressCountryCode,
      postCode: values.addressPostCode || null,
      stateOrProvince: values.addressStateOrProvince || null,
      line1: values.addressLine1,
      line2: values.addressLine2 || null,
      name: values.addressName || null,
    }
  }

  return new ApiFormData<CustomerModelCreateFormDataParams>({
    photo: photo,
    data: JSON.stringify(payload),
  })
}
