import {useMemo} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {ColumnStyle} from '../../../../components/tables/constants/ColumnStyle'
import {FilterTable} from '../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'
import {useTableOptions} from '../../../../components/tables/useTableOptions'
import {PortalEventLink} from '../../../../components/utils/PortalEventLink'
import {DateTimeFormatter} from '../../../../components/utils/formatter/DateTimeFormatter'
import {ActivityModel} from '../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {idExtractor} from '../../../../utils/idExtractor'
import {RefCodeColumn} from '../../../default/ems/components/tables/column-formatters/RefCodeColumn'

export interface EventTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ActivityModel>
}
//
export const EventTable = ({onFilter, data}: EventTableProps) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'event',
    defaults: {
      hiddenColumns: ['refCodes'],
    },
  })

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  return (
    <FilterTable
      onFilter={onFilter}
      idExtractor={idExtractor}
      hiddenColumns={hiddenColumns}
      onHiddenColumnsChange={setHiddenColumns}
      data={tableItems}
      currentPageNumber={data?.page}
      columns={columns}
      totalItems={data?.total || 0}
    />
  )
}

export const columns: TableColumnOptions<EventModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    render: ({data}) => <PortalEventLink event={data}>{data.code}</PortalEventLink>,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.NAME,
  },
  {
    field: 'slug',
    label: 'Alias',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.SLUG,
  },
  {
    field: 'description',
    label: 'Description',
    hideable: true,
    cellStyle: ColumnStyle.DESCRIPTION,
  },
  {
    field: 'type',
    label: 'Type',
    hideable: true,
    cellStyle: ColumnStyle.DESCRIPTION,
    dataExtract: (data) => data.type?.name,
  },
  {
    field: 'refCodes',
    label: 'Ref Codes',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.DESCRIPTION,
    render: ({data}) => <RefCodeColumn refCode={data.refCodes} />,
  },
  {
    field: 'venue',
    label: 'Venue',
    sortable: true,
    hideable: true,
    render: ({data}) => (
      <Badge className='text-nowrap' variant='primary'>
        {data.venue?.name}
      </Badge>
    ),
    cellStyle: ColumnStyle.NAME,
  },
  {
    field: 'isRfid',
    label: 'RFID?',
    sortable: true,
    hideable: true,
    type: 'boolean',
  },
  {
    field: 'isClosed',
    label: 'Closed?',
    sortable: true,
    hideable: true,
    type: 'boolean',
  },
  {
    field: 'isDefault',
    label: 'Default?',
    sortable: true,
    hideable: true,
    type: 'boolean',
  },
  {
    field: 'startedAt',
    label: 'Start Date',
    sortable: true,
    hideable: true,
    type: 'date',
    render: ({data}) => (
      <DateTimeFormatter className='text-uppercase text-nowrap'>{data.startedAt}</DateTimeFormatter>
    ),
    cellStyle: ColumnStyle.DATE,
  },
  {
    field: 'endedAt',
    label: 'End Date',
    sortable: true,
    hideable: true,
    type: 'date',
    render: ({data}) => (
      <DateTimeFormatter className='text-uppercase text-nowrap'>{data.endedAt}</DateTimeFormatter>
    ),
    cellStyle: ColumnStyle.DATE,
  },
]
