import * as Yup from 'yup'
import {FormikContextType} from 'formik'
import {EventModel} from '../../../../../models/ems/EventModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {useCallback, useMemo, useState} from 'react'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {WizardControls, WizardStep} from '../../../../../components/forms/Wizard/WizardControls'
import {WizardControlProvider} from '../../../../../components/forms/Wizard/WizardControlProvider'
import {WizardSteppers} from '../../../../../components/forms/Wizard/WizardSteppers'
import {ReservationModelNonSeatedProduct, ReservationModelSeatedAndNonSeatedCreateParams, ReservationModelSeatedProduct} from '../../../../../models/ems/ReservationModel'
import {useWizardFormikHelpers} from '../../../../../components/forms/Wizard/useEventWizardHelpers'
import {
  ReservationWizardCustomerStep,
  ReservationWizardCustomerStepFormValues,
} from './steps/ReservationWizardCustomerStep'
import {ReservationWizardCreateCustomerStep} from './steps/ReservationWizardCreateCustomerStep'

import {
  ReservationWizardProductVenueStep,
  ReservationWizardProductVenueStepFormValues,
} from './steps/ReservationWizardProductVenueStep'
import {ReservationWizardFinalizeStep} from './steps/ReservationWizardFinalizeStep'
import { ActivityModel } from '../../../../../models/ems/ActivityModel'

export interface ReservationWizardProps {
  formik: FormikContextType<ReservationFormValues>
  disabledFields?: Partial<Record<keyof ReservationFormValues, boolean>>
  hiddenFields?: Partial<Record<keyof ReservationFormValues, boolean>>
  step: number
  onStepChange: (step: number) => void
  event?: EventModel | null
  activity?: ActivityModel | null
  customer?: CustomerModel
  isEdit?: boolean
  onSubmit?: () => void
}

export const ReservationWizard = ({
  formik,
  hiddenFields,
  disabledFields,
  step: currentStep,
  onStepChange,
  event,
  activity,
  customer,
  isEdit,
  onSubmit,
}: ReservationWizardProps) => {
  const [noSelectedCustomer, setNoSelectedCustomer] = useState<boolean>(true)

  const {getStepState} = useWizardFormikHelpers({
    currentStep,
    formik,
    noErrors: currentStep === 3,
  })

  useOnChange(event, () => {
    formik.setFieldValue('eventCode', event?.code)
  })

  const isStepperShown = useCallback(
    (stepperFields: (keyof ReservationFormValues)[]) => {
      if (!hiddenFields) {
        return true
      }
      const shouldShow = !stepperFields.every((field) => !!hiddenFields[field])
      return shouldShow
    },
    [hiddenFields]
  )

  const steps = useMemo((): WizardStep[] => {
    const steps: WizardStep[] = [
      {
        title: 'Reseller',
        description: 'Search Reseller',
        state: getStepState(0, ['customer']),
        icon: {
          iconType: 'General',
          iconName: 'Search',
        },
        fields: ['customer'],
        hidden: !isStepperShown(['customer']),
      },
      {
        title: 'Create Reseller',
        description: 'Add a Reseller',
        state: getStepState(1, ['customer']),
        icon: {
          iconType: 'Communication',
          iconName: 'Add-user',
        },
        fields: ['customer'],
        hidden: Boolean(isEdit),
      },
      {
        title: 'Product & Voucher',
        description: 'Select Products / Voucher',
        state: getStepState(2, ['products', 'vouchers']),
        icon: {
          iconType: 'Communication',
          iconName: 'Ticket',
        },
        fields: ['products', 'vouchers'],
        hidden: !isStepperShown(['products', 'vouchers']),
      },
      {
        title: 'Finalize',
        description: 'View Reservation',
        state: getStepState(3, []),
        icon: {
          iconType: 'Communication',
          iconName: 'Flag-pole',
        },
      },
    ]

    return steps
  }, [getStepState, isStepperShown, isEdit])

  const handleSelectedCustomer = useCallback((selected: boolean) => {
    if (selected) {
      setNoSelectedCustomer(false)
    } else {
      setNoSelectedCustomer(true)
    }
  }, [])

  const handleCustomerStepChange = useCallback(
    (step: number) => {
      formik.setFieldValue('customer', '')
      onStepChange(step)
    },
    [formik, onStepChange]
  )

  const handleCreateCustomer = useCallback(() => {
    onStepChange(2)
  }, [onStepChange])


  const stepPage = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <ReservationWizardCustomerStep
            formik={formik}
            disabledFields={disabledFields}
            event={event}
            isEdit={isEdit}
            onSelectCustomer={handleSelectedCustomer}
            onStepChange={handleCustomerStepChange}
          />
        )
      case 1:
        return (
          <ReservationWizardCreateCustomerStep
            formik={formik}
            handleCreateCustomer={handleCreateCustomer}
          />
        )
      case 2:
        return (
          <ReservationWizardProductVenueStep
            formik={formik}
            event={event}
          />
        )
      case 3:
        return <ReservationWizardFinalizeStep formik={formik} />
    }
  }, [currentStep, disabledFields, event, formik, handleCreateCustomer, handleCustomerStepChange, handleSelectedCustomer, isEdit])

  const handeleStepChange = useCallback(
    (page: number, type) => {
      if (page === 1 && formik.values.customer) {
        if (type === 'increament') {
          onStepChange(page + 1)
        }

        if (type === 'decreament') {
          onStepChange(page - 1)
        }
      } else onStepChange(page)
    },
    [formik.values.customer, onStepChange]
  )

  return (
    <WizardControlProvider
      currentPage={currentStep}
      onPageChange={handeleStepChange}
      steps={steps}
      isHasPrevious={currentStep === 3 || currentStep === 0 ? false : true}
    >
      <div className='container flex-grow-1'>
        <div className='row'>
          <div className='col-12'>
            <WizardSteppers currentStep={currentStep} steps={steps} />
          </div>
          <div className='col-12'>{stepPage}</div>
          <div className='col-12'>
            <WizardControls
              formik={formik}
              steps={steps}
              submitLabel='Done'
              nextLable={'Next'}
              noErrors={currentStep === 3}
              isHasNext={currentStep === 0 ? noSelectedCustomer : currentStep === 1 ? false : true}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </WizardControlProvider>
  )
}

export interface ReservationFormValues
  extends ReservationWizardCustomerStepFormValues,
    ReservationWizardProductVenueStepFormValues {}

export const EMPTY_FORM_VALUES: ReservationFormValues = {
  customer: null,
  products: [],
  eventCode: '',
  vouchers: [],
}

export const getPayload = (
  values: ReservationFormValues,
  event: EventModel
): ReservationModelSeatedAndNonSeatedCreateParams => {
  const {customer} = values;

  if (!customer) {
    throw new Error('Invalid form data.');
  }
  const payload: ReservationModelSeatedAndNonSeatedCreateParams = {
    customerCode: customer.code,
    eventCode: event.code,
    products: [],
    vouchers: [],
  };


  values.vouchers.forEach((item) => {
    if (item.data && item.count > 0) {
      payload.vouchers.push({code: item.data.code, qty: item.count})
    }
  })
  
  const seatedProductCodes = values.products
    .filter(item => item.isSeated)
    .map(item => item.data?.code);

  const filteredProducts = values.products.filter(item => {
    if (!item.isSeated && seatedProductCodes.includes(item.data?.code)) {
      return false;
    }
    return true;
  });

  const uniqueData = new Set<string>();

  filteredProducts.forEach((item) => {
    if (item.data) {
      let productToAdd;
      let uniqueKey = `${item.data.code}_`; 

      if (item.isSeated && item.seatMap) {
        productToAdd = {
          code: item.data.code,
          locationCode: item.locationCode,
          details: item.seatMap.getSeatMapObject(),
        } as ReservationModelSeatedProduct;

        uniqueKey += `${productToAdd.locationCode}_${JSON.stringify(productToAdd.details)}`;
      } else {
        productToAdd = {
          code: item.data.code,
          qty: item.count
        } as ReservationModelNonSeatedProduct;

        uniqueKey += 'null_null'; 
      }
      if (!uniqueData.has(uniqueKey)) {
        payload.products.push(productToAdd);
        uniqueData.add(uniqueKey);
      }
    }
});

  return payload;
}

export const reservationValidationSchema = Yup.object().shape({
  eventCode: Yup.string().required(),
  customer: Yup.object().required(),
  products: Yup.array()
    .test('products-validation', '', function (products) {
      if (!products) return true;
      if (products.length === 1 && !products[0].data) {

        return false;
      }
      return true;
    })
    .test('seating-validation', 'Please assign seats for seated products.', function (products) {
      if (!products) return true;
      for (let product of products) {
        if (product.isFromAddProd && product.data && product.data.isSeated === false) {
          continue; 
        }

        if (product.isFromAddProd) {

          const matchingProduct = products.find(p => p.data && p.data.code === product.data.code && p.isSeated && p.seatMap);
          if (!matchingProduct) {
            return false;
          }
        }
      }
      return true;
    })
    .of(
      Yup.object({
        id: Yup.string().required(),
        data: Yup.object().nullable(),
        count: Yup.number().required(),
        isSeated: Yup.boolean().nullable(),
        seatMap: Yup.mixed().nullable(),
        isFromAddProd: Yup.boolean().nullable(),
      })
    ),
    vouchers: Yup
    .array()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as ReservationFormValues
      if (
        formValues.products.length > 0 &&
        formValues.products.some((item) => item.data && item.count > 0)
      ) {
        return true
      }
      if (!value) {
        return false
      }

      return value.some((item) => {
        return Boolean(item.data) && item.count > 0
      })
    }),
});