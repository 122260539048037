import {createContext, FC, useContext, useEffect, useMemo, useState} from 'react'
import socketIOClient, {Socket} from 'socket.io-client'
import {getUrlData, LAYOUT_TYPE, WSS_URL} from '../../../config/env'
import {useRootStateSelector} from '../hooks/useRootStateSelector'

export interface SocketContext {
  isConnected: boolean
  socket?: Socket | null
}

const Context = createContext<SocketContext | null>(null)

export const WebSocketProvider: FC = ({children}) => {
  const [socket, setSocket] = useState<Socket>()
  const [isConnected, setIsConnected] = useState(false)
  const tokens = useRootStateSelector((state) => ({
    default: state?.auth?.token,
    outlet: state?.outlet?.auth?.token,
    customer: state.customerPortal?.auth?.token,
  }))

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true)
    }
    const onDisconnect = () => {
      setIsConnected(false)
    }
    const onError = () => {
      setIsConnected(false)
    }

    socket?.on('connect', onConnect)
    socket?.on('disconnect', onDisconnect)
    socket?.on('connect_error', onError)

    return () => {
      socket?.off('connect', onConnect)
      socket?.off('disconnect', onDisconnect)
      socket?.off('connect_error', onError)
    }
  }, [socket])

  const value = useMemo((): SocketContext => {
    return {
      socket,
      isConnected,
    }
  }, [isConnected, socket])

  const jwt = useMemo(() => {
    switch (LAYOUT_TYPE) {
      case 'customer-delegate':
      case 'customer-portal':
        return tokens.customer
      case 'outlet':
        return tokens.outlet
      default:
        return tokens.default
    }
  }, [tokens])

  useEffect(() => {
    const {organizationCode, outletCode} = getUrlData()
    if (WSS_URL) {
      setSocket(
        socketIOClient(WSS_URL, {
          transports: ['websocket', 'polling'],
          auth: {
            token: jwt,
            organizationCode,
            outletCode,
          },
        })
      )
    }
  }, [jwt])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useWebSocket = (): SocketContext => {
  const socket = useContext(Context)
  if (!socket) {
    throw new Error('socket provider is not present.')
  }
  return socket
}
