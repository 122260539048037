import {useState} from 'react'
import {useDebounce} from './useDebounce'
import {useOnChange} from './useOnChange'

export const useDebouncedValue = <T>(value: T, delay: number = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(() => value)
  const debounce = useDebounce(delay)

  useOnChange(value, () => {
    debounce(() => {
      setDebouncedValue(() => value)
    })
  })

  return debouncedValue
}
