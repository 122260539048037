import {Calendar} from '../../../../../../components/charts/Calendar/Calendar'
import {EventCalendarChild, EventCalendarData, getDateString} from './EventCalendarChild'
import styles from './EventCalendar.module.scss'
import {CalendarWidgetModel} from '../../../../../../models/ems/WidgetModel'
import {useMemo} from 'react'
import {DateRange} from '../../../../../../utils/DateRange'
import {DateUtil} from '../../../../../../utils/DateUtil'

export interface EventCalendarProps {
  date?: Date
  data?: CalendarWidgetModel[]
}

export const EventCalendar = ({date, data}: EventCalendarProps) => {
  const calendarData = useMemo((): EventCalendarData => {
    const calendarData: EventCalendarData = {}
    data
      ?.sort(
        (a, b) =>
          DateUtil.getDateFromApiString(a.startedAt).valueOf() -
          DateUtil.getDateFromApiString(b.startedAt).valueOf()
      )
      .forEach((event) => {
        const dateRange = new DateRange(
          DateUtil.getDateFromApiString(event.startedAt),
          DateUtil.getDateFromApiString(event.endedAt)
        )
        let rowIndex: number | undefined
        dateRange.forEachDayBetweenInclusive((date) => {
          const dateString = getDateString(date)
          if (!calendarData[dateString]) {
            if (rowIndex === undefined) {
              calendarData[dateString] = [event]
              rowIndex = 0
            } else {
              const rows = normalizeRows<CalendarWidgetModel | null>(rowIndex + 1)
              rows[rowIndex] = event
              calendarData[dateString] = normalizeRows(rows.length, rows)
            }
          } else {
            const rows = normalizeRows(calendarData[dateString].length, calendarData[dateString])
            if (rowIndex === undefined) {
              const index = calendarData[dateString].findIndex((item) => item === null)
              if (index >= 0) {
                rowIndex = index
                rows[rowIndex] = event
              } else {
                rowIndex = rows.push(event) - 1
              }
            } else {
              rows[rowIndex] = event
            }
            calendarData[dateString] = normalizeRows(rows.length, rows)
          }
        })
      })

    return calendarData
  }, [data])

  const domain = useMemo((): string[] => {
    if (!data) {
      return []
    }
    return data.map((item) => item.code)
  }, [data])

  return (
    <Calendar className='overflow-hidden' date={date} dateClasses={dateClasses}>
      <EventCalendarChild data={calendarData} domain={domain} />
    </Calendar>
  )
}

const normalizeRows = <T,>(length: number, source?: T[]) => {
  const newData = new Array<CalendarWidgetModel | null>(length)
    .fill(null)
    .map((_, i) => source?.[i] ?? null)
  return newData
}

const dateClasses = {
  root: styles.dateItem,
}

export interface CalendarEvent {
  code: string
  name: string
}
