import {useMemo} from 'react'
import {FilterTable} from '../../tables/FilterTable'
import {TableColumnOptions} from '../../tables/TableColumn'
import {TableRowId} from '../../tables/TableRow'
import {CustomerModel} from '../../../models/CustomerModel'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {idExtractor} from '../../../utils/idExtractor'
import {TableAvatarImageColumn} from '../../../modules/default/svc/components/tables/TableAvatarImageColumn'

export interface CustomerTableProps {
  onFilter: (filter: FilterModel) => void
  onRefresh?: () => void
  data?: GlobalSearchModel<CustomerModel>
  hideSelectAll?: boolean
  isSelectDisabled?: (data: CustomerModel) => boolean
  onRowSelectionChange?: (rows: TableRowId[]) => void
  onSelectionChange?: (rows: TableRowId[]) => void
  selectedItems: TableRowId[]
  isEdit?: boolean
  isBulk?: boolean
}

export const CustomerWizardTable = ({
  onFilter,
  data,
  hideSelectAll,
  isSelectDisabled,
  onRowSelectionChange,
  onSelectionChange,
  selectedItems,
  isEdit,
  isBulk,
}: CustomerTableProps) => {
  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        initialFilters={initialFilters}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total || 0}
        selection={selectedItems}
        onRowSelectionChange={onRowSelectionChange}
        onSelectionChange={onSelectionChange}
        isSelectSingleRow={isBulk ? false : true}
        hideSelectAll={hideSelectAll}
        isSelectDisabled={isSelectDisabled}
        isAllCheckBoxDisabled={isEdit}
        // additionalPagesSize={[5]}
      />
    </>
  )
}

const initialFilters = {
  filters: {
    status: ['pending', 'queue', 'sent', 'submitted', 'active'],
  },
  limit: 10,
}

export const columns: TableColumnOptions<CustomerModel>[] = [
  {
    field: 'photo',
    label: 'Photo',
    sortable: true,
    hideable: true,
    className: 'w-50px',
    render: ({data}) =>
      data.photo ? <TableAvatarImageColumn image={data.photo} alt={data.name} /> : null,
  },

  {
    field: 'name',
    label: 'Name',
    sortable: true,
    hideable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
    hideable: true,
  },
  {
    field: 'mobile',
    label: 'Mobile',
    sortable: true,
    hideable: true,
  },
]
