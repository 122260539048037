import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import clsx from 'clsx'
import {TopicModel} from '../../../../../../models/eva/TopicModel'
import {DateUtil} from '../../../../../../utils/DateUtil'
import {AgendaModel} from '../../../../../../models/eva/AgendaModel'
import {GetAgendaByDay} from '../../../../eva/redux/EvaCRUD'
import {useDebounce} from '../../../../../../components/hooks/useDebounce'
import {usePromiseManager} from '../../../../../../components/hooks/usePromiseManager'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ScannerCountWidgetModel} from '../../../../../../models/ems/WidgetModel'
import {Paper} from '../../../../../../components/utils/Paper'

export interface SessionCountChartProps {
  date: Date
  event?: EventModel | null
  data?: ScannerCountWidgetModel[]
  className?: string
}

export const TopicCounterCards = ({date, data, event, className}: SessionCountChartProps) => {
  const [agendas, setAgendas] = useState<AgendaModel[]>([])
  const getAgendasDebounce = useDebounce(500)
  const {managePromise} = usePromiseManager()

  const resetAgendas = useCallback(() => {
    if (event) {
      getAgendasDebounce(async () => {
        try {
          const {data} = await managePromise('agendas', GetAgendaByDay(date, event.code))
          setAgendas(data)
        } catch (e) {
          // Ignore error
        }
      })
    }
  }, [date, event, getAgendasDebounce, managePromise])

  const topics = useMemo(() => {
    return agendas.reduce<TopicModel[]>((topics, agenda) => {
      if (agenda.topics) {
        topics.push(...agenda.topics)
      }
      return topics
    }, [])
  }, [agendas])

  const datum = useMemo(() => {
    return topics.reduce<Datum[]>((datum, topic) => {
      const start = DateUtil.getDateFromApiString(topic.startedAt)
      const end = DateUtil.getDateFromApiString(topic.endedAt)
      const isTopicActive = dateIsBetweenDays(date, start, end)
      if (isTopicActive) {
        const count =
          data?.reduce<number>((count, widgetData) => {
            const shouldCount = shouldCountScan(topic, widgetData)

            if (shouldCount) {
              count += widgetData.total
            }
            return count
          }, 0) || 0
        const topicDatum = {
          time: `${getTimeString(start)} - ${getTimeString(end)}`,
          count,
          title: topic.title,
        }
        datum.push(topicDatum)
      }
      return datum
    }, [])
  }, [data, date, topics])

  useEffect(() => {
    resetAgendas()
  }, [resetAgendas])

  return (
    <div className={clsx('row g-6', className)}>
      {datum.map((item, i) => (
        <div
          className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12'
          key={`${item.time}${item.count}${i}`}
        >
          <Paper
            rounded
            className={clsx('p-5 h-100 d-flex flex-column justify-content-between', className)}
          >
            <div>
              <div>
                <span className='fs-3 fw-bold'>{item.title}</span>
              </div>
              <div>
                <span className='fs-7 text-muted'>{item.time}</span>
              </div>
            </div>
            <div className='flex-grow-1 d-flex flex-column text-center justify-content-center'>
              <span
                className='text-primary'
                style={{
                  fontSize: '5rem',
                }}
              >
                {item.count}
              </span>
            </div>
          </Paper>
        </div>
      ))}
    </div>
  )
}

interface Datum {
  title: string
  time: string
  count: number
}

const getTimeString = (date: Date) => {
  return moment(date).format('h:mm A')
}

const dateIsBetween = (date: Date, start: Date, end: Date) => {
  return moment(date).isBetween(start, end, undefined, '[]')
}

const dateIsBetweenDays = (date: Date, start: Date, end: Date) => {
  return moment(date).isBetween(start, end, 'day', '[]')
}

const shouldCountScan = (topic: TopicModel, widgetData: ScannerCountWidgetModel) => {
  const start = DateUtil.getDateFromApiString(topic.startedAt)
  const end = DateUtil.getDateFromApiString(topic.endedAt)
  const widgetDate = DateUtil.getDateFromApiString(widgetData.timeLog)
  const isScannedWithinTopicDates = dateIsBetween(widgetDate, start, end)
  const topicScannerSlug = topic.title.split(' ')[0]?.toUpperCase()
  if (SCANNER_SLUG_NAMES.includes(topicScannerSlug)) {
    return isScannedWithinTopicDates && SCANNER_SLUG_NAMES.includes(widgetData.gateSlug)
  }
  return isScannedWithinTopicDates
}

const SCANNER_SLUG_NAMES = ['PLENARY', 'WORKSHOP']
