/* eslint-disable @typescript-eslint/no-unused-expressions */
import {Button} from '../../../../../../../components/inputs/Button'
import {
  ReservationInput,
  ReservationInputRefValue,
} from '../../../../../../../components/inputs/ReservationInput/ReservationInput'
import {ReservationInputItemValue} from '../../../../../../../components/inputs/ReservationInput/ReservationInputItem'
import {SweetAlert} from '../../../../../../../components/modals/SweetAlert'
import {HorizontalDivider} from '../../../../../../../components/utils/HorizontalDivider'
import {LoadingSpinner} from '../../../../../../../components/utils/LoadingSpinner'
import {FilterModel} from '../../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../../models/GlobalSearchModel'
import {ProductModel} from '../../../../../../../models/ems/ProductModel'
import {selectItemMapper} from '../../../../../../../utils/idExtractor'
import {ProductLocationModalType} from '../steps/ReservationWizardProductVenueStep'
import {useState} from 'react'
import clsx from 'clsx'
import {MetronicIconButton} from '../../../../../../../components/inputs/MetronicIconButton'
import {VoucherModel} from '../../../../../../../models/svc/VoucherModel'
import {
  ListCountInput,
  ListCountInputRefValue,
} from '../../../../../../../components/inputs/VoucherInput/ListCountInput'
import {ListCountInputItemValue} from '../../../../../../../components/inputs/VoucherInput/ListCountInputItem'

interface AddProductLocationModalProps {
  modalType: ProductLocationModalType
  onModalClose: () => void
  productSearchResults?: GlobalSearchModel<ProductModel>
  refreshProductsList: (filter?: FilterModel) => void
  productsValues: ReservationInputItemValue<ProductModel>[]
  onProductChange: (value: ReservationInputItemValue<ProductModel>[]) => void
  onAdd: () => void
  isAddProdClick?: boolean
  onAddItemClick?: () => void
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  vouchersValues: ListCountInputItemValue<VoucherModel>[]
  onVoucherChange: (value: ListCountInputItemValue<VoucherModel>[]) => void
  searchVouchers: (filter?: FilterModel) => void
  isPortal?: boolean
}

export const AddProductLocationModal = ({
  modalType,
  onModalClose,
  productSearchResults,
  refreshProductsList,
  productsValues,
  onProductChange,
  onAdd,
  isAddProdClick,
  onAddItemClick,
  voucherSearchResults,
  vouchersValues,
  onVoucherChange,
  searchVouchers,
  isPortal,
}: AddProductLocationModalProps) => {
  const [productInputRef, setProductInputRef] = useState<ReservationInputRefValue | null>(null)
  const [_isAddItemClick, setIsAddItemClick] = useState(true)
  const [voucherInputRef, setVoucherInputRef] = useState<ListCountInputRefValue | null>(null)

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {isPortal && <div className='swal2-corners' style={{width: '425px'}}></div>}
      <div
        className={clsx('h-100 d-flex flex-column', isPortal && 'position-relative p-0')}
        style={{width: isPortal ? '385px' : ''}}
      >
        <div className='d-flex flex-column flex-grow-1 text-start'>
          {modalType === 'product' ? (
            <>
              <div className='position-absolute top-0 end-0'>
                <MetronicIconButton
                  variant='text'
                  size='md'
                  iconType='Navigation'
                  iconName='Close'
                  tooltip='Close Modal'
                  onClick={onModalClose}
                />
              </div>
              <h2 className='text-start mb-5'>Add product</h2>
              <ReservationInput
                selectPlaceholder='Select a product'
                searchResult={productSearchResults}
                label='Product'
                onChange={onProductChange}
                values={productsValues}
                onSearch={refreshProductsList}
                itemMapper={selectItemMapper}
                listRef={setProductInputRef}
                isInitialValue
                isNew
                isAddProdClick={isAddProdClick && _isAddItemClick ? false : true}
                isAddItemClick={_isAddItemClick}
              />
              <div>
                {productsValues.length > 0 && <HorizontalDivider />}
                <Button
                  className='w-auto'
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    if (onAddItemClick) {
                      onAddItemClick()
                      setIsAddItemClick(true)
                    }
                    if (_isAddItemClick && productInputRef?.addEmptyItem) {
                      productInputRef.addEmptyItem()
                    }
                  }}
                  disabled={productInputRef?.hasBlankItem}
                  uppercase={false}
                  tooltip='Add'
                >
                  Add item
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2 className='text-start mb-5'>Add voucher</h2>
              <ListCountInput
                selectPlaceholder='Select a voucher'
                searchResult={voucherSearchResults}
                label='Voucher'
                onChange={onVoucherChange}
                values={vouchersValues}
                onSearch={searchVouchers}
                itemMapper={selectItemMapper}
                listRef={setVoucherInputRef}
                isInitialValue
                isNew
              />
              <div>
                {vouchersValues.length > 0 && <HorizontalDivider />}
                <Button
                  className='w-auto'
                  size='sm'
                  variant='primary'
                  onClick={voucherInputRef?.addEmptyItem}
                  disabled={voucherInputRef?.hasBlankItem}
                  uppercase={false}
                  tooltip='Add'
                >
                  Add item
                </Button>
              </div>
            </>
          )}
        </div>
        <div className='d-flex gap-5 justify-content-end mt-5'>
          {isPortal ? (
            <>
              <Button onClick={onModalClose}>Close</Button>
              <Button
                className='btn btn-primary align-items-center position-relative btn-cut-conrner'
                style={{marginRight: '10px'}}
                onClick={onAdd}
              >
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onModalClose}>Close</Button>
              <Button variant='primary' onClick={onAdd}>
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
            </>
          )}
        </div>
      </div>
    </SweetAlert>
  )
}
