import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ProductIsOkToBeDeliveredWidgetModel} from '../../../../../../models/ems/WidgetModel'

export interface ToBeDeliveredProductsChartTooltipProps {
  data?: ProductIsOkToBeDeliveredWidgetModel[]
  event?: EventModel
}

export const ToBeDeliveredProductsChartTooltip = ({
  data,
  event,
}: ToBeDeliveredProductsChartTooltipProps) => {
  const {key} = useBarChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.code === key || item.code === key)
    }
  }, [data, key])

  if (!hoveredData) {
    return null
  }

  return (
    <MarkdownTooltip>{`
### ${hoveredData.name}

**Event**: ${event?.name}

**Total**: ${hoveredData.total}
`}</MarkdownTooltip>
  )
}
