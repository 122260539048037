import clsx from 'clsx'
import {useMemo} from 'react'
import {Paper} from '../utils/Paper'

export interface CounterChartProps {
  className?: string
  label: string
  value?: number
}

export const CounterChart = ({className, label, value = 0}: CounterChartProps) => {
  const chartValue = useMemo(() => {
    return Math.round(value)
  }, [value])

  return (
    <Paper rounded className={clsx('p-5 d-flex flex-column justify-content-between', className)}>
      <div>
        <span className='fs-1'>{label}</span>
      </div>
      <div className='flex-grow-1 d-flex flex-column text-center justify-content-center'>
        <span
          className='text-primary'
          style={{
            fontSize: '5rem',
          }}
        >
          {chartValue}
        </span>
      </div>
    </Paper>
  )
}
