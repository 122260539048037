import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useWebSocket} from '../../../../components/utils/WebSocketProvider'
import {GetWidgets} from '../redux/EmsCRUD'
import {WidgetModel} from '../../../../models/ems/WidgetModel'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'

export interface UseEventDashboardWidgetDataOptions {
  dailyWidgets?: (keyof WidgetModel)[]
  monthlyWidgets?: (keyof WidgetModel)[]
  date: Date | null
  eventCode?: string
  month?: Date | null
}

export const useEventDashboardWidgetData = ({
  date,
  eventCode,
  dailyWidgets,
  monthlyWidgets,
  month,
}: UseEventDashboardWidgetDataOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {isLoading: isDashboardLoading, managePromise} = usePromiseManager()
  const {socket} = useWebSocket()
  const resetWidgetDebounce = useDebounce(500)

  const getSocketHandlers = useCallback(
    (eventCode: string, widgetCodes: Array<keyof WidgetModel>) => {
      return widgetCodes.map((widget) => {
        const event = `${widget}:${eventCode}`

        return {
          event,
          callback: (data: {data: WidgetModel[keyof WidgetModel]}) => {
            setWidgetData((widgetData) => ({...widgetData, [widget]: data.data}))
          },
        }
      })
    },
    []
  )

  const resetWidgetData = useCallback(async () => {
    if (eventCode && date) {
      try {
        const {data} = await managePromise(
          'dashboard',
          GetWidgets({
            widgetCodes: [...(dailyWidgets || []), ...(monthlyWidgets || [])],
            eventCode,
            date: date.toISOString(),
            month: month ? month.getMonth() + 1 : undefined,
            year: month?.getFullYear(),
          })
        )
        setWidgetData(data)
      } catch (e) {
        if (e instanceof CancelledPromiseError) {
          // Ignore cancelled promise.
        }
      }
    }
  }, [eventCode, date, managePromise, dailyWidgets, monthlyWidgets, month])

  const isSameDay = useMemo(
    () => !date || Boolean(date && moment(date).isSame(new Date(), 'day')),
    [date]
  )

  const isSameMonth = useMemo(() => {
    if (month) {
      const today = new Date()
      return month.getMonth() === today.getMonth() && month.getFullYear() === today.getFullYear()
    }
    return false
  }, [month])

  useEffect(() => {
    if (eventCode && monthlyWidgets && isSameMonth) {
      const handlers = getSocketHandlers(eventCode, monthlyWidgets)
      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })
      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [eventCode, getSocketHandlers, isSameMonth, monthlyWidgets, socket])

  useEffect(() => {
    if (eventCode && isSameDay && dailyWidgets) {
      const handlers = getSocketHandlers(eventCode, dailyWidgets)
      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })

      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [dailyWidgets, eventCode, getSocketHandlers, isSameDay, socket])

  useEffect(() => {
    resetWidgetDebounce(async () => {
      resetWidgetData()
    })
  }, [resetWidgetData, resetWidgetDebounce])

  return {data: widgetData, isLoading: isDashboardLoading}
}
