import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import type {RoleModel, RoleModelCreateParams} from '../../../../models/RoleModel'
import {UserModel, UserModelCreateParams} from '../../../../models/UserModel'
import {ConfigGroupCreateParams, ConfigGroupModel} from '../../../../models/system/ConfigGroupModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ConfigTypeModel} from '../../../../models/system/ConfigTypeModel'
import {
  CustomFieldCreateParams,
  CustomFieldModel,
  CustomFieldTableModel,
  CustomFieldTypeModel,
} from '../../../../models/system/CustomFieldModel'

const CRUD_ROLE = '/role'
const CRUD_USER = '/user'
const SYSTEM_ALL_ROLES = '/role/all'
const SYSTEM_ORGANIZATION = '/system/organization'
const SYSTEM_ORGANIZATION_LOG = '/system/log'
const SYSTEM_ROLES = '/role'
const SYSTEM_SEARCH_ROLES = '/role/list'
const SYSTEM_SEARCH_USERS = '/system/user/list'

export const DeleteOrganizationUser = (codes: string[]) => axios.delete(CRUD_USER, {data: {codes}})
export const DeleteOrganizationRole = (codes: string[]) =>
  axios.delete(SYSTEM_ROLES, {data: {codes}})
export const GetOrganizationAllRoles = () => axios.get<RoleModel[]>(SYSTEM_ALL_ROLES)
export const GetOrganizationLogs = (filter?: FilterModel) =>
  axios.post(SYSTEM_ORGANIZATION_LOG, filter)
export const GetOrganizationProfile = () => axios.get(SYSTEM_ORGANIZATION)
export const GetOrganizationRole = (filter?: FilterModel) => axios.post(SYSTEM_SEARCH_ROLES, filter)
export const GetOrganizationRoleByCode = (code: string) => axios.get(`${SYSTEM_ROLES}/${code}`)
export const GetOrganizationUsers = (filter?: FilterModel) =>
  axios.post(SYSTEM_SEARCH_USERS, filter)

export const PostRole = (data: RoleModelCreateParams) => axios.post(CRUD_ROLE, data)
export const PutRole = (data: RoleModelCreateParams, code: string) =>
  axios.put(`${CRUD_ROLE}/${code}`, data)

export const PostUser = (data: any) => axios.post(CRUD_USER, data)
export const GetUserByCode = (userCode: string) => axios.get<UserModel>(`${CRUD_USER}/${userCode}`)
export const PutUser = (data: UserModelCreateParams, code: string) =>
  axios.put(`${CRUD_USER}/${code}`, data)
export const ResetUserMFA = (userCodes: string[]) =>
  axios.post(`/user/reset-mfa`, {
    codes: userCodes,
  })
export const SetUserSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`/user/smart-pin`, {
    smartPin,
    codes,
  })

export const GetConfigGroupByCode = (code: string) =>
  axios.get<ConfigGroupModel>(`/config-group/${code}`)
export const SearchConfigGroups = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<ConfigGroupModel>>(`/config-group/list`, filters)
export const CreateConfigGroup = (params: ConfigGroupCreateParams) =>
  axios.post<ConfigGroupModel>(`/config-group`, params)
export const UpdateConfigGroup = (code: string, params: ConfigGroupCreateParams) =>
  axios.put<ConfigGroupModel>(`/config-group/${code}`, params)
export const DeleteConfigGroup = (codes: string[]) =>
  axios.delete(`/config-group`, {
    data: {
      codes,
    },
  })
export const ActivateConfigGroup = (code: string) => axios.post(`/config-group/activate/${code}`)

export const GetAllConfigTypes = () => axios.get<ConfigTypeModel[]>(`/type`)
export const GetConfigTypeByCode = (code: string) => axios.get<ConfigTypeModel>(`/type/${code}`)

export const GetCustomFieldByCode = (table: string, code: string) =>
  axios.get<CustomFieldModel>(`/${table}/${code}`)
export const SearchCustomFields = async (table: string, filters?: FilterModel) => {
  const response = await axios.post<GlobalSearchModel<CustomFieldModel>>(`/${table}/list`, filters)
  return response
}
export const CreateCustomField = (table: string, params: CustomFieldCreateParams) =>
  axios.post<CustomFieldModel>(`/${table}`, params)
export const UpdateCustomField = (table: string, code: string, params: CustomFieldCreateParams) =>
  axios.put<CustomFieldModel>(`/${table}/${code}`, params)
export const DeleteCustomField = (table: string, codes: string[]) =>
  axios.delete(`/${table}`, {
    data: {
      codes,
    },
  })

export const GetCustomFieldTables = () =>
  axios.get<CustomFieldTableModel[]>(`/extras-custom-field/tables`)

export const GetCustomFieldTypes = () =>
  axios.get<CustomFieldTypeModel[]>(`/extras-custom-field/types`)

export const GetTableCustomFields = (dbRoute: string, tableCode: string) =>
  axios.get<CustomFieldModel[]>(`/${dbRoute}/all/${tableCode}`)
