import {useMemo} from 'react'
import {useDefaultColorScalePreset} from '../../../../../../components/charts/hooks/useDefaultColorScalePreset'
import {PieChart} from '../../../../../../components/charts/PieChart/PieChart'
import {PieChartDatum} from '../../../../../../components/charts/PieChart/PieChartSegment'
import {Paper} from '../../../../../../components/utils/Paper'
import {WidgetModel} from '../../../../../../models/fnb/WidgetModel'
import {EarningsTypePieChartTooltip, getLabel} from './EarningsTypePieChartTooltip'

export interface EarningsTypePieChartProps {
  datum?: WidgetModel['WIDGETF02']
}

export const EarningsTypePieChart = ({datum}: EarningsTypePieChartProps) => {
  const pieChartDatum = useMemo((): PieChartDatum[] => {
    const items: PieChartDatum[] = [
      {
        label: getLabel('cc'),
        value: 0,
        key: 'cc',
      },
      {
        label: getLabel('cash'),
        value: 0,
        key: 'cash',
      },
      {
        label: getLabel('voucher'),
        value: 0,
        key: 'voucher',
      },
    ]
    if (datum) {
      datum?.forEach((datum) => {
        const index = items.findIndex((item) => item.key === datum.orderPaymentType)
        if (index >= 0) {
          items[index].value = datum.total
        }
      })
    }
    return items
  }, [datum])

  const domain = useMemo(() => pieChartDatum.map((datum) => datum.key), [pieChartDatum])

  const getColor = useDefaultColorScalePreset({domain})

  return (
    <Paper rounded className='h-350px p-6'>
      <PieChart
        datum={pieChartDatum}
        tooltip={<EarningsTypePieChartTooltip data={datum} />}
        getColor={getColor}
      />
    </Paper>
  )
}
