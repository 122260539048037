import axios from 'axios'
import {AgendaModel, AgendaModelCreateParams} from '../../../../models/eva/AgendaModel'
import {
  PollModel,
  PollModelCreateParams,
  PollModelTranslationCreateParams,
} from '../../../../models/eva/PollModel'
import {SpeakerModel, SpeakerModelCreateParams} from '../../../../models/eva/SpeakerModel'
import {
  TopicModel,
  TopicModelCreateParams,
  TopicModelTranslationCreateParams,
} from '../../../../models/eva/TopicModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {PresentationParams} from '../components/TopicPresentation'

interface PresentationPayload {
  presentations: PresentationParams[]
}

const TOPIC_BASE_PATH = '/topic'
const AGENDA_BASE_PATH = '/agenda'
const POLL_BASE_PATH = '/poll'

export const GetAgendaList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<AgendaModel>>(`${AGENDA_BASE_PATH}/list`, filter)
export const DeleteAgendas = (agendaCodes: string[]) =>
  axios.delete(AGENDA_BASE_PATH, {
    data: {
      codes: agendaCodes,
    },
  })
export const GetAgendaByCode = (agendaCode: string) =>
  axios.get<AgendaModel>(`${AGENDA_BASE_PATH}/${agendaCode}`)
export const GetAgendaTranslation = (code: string, lang: string) =>
  axios.get<AgendaModel>(`${AGENDA_BASE_PATH}/${code}/${lang}`)
export const CreateAgenda = (params: AgendaModelCreateParams) =>
  axios.post(AGENDA_BASE_PATH, params)
export const UpdateAgenda = (agendaCode: string, params: AgendaModelCreateParams) =>
  axios.put(`${AGENDA_BASE_PATH}/${agendaCode}`, params)

export const GetTopicList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TopicModel>>(`${TOPIC_BASE_PATH}/list`, filter)
export const DeleteTopics = (agendaCodes: string[]) =>
  axios.delete(TOPIC_BASE_PATH, {
    data: {
      codes: agendaCodes,
    },
  })

export const DeletePresentations = (presentationsCode: string[]) =>
  axios.post(
    `${TOPIC_BASE_PATH}/delete-presentation`,

    {codes: presentationsCode}
  )
export const GetTopicByCode = (topicCode: string) =>
  axios.get<TopicModel>(`${TOPIC_BASE_PATH}/${topicCode}`)
export const GetTopicTranslation = (code: string, lang: string) =>
  axios.get<TopicModel>(`${TOPIC_BASE_PATH}/${code}/${lang}`)
export const CreateOrUpdateTopicTranslations = (
  topicCode: string,
  params: TopicModelTranslationCreateParams
) => axios.post<TopicModel>(`${TOPIC_BASE_PATH}/translation/${topicCode}`, params)
export const CreateTopic = (params: TopicModelCreateParams) => {
  return axios.post(TOPIC_BASE_PATH, getTopicFormData(params), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const UpdateTopic = (topicCode: string, params: TopicModelCreateParams) => {
  return axios.put(`${TOPIC_BASE_PATH}/${topicCode}`, getTopicFormData(params), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const GetTopicTypes = () => axios.get(`/topic/type`)
export const GetTopicsTreeByAgenda = (agendaCode: string) =>
  axios.post<TopicModel[]>(`${TOPIC_BASE_PATH}${AGENDA_BASE_PATH}`, {code: agendaCode})

export const UpdatePresentation = (data: PresentationPayload) =>
  axios.post(`${TOPIC_BASE_PATH}/update-presentation`, data)

export const PlayPausePresentation = (
  code: string,
  body: {
    isPresentationStarted: boolean
  }
) => axios.post(`${TOPIC_BASE_PATH}/present/${code}`, body)

export const CreateOrUpdatePollTranslations = (
  code: string,
  params: PollModelTranslationCreateParams
) => axios.post<PollModel>(`${POLL_BASE_PATH}/translation/${code}`, params)
export const GetPollTranslation = (code: string, lang: string) =>
  axios.get<PollModel>(`${POLL_BASE_PATH}/${code}/${lang}`)
export const GetPollList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<PollModel>>(`${POLL_BASE_PATH}/list`, filter)
export const GetPollByCode = (pollCode: string) =>
  axios.get<PollModel>(`${POLL_BASE_PATH}/${pollCode}`)
export const CreatePoll = (params: PollModelCreateParams) => axios.post(POLL_BASE_PATH, params)
export const UpdatePoll = (pollCode: string, params: PollModelCreateParams) =>
  axios.put(`${POLL_BASE_PATH}/${pollCode}`, params)
export const DeletePolls = (codes: string[]) =>
  axios.delete(`${POLL_BASE_PATH}`, {
    data: {codes},
  })
export const StartPolls = (codes: string[]) =>
  axios.post(`${POLL_BASE_PATH}/start`, {
    codes,
  })
export const EndPolls = (codes: string[]) =>
  axios.post(`${POLL_BASE_PATH}/end`, {
    codes,
  })
export const GetEventSpeakers = (eventCode: string, filters?: FilterModel) =>
  axios.post<SpeakerModel>(`/speaker/event/${eventCode}`, filters)
export const SearchSpeakers = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<SpeakerModel>>(`/speaker/list`, filters)
export const DeleteSpeakers = (codes: string[]) =>
  axios.delete(`/speaker`, {
    data: {
      codes,
    },
  })
export const CreateSpeaker = (params: SpeakerModelCreateParams) => axios.post(`/speaker`, params)
export const UpdateSpeaker = (speakerCode: string, params: SpeakerModelCreateParams) =>
  axios.put(`/speaker/${speakerCode}`, params)

export const GetAgendaByDay = (date: Date, eventCode: string) =>
  axios.post<AgendaModel[]>(`/agenda/day`, {
    date: date.toISOString(),
    eventCode,
  })

const getTopicFormData = (params: TopicModelCreateParams) => {
  const formData = new FormData()
  formData.append('title', params.title)
  formData.append('description', params.description)
  formData.append('agendaCode', params.agendaCode)
  formData.append('startedAt', params.startedAt)
  formData.append('endedAt', params.endedAt)
  formData.append('isFeatured', String(params.isFeatured))
  params.topicTypeCode && formData.append('topicTypeCode', params.topicTypeCode)
  params.parentCode && formData.append('parentCode', params.parentCode)
  params.locationCode && formData.append('locationCode', params.locationCode)
  params.photo && formData.append('photo', params.photo)
  params.speakerCodes?.forEach((code) => {
    formData.append('speakerCodes[]', code)
  })
  params.deletedFileCodes?.forEach((code) => {
    formData.append('deletedFileCodes[]', code)
  })
  params.emsFiles?.forEach((blob) => {
    formData.append('emsFiles', blob)
  })
  params.presentationFiles?.forEach((blob) => {
    formData.append('presentationFiles', blob)
  })
  if (params.translations) {
    Object.entries(params.translations).forEach(([lang, translation]) => {
      formData.append(`translations[${lang}]`, JSON.stringify(translation))
    })
  }
  return formData
}
