import {FormikContextType} from 'formik'
import {useCallback} from 'react'

export const useFormikMobileNumberInputHelpers = <Values extends Record<string, any>>(
  formik: FormikContextType<Values>
) => {
  const onChange = useCallback(
    (name: string, value: string) => {
      formik.setFieldTouched(name)
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const getFieldProps = useCallback(
    (fieldName: string) => {
      return {
        onChange: (value: string) => onChange(fieldName, value),
        value: formik.values[fieldName],
        onBlur: () => {
          formik.setFieldTouched(fieldName, true)
        },
        errorMessage: formik.errors[fieldName] as string,
        isTouched: formik.touched[fieldName] as boolean,
      }
    },
    [formik, onChange]
  )

  return {
    getFieldProps,
  }
}
