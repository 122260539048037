import {useColorScalePreset} from './useColorScalePreset'

export interface UseColorScalePresetProps {
  domain: (string | number)[]
}

export const useDefaultColorScalePreset = ({domain}: UseColorScalePresetProps) => {
  return useColorScalePreset({domain, colors})
}

const colors = [
  '#198996',
  '#2bb69f',
  '#24673c',
  '#e1a94c',
  '#e69206',
  '#df6225',
  '#813713',
  '#a8692c',
  '#775945',
]
