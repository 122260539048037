import {FormikContextType} from 'formik'
import {uniq} from 'lodash'

import {EventModel} from '../../../../../../models/ems/EventModel'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {concat} from 'lodash'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {Button} from '../../../../../../components/inputs/Button'
import {ProductLocationWizardTable} from '../table/ProductLocationWizardTable'
import { ReservationInputItemValue } from '../../../../../../components/inputs/ReservationInput/ReservationInputItem'
import { AddProductLocationModal } from '../../../../../default/ems/components/wizards/ReservationWizard/modals/AddProductLocationModal'
import { EditProductLocationModal } from '../../../../../default/ems/components/wizards/ReservationWizard/modals/EditProductLocationModal'
import { ActivityModel } from '../../../../../../models/ems/ActivityModel'
import { ReservationFormValues } from '../ReservationWizard'
import { useSelector } from 'react-redux'
import { SwalUtils } from '../../../../../../utils/SwalUtils'
import { useOnChange } from '../../../../../../components/hooks/useOnChange'
import { RootState } from '../../../../../../../setup'
import { ProductLocationModel } from '../../../../../../models/ems/ReservationModel'
import { useReservationPortalData } from '../hooks/useReservationPortalData'
import { VoucherModel } from '../../../../../../models/svc/VoucherModel'
import { ListCountInputItemValue } from '../../../../../../components/inputs/VoucherInput/ListCountInputItem'

export interface ReservationWizardProductVenueStepFormValues {
  products: ReservationInputItemValue<ProductModel>[]
  vouchers: ListCountInputItemValue<VoucherModel>[]
  eventCode: string
}

export interface ReservationWizardProductVenueStepProps<
  T extends ReservationWizardProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | ActivityModel | null
}
export type ProductLocationModalType = 'product' | 'voucher' | undefined

export const ReservationWizardProductVenueStep = <
  T extends ReservationWizardProductVenueStepFormValues
>({
  formik,
  event,
}: ReservationWizardProductVenueStepProps<T>) => {
  const [modalType, setModalType] = useState<ProductLocationModalType>()
  const [eventCode, setEventCode] = useState('')
  const [currentSelectedProductVoucher, setCurrentSelectedProductVoucher] =
    useState<ProductLocationModel>()
  const [productsSelectedValues, setProductsSelectedValues] = useState<
    ReservationInputItemValue<ProductModel>[]
  >([])

  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {productSearchResults, refreshProductsList,  searchVouchers, voucherSearchResults} = useReservationPortalData({
    eventCode: event?.code || formik.values.eventCode,
  })
  const [vouchersSelectedValues, setVouchersSelectedValues] = useState<
  ListCountInputItemValue<VoucherModel>[]
  >([])
  const reservationForm: ReservationFormValues = useSelector<RootState>(
    ({ems}) => ems.reservationForm
  ) as ReservationFormValues

  useEffect(() => {
    refreshProductsList()
    searchVouchers()
    if (event) {
      setEventCode(event?.code)
    }
  }, [event, refreshProductsList, searchVouchers])

  const isAllProductsSelected = useMemo(() => {
    return productSearchResults
      ? productSearchResults.data.every((item: any) => {
          const isSelected = formik.values.products.some((value) => value.data?.code === item.code)
          if (isSelected) {
            return true
          }
          return false
        })
      : false
  }, [formik.values.products, productSearchResults])

  const productLocationTableData = useMemo(() => {
    const all = concat(formik.values.products, formik.values.vouchers as any)
      .map((item) => {
        if (item.data && item.data.name !== '' && !item.isSeated) {
          return {
            code: item.data.code,
            name: item.data.name,
            qty: item?.count,
            type: item.type,
            isSeated: item.data.isSeated,
          }
        }
        return null
      })
      .filter((item): item is ProductLocationModel => item !== null)

    const uniqueData = all.reduce((acc: ProductLocationModel[], curr) => {
      if (!acc.some((item) => item.code === curr.code)) {
        acc.push(curr)
      }
      return acc
    }, [])

    const data: GlobalSearchModel<ProductLocationModel> = {
      data: uniqueData,
      total: uniqueData.length,
      page: 1,
      limit: 1000,
    }

    let newData = {...data}
    newData.data = newData.data
    .filter((item) => {
        const nameMatch = item?.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        const codeMatch = item?.code?.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        return nameMatch || codeMatch;
    })
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);
return newData;
  }, [formik.values.products, formik.values.vouchers, currentPage, pageSize, search]);

  const handleCloseModal = useCallback(() => {
    if (modalType === 'product') refreshProductsList()
    if (modalType === 'voucher') searchVouchers()
    setModalType(undefined)
    setCurrentSelectedProductVoucher(undefined)
    if (productsSelectedValues && productsSelectedValues.length) {
      setProductsSelectedValues((products) => products.filter((item) => item.data !== null))
    }
    setIsAddProdClick(false)
    if (vouchersSelectedValues && vouchersSelectedValues.length) {
      setVouchersSelectedValues((voucher) =>
        voucher.filter((item) => item.data !== null && !item.isNew)
      )
    }
  }, [modalType, productsSelectedValues, refreshProductsList, searchVouchers, vouchersSelectedValues])

  const handleAddProductOrLocation = useCallback(() => {
    handleCloseModal()
    setTimeout(() => {
      let productsWithoutIsNew = [...productsSelectedValues]
      productsWithoutIsNew = productsWithoutIsNew
        .filter((item) => item && item !== null)
        .map((item) => {
          return {
            id: item.id,
            data: item.data,
            count: item.count,
            isSeated: item.isSeated,
            type: 'product',
            seatMap: item.seatMap,
            locationCode: item.locationCode,
            isFromAddProd: item.isFromAddProd,
          }
        })
        let vouchersWithoutIsNew = [...vouchersSelectedValues]

        vouchersWithoutIsNew = vouchersWithoutIsNew
          .filter((item) => item.data && item.data !== null)
          .map((item) => {
            return {
              id: item.id,
              data: item.data,
              count: item.count,
              type: 'voucher',
              isSeated: false,
              seatMap: null,
              locationCode: '',
              isFromAddProd: false,
            }
          })
  
      if (productsWithoutIsNew) formik.setFieldValue('products', productsWithoutIsNew)
      if (vouchersWithoutIsNew) formik.setFieldValue('vouchers', vouchersWithoutIsNew)
      handleCloseModal()
    }, 200)
  }, [formik, handleCloseModal, productsSelectedValues, vouchersSelectedValues])

  const handleOnChangeProduct = useCallback(
    (newValues: ReservationInputItemValue<ProductModel>[]) => {
      setProductsSelectedValues(newValues)
    },
    []
  )

  const handleOnEditProductVoucher = useCallback(
    (type: ProductLocationModalType, value: ProductLocationModel) => {
      let newProductsValues = [...formik.values.products]
      let newVouchersValues = [...formik.values.vouchers]
      let index = -1
      if (type === 'product') {
        const found = newProductsValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })
        if (found) {
          newProductsValues[index] = {
            ...found,
            count: value.qty,
          }

          formik.setFieldValue('products', newProductsValues)
          handleCloseModal()
        }
      }

      if (type === 'voucher') {
        const found = newVouchersValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })

        if (found) {
          newVouchersValues[index] = {
            ...found,
            count: value.qty,
          }

          formik.setFieldValue('vouchers', newVouchersValues)
          handleCloseModal()
        }
      }
    },
    [formik, handleCloseModal]
  )

  const filterProductVoucherHandler = useCallback((filter: FilterModel) => {
    const searchTerm = (filter.filters?.search || '') as string;
    setSearch(searchTerm.toLocaleLowerCase());
  }, [])

  const deleteByCodes = useCallback(
    async (codes: string[]) => {
      if (codes) {
        const response = await SwalUtils.deleteItem()
        if (response.isConfirmed) {
          let formikProductValues = [...formik.values.products]
          let formikVoucherValues = [...formik.values.vouchers]
          for (let i = 0; i < codes.length; i++) {
            const foundProduct = formikProductValues.find((item) => item?.data?.code === codes[i])
            const foundvoucher = formikVoucherValues.find((item) => item?.data?.code === codes[i])


            if (foundProduct) {
              formikProductValues = formikProductValues.filter(
                (item) => item.data?.code !== foundProduct.data?.code
              )
            } else if (foundvoucher) {
              formikVoucherValues = formikVoucherValues.filter(
                (item) => item.data?.code !== foundvoucher.data?.code
              )
            }
          }
          formik.setFieldValue('products', formikProductValues)
          setProductsSelectedValues(formikProductValues)
          formik.setFieldValue('vouchers', formikVoucherValues)
          setVouchersSelectedValues(formikVoucherValues)
        }
      }
    },
    [formik]
  )

  const handleDeleteProducts = useCallback(
    (data: ProductLocationModel) => {
      deleteByCodes([data.code])
    },
    [deleteByCodes]
  )

  const handleEditProductsVouchers = useCallback(
    (data: ProductLocationModel) => {
      if (productSearchResults && productSearchResults.data?.length) {
        const p = productSearchResults.data.find((item: any) => item.code === data.code)
        if (p) setModalType('product')
      }
      if (voucherSearchResults && voucherSearchResults.data?.length) {
        const v = voucherSearchResults.data.find((item) => item.code === data.code)
        if (v) setModalType('voucher')
      }
      setCurrentSelectedProductVoucher(data)
    },
    [productSearchResults, voucherSearchResults]
  )

  const productsValues = useMemo(() => {
    if (productsSelectedValues.length !== formik.values.products.length) {
      const newData = productsSelectedValues.concat(formik.values.products)
      return uniq(newData)
    } else return formik.values.products
  }, [formik.values.products, productsSelectedValues])

  useOnChange(reservationForm, () => {
    if (reservationForm) {
      setTimeout(() => {
        if (reservationForm.products) {
          setProductsSelectedValues(reservationForm.products)
          formik.setFieldValue('products', reservationForm.products)
        }
        if (reservationForm.vouchers) {
          setVouchersSelectedValues(reservationForm.vouchers)
          formik.setFieldValue('vouchers', reservationForm.vouchers)
        }
      }, 200)
    }
  })

  const [isAddProdClick, setIsAddProdClick] = useState(false)

  const newAdd = useCallback(() => {
    setModalType('product')
    setIsAddProdClick(true)
  }, [])

  const handleAddItemClick = () => {
    setIsAddProdClick(false)
  }

  const productErrors = useMemo(() => {
    if (formik.errors.products)
      return (
        <div className='col-12'>
          <h5 className='my-5 px-8'>{formik.errors.products}</h5>
        </div>
      )
  }, [formik.errors.products])

  const vouchersValues = useMemo(() => {
    if (vouchersSelectedValues.length !== formik.values.vouchers.length)
      return vouchersSelectedValues
    else return formik.values.vouchers
  }, [formik.values.vouchers, vouchersSelectedValues])


  const handleVoucherChange = useCallback((newValues: ListCountInputItemValue<VoucherModel>[]) => {
    setVouchersSelectedValues(newValues)
  }, [])

  const isAllVouchersSelected = useMemo(() => {
    return voucherSearchResults?.data.every((item) => {
      const isSelected = formik.values.vouchers.some((value) => value.data?.code === item.code)
      if (isSelected) {
        return true
      }
      return false
    })
  }, [formik.values.vouchers, voucherSearchResults?.data])


  return (
    <div className='container'>
      <div className='row'>
        <div className='d-flex gap-3 px-11 pt-5 pb-3'>
          <Button
            className='btn btn-primary px-10'
            onClick={newAdd}
            disabled={isAllProductsSelected}
          >
            {isAllProductsSelected ? 'No Products available' : 'Add Product'}
          </Button>
          <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('voucher')
            }}
            disabled={isAllVouchersSelected}
          >
            {isAllVouchersSelected ? 'No Vouchers available' : 'Add Voucher'}
          </Button>
        </div>
        {productErrors}

        <div>
          <ProductLocationWizardTable
            onDelete={handleDeleteProducts}
            onEdit={handleEditProductsVouchers}
            data={productLocationTableData}
            onFilter={filterProductVoucherHandler}
            eventCode={eventCode}
            formik={formik}
            currentPageNumber={currentPage}
            currentPageSize={pageSize}
            onChangePageNumber={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </div>
      </div>
      {currentSelectedProductVoucher ? (
        <EditProductLocationModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          data={currentSelectedProductVoucher}
          onEdit={handleOnEditProductVoucher}
        />
      ) : (
        <AddProductLocationModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          productSearchResults={productSearchResults}
          refreshProductsList={refreshProductsList}
          productsValues={productsValues}
          onProductChange={handleOnChangeProduct}
          onAdd={handleAddProductOrLocation}
          isAddProdClick={isAddProdClick}
          onAddItemClick={handleAddItemClick}
          isPortal
          searchVouchers={searchVouchers}
          vouchersValues={vouchersValues}
          onVoucherChange={handleVoucherChange}
          voucherSearchResults={voucherSearchResults}
        />
      )}
    </div>
  )
}
