import {useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CustomerModel} from '../../../models/CustomerModel'
import {useAppConfig} from '../../../modules/app-config/hooks/useAppConfig'
import {ImageInputValue} from '../../inputs/FileInput/ImageInputValue'

interface FinalStepCustomerHeaderProps {
  customer: CustomerModel
}

export const FinalStepCustomerHeader = ({customer}: FinalStepCustomerHeaderProps) => {
  const {staticUrls} = useAppConfig()
  const avatar = useMemo(() => {
    if (customer?.photo) {
      const imageValue = new ImageInputValue(staticUrls.public, customer.photo)
      return imageValue.url
    }
  }, [customer?.photo, staticUrls.public])
  return (
    <div className='card'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-row'>
                {avatar && (
                  <div className='d-flex drag-drop-file-input-drop-area-thumbnail'>
                    <img src={avatar} alt='' className=' h-100' />
                  </div>
                )}

                <div className='d-flex flex-column px-2'>
                  <div className='d-flex align-items-center'>
                    <p className='text-gray-800 fs-2 fw-bolder mb-1'>{customer?.name}</p>
                  </div>
                  {customer?.mobile && customer?.mobile.length > 0 && (
                    <div className='d-flex flex-wrap mt-4 mb-2 pe-2'>
                      <span className='d-flex align-items-center me-5'>
                        <KTSVG
                          path='/media/icons/duotone/Devices/Phone.svg'
                          className='svg-icon-4 me-1'
                        />
                        {customer?.mobile}
                      </span>
                    </div>
                  )}
                  {customer?.email && customer?.email.length > 0 && (
                    <div className='d-flex flex-wrap mb-4 pe-2'>
                      <span className='d-flex align-items-center text-muted me-5 mb-2'>
                        <KTSVG
                          path='/media/icons/duotone/Communication/Mail.svg'
                          className='svg-icon-4 me-1'
                        />
                        {customer?.email}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
