import {ReactNode} from 'react'
import {NavigationSectionMenuItem} from '../../../../config/drawer'
import {Content} from '../../../../_metronic/layout/components/Content'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {MasterInit} from '../../../../_metronic/layout/MasterInit'
import {Main} from '../../../../_metronic/partials'
import {Header} from './Header/Header'
import {LayoutPortalProvider} from './LayoutPortalProvider'

export interface LayoutProps {
  children?: ReactNode
  menus: NavigationSectionMenuItem[]
  controls?: ReactNode
}

export const Layout = ({children, menus}: LayoutProps) => {
  return (
    <PageDataProvider>
      <LayoutPortalProvider>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <Header menus={menus} />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Main />
        <MasterInit />
        <ScrollTop />
      </LayoutPortalProvider>
    </PageDataProvider>
  )
}
