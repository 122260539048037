import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {GuestCountPerProductStatusModel} from '../../../../../../models/ems/WidgetModel'

export interface GuestCountPerProductTooltipProps {
  data?: GuestCountPerProductStatusModel[]
  event?: EventModel
  filterKey: string
}

export const GuestCountPerProductTooltip = ({
  data,
  event,
  filterKey,
}: GuestCountPerProductTooltipProps) => {
  const {key: datumKey} = useBarChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (datumKey && data) {
      return data.find((item) => item.code === datumKey || item.code === datumKey)
    }
  }, [data, datumKey])

  if (!hoveredData) {
    return null
  }

  return (
    <MarkdownTooltip>{`
### ${hoveredData.name}

**Event**: ${event?.name}

**Total**: ${hoveredData[filterKey as keyof GuestCountPerProductStatusModel] || 0}
`}</MarkdownTooltip>
  )
}
