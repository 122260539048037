import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Zoom} from '@visx/zoom'
import {localPoint} from '@visx/event'
import {MercatorMapFeature} from './MercatorMap'
import {MercatorMapOptimized} from './MercatorMapOptimized'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import {ReactNode, useCallback, useRef} from 'react'
import {TooltipContextProvider} from '../TooltipContext/TooltipContextProvider'

export type MercatorChartProps = {
  getColor?: (feature: MercatorMapFeature) => string
  tooltip?: ReactNode
}

export const MercatorChart = ({getColor, tooltip}: MercatorChartProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })
  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<MercatorMapFeature>()

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handleMapMouseMove = useCallback(
    (data: MercatorMapFeature) => {
      showTooltip({
        tooltipData: data,
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  return (
    <div ref={containerRef} className='h-100 w-100'>
      <ParentSize onMouseMove={handleContainerMouseMove} onMouseOut={hideTooltip}>
        {({width, height}) => {
          const centerX = width / 2
          const centerY = height / 2
          const scale = (width / 630) * 100
          return width < 10 ? null : (
            <>
              <Zoom<SVGSVGElement> width={width} height={height} scaleXMin={1} scaleYMin={1}>
                {(zoom) => (
                  <svg
                    width={width}
                    height={height}
                    ref={zoom.containerRef}
                    style={{
                      position: 'relative',
                      height,
                      width,
                      cursor: zoom.isDragging ? 'grabbing' : 'grab',
                      touchAction: 'none',
                    }}
                  >
                    <rect
                      width={width}
                      height={height}
                      rx={14}
                      fill='transparent'
                      onTouchStart={zoom.dragStart}
                      onTouchMove={zoom.dragMove}
                      onTouchEnd={zoom.dragEnd}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={zoom.dragMove}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => {
                        if (zoom.isDragging) zoom.dragEnd()
                      }}
                      onDoubleClick={(event) => {
                        const point = localPoint(event) || {x: 0, y: 0}
                        zoom.scale({scaleX: 1.1, scaleY: 1.1, point})
                      }}
                    />
                    <g transform={zoom.toString()}>
                      <MercatorMapOptimized
                        onMouseMove={handleMapMouseMove}
                        scale={scale}
                        translate={[centerX, centerY]}
                        getColor={getColor}
                      />
                    </g>
                  </svg>
                )}
              </Zoom>
              <TooltipContextProvider value={tooltipData}>
                {tooltipOpen && (
                  <TooltipInPortal
                    left={tooltipLeft}
                    top={tooltipTop}
                    style={{
                      ...defaultStyles,
                      background: 'none',
                      boxShadow: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {tooltip}
                  </TooltipInPortal>
                )}
              </TooltipContextProvider>
            </>
          )
        }}
      </ParentSize>
    </div>
  )
}
