import {Mercator} from '@visx/geo'
import {memo} from 'react'
import * as topojson from 'topojson-client'
import topology from './world-topo.json'

export interface MercatorMapProps {
  getColor?: (feature: MercatorMapFeature) => string
  translate?: [number, number]
  scale?: number
  onMouseMove?: (feature: MercatorMapFeature) => void
}

export const MercatorMap = memo(({getColor, translate, scale, onMouseMove}: MercatorMapProps) => {
  return (
    <Mercator<MercatorMapFeature> data={world.features} translate={translate} scale={scale}>
      {(mercator) => (
        <g>
          {mercator.features.map(({feature, path}, i) => (
            <path
              key={`map-feature-${i}`}
              d={path || ''}
              fill={getColor?.(feature)}
              strokeWidth={0.1}
              stroke='white'
              onMouseMove={() => onMouseMove?.(feature)}
            />
          ))}
        </g>
      )}
    </Mercator>
  )
})

export interface MercatorMapFeature {
  type: 'Feature'
  id: string
  geometry: {coordinates: [number, number][][]; type: 'Polygon'}
  properties: {name: string}
}

// @ts-ignore
const world = topojson.feature(topology, topology.objects.units) as {
  type: 'FeatureCollection'
  features: MercatorMapFeature[]
}
