import moment from 'moment'
import {useCallback, useMemo} from 'react'
import {MetronicIconButton} from './MetronicIconButton'

export interface MonthPaginationInputProps {
  value: Date
  onChange: (date: Date) => void
}

export const MonthPaginationInput = ({onChange, value}: MonthPaginationInputProps) => {
  const handleIncrement = useCallback(() => {
    onChange(moment(value).add(1, 'month').toDate())
  }, [onChange, value])

  const handleDecrement = useCallback(() => {
    onChange(moment(value).subtract(1, 'month').toDate())
  }, [onChange, value])

  const monthName = useMemo(() => {
    return moment(value).format('MMMM, YYYY')
  }, [value])

  return (
    <div className='d-inline-flex align-items-center'>
      <MetronicIconButton
        variant='text'
        iconType='Navigation'
        iconName='Angle-left'
        onClick={handleDecrement}
      />
      <div className='fw-bolder text-center' style={{minWidth: '10rem'}}>
        {monthName}
      </div>
      <MetronicIconButton
        variant='text'
        iconType='Navigation'
        iconName='Angle-right'
        onClick={handleIncrement}
      />
    </div>
  )
}
