import clsx from 'clsx'
import {useMemo} from 'react'
import {
  BarChartDatum,
  HorizontalBarChart,
} from '../../../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {Paper} from '../../../../../../components/utils/Paper'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ProductIsOkToBeDeliveredWidgetModel} from '../../../../../../models/ems/WidgetModel'
import {ToBeDeliveredProductsChartTooltip} from './ToBeDeliveredProductsChartTooltip'

export interface ToBeDeliveredProductsChartProps {
  data?: ProductIsOkToBeDeliveredWidgetModel[]
  event?: EventModel
  className?: string
}

export const ToBeDeliveredProductsChart = ({
  data,
  event,
  className,
}: ToBeDeliveredProductsChartProps) => {
  const datum = useMemo((): BarChartDatum[] => {
    if (data) {
      return data.map((d) => ({
        key: d.code,
        label: d.name,
        value: d.total,
      }))
    }
    return []
  }, [data])

  return (
    <Paper className={clsx('p-10 pt-8 d-flex flex-column', className)} rounded>
      <p className='mb-3 fs-1'>To Be Delivered</p>
      <div className='flex-grow-1 p-10'>
        <HorizontalBarChart
          datum={datum}
          xLabel='Category'
          yLabel='Total'
          tooltip={<ToBeDeliveredProductsChartTooltip data={data} event={event} />}
        />
      </div>
    </Paper>
  )
}
