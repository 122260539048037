import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export interface LogoProps {
  className?: string
}

export const Logo = ({className}: LogoProps) => {
  return (
    <img
      alt='Logo'
      src={toAbsoluteUrl('/media/saas/webntech.svg')}
      className={clsx('h-30px mb-12', className)}
    />
  )
}
