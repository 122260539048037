import {useMemo} from 'react'
import {useMercatorChartTooltipContext} from '../../../../../../components/charts/MercatorChart/useMercatorChartTooltipContext'
import {Paper} from '../../../../../../components/utils/Paper'
import moment from 'moment'

export interface EventMercatorChartTooltipProps {
  data?: EventMapData[]
}

export const EventMercatorChartTooltip = ({data}: EventMercatorChartTooltipProps) => {
  const tooltipData = useMercatorChartTooltipContext()

  const focusedData = useMemo(() => {
    return data?.find((item) => item.countryCode === tooltipData?.id)
  }, [data, tooltipData?.id])

  if (!tooltipData || !focusedData) {
    return null
  }

  return (
    <Paper rounded className='p-5'>
      <h1>{tooltipData.properties.name}</h1>
      {focusedData.events.map((event) => (
        <div key={event.name}>
          <h3>{event.name}</h3>
          <p>
            {moment(event.date[0]).format('YYYY-MM-DD')} -{' '}
            {moment(event.date[1]).format('YYYY-MM-DD')}
          </p>
          <p>Total visitors: {event.total}</p>
        </div>
      ))}
    </Paper>
  )
}

interface EventMapData {
  events: EventData[]
  countryCode: string
}

interface EventData {
  total: number
  name: string
  date: [Date, Date]
}
