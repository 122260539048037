import {useMemo} from 'react'
import {useDefaultColorScalePreset} from '../../../../../../components/charts/hooks/useDefaultColorScalePreset'
import {PieChart} from '../../../../../../components/charts/PieChart/PieChart'
import {PieChartDatum} from '../../../../../../components/charts/PieChart/PieChartSegment'
import {FilterType} from '../../../../../../components/tables/constants/FilterType'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {GuestCountPerStatusModel} from '../../../../../../models/ems/WidgetModel'
import {GuestCountPerStatusTooltip} from './GuestCountPerStatusTooltip'

export interface GuestCountPerProductProps {
  data?: GuestCountPerStatusModel[]
  event?: EventModel | null
}

export const GuestCountPerStatus = ({data, event}: GuestCountPerProductProps) => {
  const pieChartDatum = useMemo((): PieChartDatum[] => {
    const items: PieChartDatum[] = FilterType.GUEST_STATUS.map((item) => ({
      key: item.value,
      label: item.label,
      value: 0,
    }))
    if (data) {
      data?.forEach((datum) => {
        const index = items.findIndex((item) => item.key === datum.status)
        if (index >= 0) {
          items[index].value = datum.total
        }
      })
    }
    return items
  }, [data])

  const domain = useMemo(() => pieChartDatum.map((datum) => datum.key), [pieChartDatum])

  const getColor = useDefaultColorScalePreset({domain})

  return (
    <PieChart
      datum={pieChartDatum}
      tooltip={<GuestCountPerStatusTooltip data={data} event={event} />}
      getColor={getColor}
    />
  )
}
