import {useCallback, useMemo} from 'react'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import {
  LinkBreadCrumb,
  LinkBreadcrumbsToolbar,
} from '../../../../components/layouts/DefaultLayout/LinkBreadcrumbsToolbar'
import {Paper} from '../../../../components/utils/Paper'
import {ActivityModel} from '../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {BookingEditWizard} from '../../components/wizards/BookingWizard/BookingEditWizard'

interface PortalEventDetailsBookingEditProps {
  event?: EventModel | ActivityModel | null
  step?: number
}

interface RouteProps {
  params: {
    customerCode?: string
  }
}

export const PortalEventDetailsBookingEdit = ({
  event,
  step,
}: PortalEventDetailsBookingEditProps) => {
  const location = useLocation<{breadcrumbs?: LinkBreadCrumb[]; from?: string}>()
  const history = useHistory()
  const {
    params: {customerCode},
  }: RouteProps = useRouteMatch()
  const breadcrumbs = useMemo(() => {
    const breadcrumbs: LinkBreadCrumb[] = location.state?.breadcrumbs
      ? [...location.state.breadcrumbs]
      : [
          {
            label: 'Bookings',
            link: customerCode
              ? `/customer/${customerCode}/booking`
              : `/event/${event?.code}/booking`,
          },
        ]
    breadcrumbs.push({
      label: 'Edit Booking',
    })
    return breadcrumbs
  }, [customerCode, event?.code, location?.state?.breadcrumbs])

  const handleSuccess = useCallback(() => {
    if (event) history.replace(`/event/${event.code}/booking`)
    if (customerCode) history.replace(`/customer/${customerCode}/booking`)
  }, [customerCode, event, history])

  return (
    <>
      <LinkBreadcrumbsToolbar title='Edit Booking' breadcrumbs={breadcrumbs} />
      <div className='container'>
        <Paper rounded className='py-10'>
          <BookingEditWizard onSuccess={handleSuccess} event={event} step={step} />
        </Paper>
      </div>
    </>
  )
}
