import {useCallback, useEffect, useState} from 'react'
import {SweetAlert} from '../../modals/SweetAlert'
import {Button} from '../Button'
import {QRCodeInput} from './QRCodeInput'

export interface QRCodeModalInputProps {
  onClose: () => void
  open: boolean
  autoSubmit?: boolean
  onSubmit: (value: string) => void
  loading?: boolean
  hideLabel?: boolean
  hideButton?: boolean
}

export const QRCodeModalInput = ({
  open,
  onClose,
  autoSubmit,
  onSubmit,
  loading,
  hideButton,
  hideLabel,
}: QRCodeModalInputProps) => {
  const [value, setValue] = useState('')

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleChange = useCallback(
    (value: string) => {
      setValue(value)
      if (autoSubmit) {
        onSubmit && onSubmit(value)
      }
    },
    [autoSubmit, onSubmit]
  )

  const handleClick = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  useEffect(() => {
    setValue('')
  }, [open])

  return (
    <SweetAlert onClose={handleClose} open={open} showConfirmButton={false}>
      {!hideLabel && (
        <div className='mb-3'>
          <span>{value}</span>
        </div>
      )}
      <QRCodeInput disabled={loading} scanDelay={1000} className='w-100' onChange={handleChange} />
      {!hideButton && value && <Button onClick={handleClick}>OK</Button>}
    </SweetAlert>
  )
}
