import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useWebSocket} from '../../../../components/utils/WebSocketProvider'
import {WidgetModel} from '../../../../models/fnb/WidgetModel'
import {GetWidgetData} from '../redux/FnbCRUD'

export interface UseOutletDashboardWidgetDataOptions {
  widgets: (keyof WidgetModel)[]
  date: Date | null
  eventCode?: string
  outletCode?: string
}

export const useOutletDashboardWidgetData = ({
  date,
  eventCode,
  outletCode,
  widgets,
}: UseOutletDashboardWidgetDataOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {socket} = useWebSocket()

  const resetWidgetData = useCallback(async () => {
    if (eventCode && date) {
      const {data} = await GetWidgetData(widgets, eventCode, date.toISOString(), outletCode)
      setWidgetData(data)
    }
  }, [date, eventCode, outletCode, widgets])

  const isSameDay = useMemo(() => Boolean(date && moment(date).isSame(new Date(), 'day')), [date])

  useEffect(() => {
    if (eventCode && isSameDay) {
      const handlers = widgets.map((widget) => {
        let event: string

        if (outletCode) {
          event = `${widget}:${eventCode}:${outletCode}`
        } else {
          event = `${widget}:${eventCode}`
        }

        return {
          event,
          callback: (data: {data: WidgetModel[keyof WidgetModel]}) => {
            setWidgetData((widgetData) => ({...widgetData, [widget]: data.data}))
          },
        }
      })

      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })

      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [eventCode, isSameDay, outletCode, socket, widgets])

  useEffect(() => {
    resetWidgetData()
  }, [resetWidgetData])

  return {data: widgetData}
}
