import {useMemo} from 'react'
import {DetailHeaderTab, DetailHeaderTabProps} from './DetailHeaderTab'

export interface DetailHeaderTabListProps {
  tabs: DetailHeaderTabProps[]
}

export const DetailHeaderTabList = ({tabs}: DetailHeaderTabListProps) => {
  const tabNodes = useMemo(() => {
    if (tabs) {
      return tabs.map((tab) => {
        return <DetailHeaderTab key={tab.label} {...tab} />
      })
    }
    return null
  }, [tabs])

  return (
    <div className='d-flex overflow-auto h-55px'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabNodes}
      </ul>
    </div>
  )
}
