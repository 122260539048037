import clsx from 'clsx'
import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {MetronicIcon} from '../../../inputs/MetronicIcon'
import {useLayoutHelpers} from '../useLayoutHelpers'
import {NavButtonMenu} from './NavButtonMenu'

export interface NavButtonProps {
  item: NavigationSectionMenuItem
  className?: string
}

export const NavButton = ({item, className}: NavButtonProps) => {
  const {childrenIsActive, isRouteActive} = useLayoutHelpers()
  const isActive = useMemo(
    () => isRouteActive(item.to) || childrenIsActive([item]),
    [childrenIsActive, isRouteActive, item]
  )

  const buttonClassNames = useMemo(
    () =>
      clsx(
        'btn btn-text rotate rounded-0',
        {
          'bg-light text-primary': isActive,
        },
        className
      ),
    [className, isActive]
  )

  const content = useMemo(() => {
    if (item.subMenus) {
      return (
        <>
          <button
            type='button'
            className={clsx(buttonClassNames, className)}
            data-kt-menu-trigger='hover'
            data-kt-menu-placement={item.direction === 'right' ? 'right-start' : 'bottom-start'}
          >
            {item.title}
            <MetronicIcon
              className='rotate-180 ms-3 me-0'
              iconType='Navigation'
              iconName='Angle-down'
            />
          </button>
          <NavButtonMenu items={item.subMenus} />
        </>
      )
    }
    if (item.to) {
      return (
        <Link to={item.to}>
          <button type='button' className={clsx(buttonClassNames, className)}>
            {item.title}
          </button>
        </Link>
      )
    }
  }, [buttonClassNames, className, item.direction, item.subMenus, item.title, item.to])

  return <div>{content}</div>
}
