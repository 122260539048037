import {memo, useMemo} from 'react'
import {useDebouncedValue} from '../../hooks/useDebouncedValue'
import {MercatorMap, MercatorMapProps} from './MercatorMap'

export interface MercatorMapOptimizedProps extends MercatorMapProps {}

export const MercatorMapOptimized = memo(
  ({getColor, translate, scale, onMouseMove}: MercatorMapOptimizedProps) => {
    const translateX = useMemo(() => {
      return translate?.[0]
    }, [translate])
    const translateY = useMemo(() => {
      return translate?.[1]
    }, [translate])
    const translateOptimized = useMemo((): [number, number] | undefined => {
      if (translateX !== undefined && translateY !== undefined) {
        return [translateX, translateY]
      }
    }, [translateX, translateY])

    const debouncedValues = useDebouncedValue(
      useMemo(
        () => ({
          getColor,
          scale,
          onMouseMove,
          translateOptimized,
        }),
        [getColor, onMouseMove, scale, translateOptimized]
      )
    )

    return (
      <MercatorMap
        getColor={debouncedValues.getColor}
        scale={debouncedValues.scale}
        onMouseMove={debouncedValues.onMouseMove}
        translate={debouncedValues.translateOptimized}
      />
    )
  }
)
