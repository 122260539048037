import {FormikContextType} from 'formik'
import {ImageInputValue} from '../../../../../../components/inputs/FileInput/ImageInputValue'
import {RichTextEditorValue} from '../../../../../../components/inputs/RichTextEditor/RichTextEditor'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {CustomerCategoryModel} from '../../../../../../models/svc/CustomerCategoryModel'
import {CustomFieldCreateValue} from '../../../../../../models/system/CustomFieldModel'
import {CustomFieldInputValue} from '../../../../../default/system/components/TypeFieldInput/CustomFieldTypeInput'
import * as Yup from 'yup'
import {ISOCountryModel} from '../../../../../../models/ISOCountryModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {LocationModel} from '../../../../../../models/acs/LocationModel'
import {useTranslationFieldHelpers} from '../../../../../../components/inputs/hooks/useTranslationFieldHelpers'
import {Fragment, useCallback, useMemo} from 'react'
import {SelectInput, SelectInputItem} from '../../../../../../components/inputs/SelectInput'
import {useFormikTextInputHelpers} from '../../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {TreeNodeItem, TreeSelect} from '../../../../../../components/inputs/TreeSelect/TreeSelect'
import {ApiTree} from '../../../../../../utils/Tree/ApiTree'
import {DatePickerInput, TextInput} from '../../../../../../components/inputs'
import {FilterSearchableSelectInput} from '../../../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {selectItemMapper} from '../../../../../../utils/idExtractor'
import {AvatarImageInput} from '../../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import {HorizontalDivider} from '../../../../../../components/utils/HorizontalDivider'

export interface CustomerFormValues {
  firstName: string
  lastName: string
  email: string
  designation: string
  organization: string
  type: string
  avatar: ImageInputValue | null
  mobile: string
  gender: string
  nationality: string
  residence: string
  salutation: string
  dob: Date | null
  addressName: string
  addressLine1: string
  addressLine2: string
  addressCity: string
  addressStateOrProvince: string
  addressPostCode: string
  addressCountryCode: string
  bio: RichTextEditorValue | null
  category: null | CustomerCategoryModel
  product: null | ProductModel
  imageHeight?: number
  imageWidth?: number
  translations: {
    [lang: string]: {
      firstName: string
      lastName: string
      designation: string
      organization: string
      salutation: string
      bio: RichTextEditorValue | null
      customFields: CustomFieldCreateValue[]
    }
  }
  customFields: CustomFieldInputValue[]
  locationCodes?: string[]
}

const requiredIfHasAddress = (value?: string, context?: Yup.TestContext<unknown>) => {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressCountryCode,
    addressName,
    addressPostCode,
    addressStateOrProvince,
  } = context?.parent as CustomerFormValues

  const addressFields = {
    addressLine1,
    addressLine2,
    addressCity,
    addressCountryCode,
    addressName,
    addressPostCode,
    addressStateOrProvince,
  }

  const hasAddressField = Object.values(addressFields).some((value) => Boolean(value))

  if (hasAddressField) {
    return Boolean(value)
  }

  return true
}

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Minimum 2 characters').required('First name is required.'),
  lastName: Yup.string().min(2, 'Minimum 2 characters').required('Last name is required.'),
  email: Yup.string().email().required('Email is required.'),
  type: Yup.string().required('Type is required.'),
  mobile: Yup.string(),
  addressLine1: Yup.string().test(
    'required-if-has-address',
    'Address Line 1 is Required',
    requiredIfHasAddress
  ),

  addressLine2: Yup.string().test(
    'required-if-has-address',
    'Fill out line 1 first!',
    (value, context) => {
      const values = context.parent as CustomerFormValues
      if (value && !values.addressLine1) {
        return false
      }
      return true
    }
  ),
  addressCity: Yup.string().test(
    'required-if-has-address',
    'City is required',
    requiredIfHasAddress
  ),
  addressCountryCode: Yup.string().test(
    'required-if-has-address',
    'Country code is required',
    requiredIfHasAddress
  ),
})

export interface CustomerFormFieldsProps<T extends CustomerFormValues> {
  formik: FormikContextType<T>
  countries: ISOCountryModel[]
  onSearchCategories?: (filter: FilterModel) => void
  categorySearchResults?: GlobalSearchModel<CustomerCategoryModel>
  productSearchResults?: GlobalSearchModel<ProductModel>
  onSearchProducts?: (filter: FilterModel) => void
  disabledFields?: Partial<Record<keyof CustomerFormValues, boolean>>
  hiddenFields?: Partial<Record<keyof CustomerFormValues, boolean>>
  lang?: string
  locations?: LocationModel[]
  isEditing?: boolean
}

export const CustomerFormFields = <T extends CustomerFormValues>({
  formik,
  countries,
  onSearchCategories,
  categorySearchResults,
  onSearchProducts,
  productSearchResults,
  hiddenFields,
  disabledFields,
  lang,
  locations,
  isEditing,
}: CustomerFormFieldsProps<T>) => {
  const {getTranslationTextFieldProps} = useTranslationFieldHelpers(formik, lang)

  const handleDobChange = useCallback(
    (value: Date | null) => {
      formik.setFieldValue('dob', value)
    },
    [formik]
  )

  const countryList = useMemo(() => {
    return countries.map<SelectInputItem>((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, [countries])

  const handleAvatarChange = useCallback(
    (value: ImageInputValue | null) => {
      formik.setFieldValue('avatar', value)
    },
    [formik]
  )

  const handleCategoryChange = useCallback(
    (value: CustomerCategoryModel | null) => {
      formik.setFieldValue('category', value)
    },
    [formik]
  )

  const handleProductChange = useCallback(
    (value: ProductModel | null) => {
      formik.setFieldValue('product', value)
    },
    [formik]
  )

  const textInputHelpers = useFormikTextInputHelpers(formik)

  const handleDimensionsChange = useCallback(
    (height: number, width: number) => {
      formik.setFieldValue('imageHeight', height)
      formik.setFieldValue('imageWidth', width)
    },
    [formik]
  )

  const locationItems = useMemo((): TreeNodeItem[] => {
    if (locations) {
      const locationTree = new ApiTree(locations)
      return locationTree.getTreeSelectItems()
    }
    return []
  }, [locations])

  const handleLocationChange = useCallback(
    (codes: string[]) => {
      formik.setValues((values) => ({
        ...values,
        product: values.product,
        locationCodes: codes,
        guest: null,
      }))
    },
    [formik]
  )

  const locationInput = (
    <>
      <TreeSelect
        label='Location'
        items={locationItems}
        onChange={handleLocationChange}
        values={formik.values?.locationCodes || []}
      />
    </>
  )
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Salutation'
              placeholder='Enter Salutation'
              {...getTranslationTextFieldProps('salutation')}
              disabled={disabledFields?.salutation}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='First Name'
              placeholder='Enter First Name'
              {...getTranslationTextFieldProps('firstName')}
              disabled={disabledFields?.firstName}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Last Name'
              placeholder='Enter Last Name'
              {...getTranslationTextFieldProps('lastName')}
              disabled={disabledFields?.lastName}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Email'
              placeholder='Enter Email'
              {...textInputHelpers.getProps('email')}
              disabled={disabledFields?.email}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Designation'
              placeholder='Enter Designation'
              {...getTranslationTextFieldProps('designation')}
              disabled={disabledFields?.designation}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Organization'
              placeholder='Enter Organization'
              {...getTranslationTextFieldProps('organization')}
              disabled={disabledFields?.organization}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Mobile Number'
              placeholder='Enter Mobile Number'
              {...textInputHelpers.getProps('mobile')}
              disabled={disabledFields?.mobile}
            />
          </div>
          {onSearchCategories && (
            <div className='col-12 col-md-6'>
              <FilterSearchableSelectInput
                allowClear
                itemMapper={selectItemMapper}
                label='Group'
                placeholder='Search Group'
                onChange={handleCategoryChange}
                onFilter={onSearchCategories}
                value={formik.values.category}
                searchResult={categorySearchResults}
                disabled={disabledFields?.category}
              />
            </div>
          )}
          {onSearchProducts && (
            <div className='col-12 col-md-6'>
              <FilterSearchableSelectInput
                disabled={disabledFields?.product}
                allowClear
                itemMapper={selectItemMapper}
                label='Category'
                placeholder='Search Category'
                onChange={handleProductChange}
                onFilter={onSearchProducts}
                value={formik.values.product}
                searchResult={productSearchResults}
              />
            </div>
          )}
          {isEditing && <div className='col-12 col-md-6'>{locationInput}</div>}
          {!hiddenFields?.type && (
            <div className='col-12 col-md-6'>
              <SelectInput
                placeholder='Select Type'
                label='Type'
                items={CUSTOMER_TYPE}
                {...formik.getFieldProps('type')}
                disabled
              />
            </div>
          )}
          <div className='col-12 col-md-6'>
            <SelectInput
              placeholder='Select Gender'
              label='Gender'
              items={GENDER_SELECT_ITEMS}
              {...formik.getFieldProps('gender')}
              disabled={disabledFields?.gender}
            />
          </div>
          <div className='col-12 col-md-6'>
            <SelectInput
              placeholder='Select Nationality'
              label='Nationality'
              items={countryList}
              {...formik.getFieldProps('nationality')}
              disabled={disabledFields?.nationality}
            />
          </div>
          <div className='col-12 col-md-6'>
            <SelectInput
              placeholder='Select Residence'
              label='Residence'
              items={countryList}
              {...formik.getFieldProps('residence')}
              disabled={disabledFields?.residence}
            />
          </div>
          <div className='col-12 col-md-6'>
            <DatePickerInput
              label='Date of Birth'
              onChange={handleDobChange}
              value={formik.values.dob}
              disabled={disabledFields?.dob}
            />
          </div>
          <div className='col-12 col-md-6'>
            <AvatarImageInput
              onDimensionChange={handleDimensionsChange}
              height={formik.values.imageHeight}
              width={formik.values.imageWidth}
              value={formik.values.avatar}
              onChange={handleAvatarChange}
              label='Photo'
              disabled={disabledFields?.avatar}
            />
          </div>

          <div className='col-12'>
            <div className='my-5'>
              <HorizontalDivider className='my-5' />
            </div>

            <h2 className='mb-5'>Address</h2>
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Name'
              placeholder='Enter Name'
              {...textInputHelpers.getProps('addressName')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Line 1'
              placeholder='Enter Address Line 1'
              {...textInputHelpers.getProps('addressLine1')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Line 2'
              placeholder='Enter Address Line 2'
              {...textInputHelpers.getProps('addressLine2')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='City'
              placeholder='Enter City'
              {...textInputHelpers.getProps('addressCity')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='State or Province'
              placeholder='Enter State or Province'
              {...textInputHelpers.getProps('addressStateOrProvince')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <SelectInput
              label='Country'
              placeholder='Select Country'
              items={countryList}
              {...formik.getFieldProps('addressCountryCode')}
            />
          </div>
          <div className='col-12 col-md-6'>
            <TextInput
              label='Post Code'
              placeholder='Post Code'
              {...textInputHelpers.getProps('addressPostCode')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const GENDER_SELECT_ITEMS: SelectInputItem[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
]

const CUSTOMER_TYPE: SelectInputItem[] = [
  {
    label: 'Partner',
    value: 'partner',
  },
  {
    label: 'Reseller',
    value: 'reseller',
  },
]

export const EMPTY_INITIAL_VALUES: CustomerFormValues = {
  firstName: '',
  lastName: '',
  salutation: '',
  avatar: null,
  email: '',
  type: 'reseller',
  mobile: '',
  gender: '',
  nationality: '',
  residence: '',
  dob: null,
  product: null,
  addressName: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressStateOrProvince: '',
  addressPostCode: '',
  addressCountryCode: '',
  bio: null,
  category: null,
  designation: '',
  organization: '',
  translations: {},
  customFields: [],
  locationCodes: [],
}
