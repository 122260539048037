import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {
  ForgotPasswordForm,
  ForgotPasswordFormValues,
} from '../../../../components/forms/ForgotPasswordForm'
import {RequestForgottenPassword} from '../../redux/CustomerPortalCRUD'

export function ForgotPassword() {
  const history = useHistory()

  const handleSubmit = useCallback(async (values: ForgotPasswordFormValues) => {
    await RequestForgottenPassword(values.email)
  }, [])

  const logout = useCallback(() => {
    history.push('/auth/login')
  }, [history])

  return <ForgotPasswordForm onSubmit={handleSubmit} onCancel={logout} />
}
