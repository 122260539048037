import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {isTicketCancel, isTicketDownloadable, isTicketIsReassignable} from '../../../hooks/useTicketTableActions'

export interface TicketTableRowActionsProps {
  onCancel?: (data: TicketPortalModel) => void
  onReassignSeat?: (data: TicketPortalModel) => void
  onDownload?: (data: TicketPortalModel) => void
  data: TicketPortalModel
  loading?: boolean
}

export const TicketTableRowActions = ({
  onCancel,
  onReassignSeat,
  data,
  loading,
  onDownload,
}: TicketTableRowActionsProps) => {
  return (
    <>
      {isTicketIsReassignable(data) && (
        <RowAction
          loading={loading}
          variant='info'
          onClick={onReassignSeat}
          data={data}
          iconType='Home'
          iconName='Armchair'
          activeColor='primary'
          tooltip='Reassign'
        />
      )}

      {isTicketCancel(data) && (
        <RowAction
          loading={loading}
          data={data}
          iconType='Code'
          iconName='Stop'
          variant='danger'
          tooltip='Cancel Ticket'
          onClick={onCancel}
        />
      )}
      {isTicketDownloadable(data) && (
        <RowAction
          loading={loading}
          data={data}
          iconType='Files'
          iconName='Download'
          variant='primary'
          tooltip='Download'
          onClick={onDownload}
        />
     )}
    </>
  )
}
