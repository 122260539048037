import {ReactNode, useCallback, useMemo, useState} from 'react'
import clsx from 'clsx'
import {SocialDistancingFormValues} from '../../forms/SocialDistancingForm'
import {SocialDistancingModalForm} from '../../forms/SocialDistancingModalForm'
import {Button} from '../Button'
import {CheckboxInput} from '../CheckboxInput/CheckboxInput'
import {MetronicIconButton} from '../MetronicIconButton'
import {OtherDropdownMenuButton} from '../OtherDropdownMenuButton/OtherDropdownMenuButton'
import {SeatMapValue} from './SeatMapValue'
import {SeatMapSpacing} from './SeatMapSpacing'
import {SVGSeatMapInput} from './SVGSeatMapInput/SVGSeatMapInput'
import {DaysSeatManifestProps} from '../../hooks/useSeatMapState'

export interface SeatMapEditorInputProps {
  rows: number
  columns: number
  actions?: ReactNode
  className?: string
  hidden?: SeatMapValue
  disabled?: SeatMapValue
  selected?: SeatMapValue
  occupied?: SeatMapValue
  extra?: SeatMapValue
  shared?: SeatMapValue
  sharedDisabled?: SeatMapValue
  onSelectedChange?: (value: SeatMapValue) => void
  onDisabledChange?: (value: SeatMapValue) => void
  onHiddenChange?: (value: SeatMapValue) => void
  showHidden?: boolean
  onShowHiddenChange?: (isShown: boolean) => void
  onSpacingXChange?: (spacing: SeatMapSpacing) => void
  spacingX?: SeatMapSpacing
  enableSocialDistancingOption?: boolean
  maxSelected?: number
  isRightToLeft?: boolean
  isBottomToTop?: boolean
  onIsRightToLeftChange?: (isRightToLeft: boolean) => void
  onIsBottomToTopChange?: (isBottomToTop: boolean) => void
  disableRowClick?: boolean
  seatsDays?: DaysSeatManifestProps | null
  isCircledDaysBox?: boolean
  isShowNoneFilledDays?: boolean
  daysBoxWidth?: number
  daysBoxHeight?: number
}

export const SeatMapEditorInput = ({
  className,
  showHidden,
  selected,
  columns,
  disabled,
  hidden,
  rows,
  onDisabledChange,
  onHiddenChange,
  onSelectedChange,
  onShowHiddenChange,
  enableSocialDistancingOption,
  onSpacingXChange,
  spacingX,
  maxSelected,
  occupied,
  extra,
  shared,
  sharedDisabled,
  actions,
  isBottomToTop,
  isRightToLeft,
  onIsBottomToTopChange,
  onIsRightToLeftChange,
  disableRowClick,
  seatsDays,
  isCircledDaysBox,
  isShowNoneFilledDays,
}: SeatMapEditorInputProps) => {
  const [socialDistancingFormShown, setSocialDistancingFormShown] = useState(false)

  const clearSelection = useCallback(() => {
    onSelectedChange && onSelectedChange(new SeatMapValue())
  }, [onSelectedChange])

  const selectAll = useCallback(() => {
    if (onSelectedChange) {
      const allSeats = new SeatMapValue(rows, columns)
      if (showHidden) {
        onSelectedChange(allSeats)
      } else {
        const visibleSeats = hidden ? allSeats.difference(hidden) : allSeats
        onSelectedChange(visibleSeats)
      }
    }
  }, [columns, hidden, onSelectedChange, rows, showHidden])

  const setSelectedSeats = useCallback(
    (value: SeatMapValue) => {
      onSelectedChange && onSelectedChange(value)
    },
    [onSelectedChange]
  )

  const showSelectedSeats = useCallback(() => {
    if (onHiddenChange && hidden && selected) {
      onHiddenChange(hidden.difference(selected))
    }
    clearSelection()
  }, [clearSelection, hidden, onHiddenChange, selected])

  const hideSelectedSeats = useCallback(() => {
    if (onHiddenChange && selected && hidden) {
      onHiddenChange(selected.union(hidden))
    }
    clearSelection()
  }, [clearSelection, hidden, onHiddenChange, selected])

  const enableSelectedSeats = useCallback(() => {
    if (onDisabledChange && disabled && selected) {
      onDisabledChange(disabled.difference(selected))
    }
    clearSelection()
  }, [clearSelection, disabled, onDisabledChange, selected])

  const disableSelectedSeats = useCallback(() => {
    if (onDisabledChange && selected && disabled) {
      let ddd = selected.union(disabled)
      if (sharedDisabled) ddd.union(sharedDisabled)
      onDisabledChange(selected.union(ddd))
    }
    clearSelection()
  }, [clearSelection, disabled, onDisabledChange, selected, sharedDisabled])

  const handleShowHiddenChange = useCallback(() => {
    onShowHiddenChange && onShowHiddenChange(!showHidden)
  }, [onShowHiddenChange, showHidden])

  const handleOnIsRightToLeftChange = useCallback(() => {
    onIsRightToLeftChange && onIsRightToLeftChange(!isRightToLeft)
  }, [isRightToLeft, onIsRightToLeftChange])

  const handleOnIsBottomToTopChange = useCallback(() => {
    onIsBottomToTopChange && onIsBottomToTopChange(!isBottomToTop)
  }, [isBottomToTop, onIsBottomToTopChange])

  const handleMoveFromLeft = useCallback(() => {
    if (spacingX && onSpacingXChange && selected) {
      const newValue = spacingX.clone()
      newValue.incrementSpacingFromSeatMapValue(selected)
      onSpacingXChange(newValue)
    }
  }, [onSpacingXChange, selected, spacingX])

  const handleMoveFromRight = useCallback(() => {
    if (spacingX && onSpacingXChange && selected) {
      const newValue = spacingX.clone()
      newValue.decrementSpacingFromSeatMapValue(selected)
      onSpacingXChange(newValue)
    }
  }, [onSpacingXChange, selected, spacingX])

  const showSocialDistancingForm = useCallback(() => {
    setSocialDistancingFormShown(true)
  }, [])

  const hideSocialDistancingForm = useCallback(() => {
    setSocialDistancingFormShown(false)
  }, [])

  const applySocialDistancing = useCallback(
    ({spacing, per}: SocialDistancingFormValues) => {
      if (onDisabledChange && disabled) {
        const disabledClone = disabled.clone()
        disabledClone.applySocialDistancing(rows, columns, spacing, per)
        onDisabledChange(disabledClone)
      }
      hideSocialDistancingForm()
    },
    [columns, disabled, hideSocialDistancingForm, onDisabledChange, rows]
  )

  const showButton = useMemo(() => {
    if (onHiddenChange && selected && hidden) {
      const hasHiddenItemsSelected = selected.intersect(hidden).hasAnyValues()
      if (hasHiddenItemsSelected) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='Navigation'
            iconName='Check'
            type='button'
            onClick={showSelectedSeats}
            variant='danger'
            tooltip='Show Selected'
          />
        )
      }
    }
    return null
  }, [hidden, onHiddenChange, selected, showSelectedSeats])

  const hideButton = useMemo(() => {
    if (onHiddenChange && selected && hidden) {
      const hasShownItemsSelected = selected.difference(hidden).hasAnyValues()
      if (hasShownItemsSelected) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='Navigation'
            iconName='Close'
            type='button'
            onClick={hideSelectedSeats}
            variant='danger'
            tooltip='Remove Selected'
          />
        )
      }
    }
    return null
  }, [selected, hidden, hideSelectedSeats, onHiddenChange])

  const enableButton = useMemo(() => {
    if (onDisabledChange && selected && disabled) {
      const hasDisabledItemsSelected = selected.intersect(disabled).hasAnyValues()
      if (hasDisabledItemsSelected) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='General'
            iconName='Visible'
            type='button'
            onClick={enableSelectedSeats}
            variant='warning'
            tooltip='Enable Selected'
          />
        )
      }
    }
    return null
  }, [disabled, enableSelectedSeats, selected, onDisabledChange])

  const disableButton = useMemo(() => {
    if (onDisabledChange && selected && disabled) {
      const hasEnabledItemsSelected = selected.difference(disabled).hasAnyValues()
      if (hasEnabledItemsSelected) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='General'
            iconName='Hidden'
            type='button'
            onClick={disableSelectedSeats}
            variant='warning'
            tooltip='Disable Selected'
          />
        )
      }
    }
    return null
  }, [disableSelectedSeats, disabled, selected, onDisabledChange])

  const clearSelectionButton = useMemo(() => {
    if (onSelectedChange && selected) {
      if (selected.hasAnyValues()) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='Code'
            iconName='Stop'
            type='button'
            onClick={clearSelection}
            variant='info'
            tooltip='Clear Selection'
          />
        )
      }
    }
    return null
  }, [clearSelection, selected, onSelectedChange])

  const showHiddenCheckbox = useMemo(() => {
    if (onShowHiddenChange) {
      return (
        <div>
          <CheckboxInput
            noMargin
            checked={showHidden}
            label='Show Removed'
            onChange={handleShowHiddenChange}
          />
        </div>
      )
    }
    return null
  }, [handleShowHiddenChange, onShowHiddenChange, showHidden])

  const isRightToLeftCheckbox = useMemo(() => {
    if (onIsRightToLeftChange) {
      return (
        <div>
          <CheckboxInput
            noMargin
            checked={isRightToLeft}
            label='Is Right To Left?'
            onChange={handleOnIsRightToLeftChange}
          />
        </div>
      )
    }
    return null
  }, [onIsRightToLeftChange, isRightToLeft, handleOnIsRightToLeftChange])

  const isBottomToTopCheckbox = useMemo(() => {
    if (onIsBottomToTopChange) {
      return (
        <div>
          <CheckboxInput
            noMargin
            checked={isBottomToTop}
            label='Is Bottom To Top?'
            onChange={handleOnIsBottomToTopChange}
          />
        </div>
      )
    }
    return null
  }, [onIsBottomToTopChange, isBottomToTop, handleOnIsBottomToTopChange])

  const selectAllButtons = useMemo(() => {
    if (!maxSelected && onSelectedChange && hidden) {
      const totalSeats = rows * columns
      const hiddenTotal = hidden.getValueCount()
      let shouldShow = totalSeats > 0
      if (!showHidden) {
        shouldShow = totalSeats - hiddenTotal > 0
      }
      if (shouldShow) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='Design'
            iconName='Select'
            type='button'
            onClick={selectAll}
            variant='info'
            tooltip='Select All'
          />
        )
      }
    }
    return null
  }, [maxSelected, onSelectedChange, hidden, rows, columns, showHidden, selectAll])

  const moveLeftButton = useMemo(() => {
    if (onSpacingXChange && selected?.hasAnyValues()) {
      return (
        <MetronicIconButton
          size='sm'
          iconType='Navigation'
          iconName='Arrow-from-left'
          type='button'
          onClick={handleMoveFromLeft}
          variant='dark'
          tooltip='Shift right'
        />
      )
    }
    return null
  }, [onSpacingXChange, selected, handleMoveFromLeft])

  const moveRightButton = useMemo(() => {
    if (onSpacingXChange && selected?.hasAnyValues()) {
      return (
        <MetronicIconButton
          size='sm'
          iconType='Navigation'
          iconName='Arrow-from-right'
          type='button'
          onClick={handleMoveFromRight}
          variant='dark'
          tooltip='Shift left'
        />
      )
    }
    return null
  }, [onSpacingXChange, selected, handleMoveFromRight])

  const otherMenu = useMemo(() => {
    if (enableSocialDistancingOption) {
      return (
        <OtherDropdownMenuButton className='seat-map-editor-other-btn'>
          <Button
            onClick={showSocialDistancingForm}
            className='text-nowrap'
            uppercase={false}
            size='flush'
            variant='text'
          >
            Apply social distancing
          </Button>
        </OtherDropdownMenuButton>
      )
    }
    return null
  }, [enableSocialDistancingOption, showSocialDistancingForm])

  const handleRowClick = useCallback(
    (rowLetter: string) => {
      if (!disableRowClick && selected && onSelectedChange) {
        let newValue = selected.clone()
        let allSelected = true
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          if (!newValue.isValueExist(rowLetter, columnIndex + 1)) {
            allSelected = false
          }
        }
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          if (allSelected) {
            newValue.removeValue(rowLetter, columnIndex + 1)
          } else {
            newValue.addValue(rowLetter, columnIndex + 1)
          }
        }
        if (hidden) {
          newValue = newValue.intersect(hidden.union(newValue))
        }
        onSelectedChange(newValue)
      }
    },
    [columns, disableRowClick, hidden, onSelectedChange, selected]
  )

  return (
    <div className={clsx('seat-map-editor', className)}>
      <div className='seat-map-editor-controls'>
        {actions}
        {isRightToLeftCheckbox}
        {isBottomToTopCheckbox}
        {showHiddenCheckbox}
        {selectAllButtons}
        {clearSelectionButton}
        {disableButton}
        {enableButton}
        {hideButton}
        {showButton}
        {moveLeftButton}
        {moveRightButton}
      </div>
      {otherMenu}
      <SocialDistancingModalForm
        open={socialDistancingFormShown}
        onHide={hideSocialDistancingForm}
        onSubmit={applySocialDistancing}
      />
      <SVGSeatMapInput
        onRowClick={handleRowClick}
        isBottomToTop={isBottomToTop}
        isRightToLeft={isRightToLeft}
        renderHidden={showHidden}
        disabled={disabled}
        hidden={hidden}
        value={selected}
        columns={columns}
        rows={rows}
        onChange={setSelectedSeats}
        occupied={occupied}
        spacingX={spacingX}
        extra={extra}
        shared={shared}
        sharedDisabled={sharedDisabled}
        seatsDays={seatsDays}
        isCircledDaysBox={isCircledDaysBox}
        isShowNoneFilledDays={isShowNoneFilledDays}
      />
    </div>
  )
}
