import {useState, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {PostCustomer, AddExistedCustomer} from '../../../redux/CustomerPortalCRUD'
import {useFormik} from 'formik'
import * as RxCustPort from '../../../redux/CustomerPortalRedux'
import {CustomerForm, CustomerFormValues, roleFormValidationSchema} from './CustomerForm'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {
  useFormDrawerCustomerData,
  getCustomerPayload,
  initialValues,
} from '../../../hooks/useFormDrawerCustomerData'
import {CustomerModel} from '../../../../../models/CustomerModel'
export interface CustomerFormCreateProps {
  drawerRef: (drawer: Drawer | null) => void
  onChange: () => void
}

const CustomerFormCreate = ({drawerRef, onChange}: CustomerFormCreateProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()
  const formDrawer = useFormDrawerCustomerData()
  const [isExistedCustomer, setIsExistedCustomer] = useState<CustomerModel>()
  const createNewCustomer = useCallback(
    async (values: CustomerFormValues) => {
      try {
        if (isExistedCustomer) {
          await AddExistedCustomer({
            customerCode: isExistedCustomer.code,
          })
        } else {
          const payload = getCustomerPayload({values})
          await PostCustomer(payload)
        }
        dispatch(RxCustPort.actions.customers.createSuccess())
        onChange()
      } catch (e) {
        dispatch(RxCustPort.actions.customers.createFailed())
        throw e
      }
    },
    [dispatch, isExistedCustomer, onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await createNewCustomer(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
  }, [formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )
  return (
    <Drawer ref={handleDrawerRef} onClose={handleCloseDrawer}>
      <CustomerForm
        title='Create Customer'
        countries={formDrawer.countries}
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
        setIsExistedCustomer={setIsExistedCustomer}
        isExistedCustomer={isExistedCustomer}
      />
    </Drawer>
  )
}

export default CustomerFormCreate
