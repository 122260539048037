import {useCallback, useMemo, useState} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {FilterModel} from '../../../../models/FilterModel'
import {actions} from '../../redux/CustomerPortalRedux'
import {GetBookingByCode, GetBookingDetail, GetTicketByCode} from '../../redux/CustomerPortalCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useDispatch} from 'react-redux'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {TicketTable} from '../../components/tables/TicketTable/TicketTable'
import {TicketCardGrid} from '../../components/TicketCard/TicketCardGrid'
import {CustomerDetailHeader} from '../../components/CustomerDetailsHeader'
import PortalHeader, {LinkType} from '../../components/Headers/PortalHeader'
import {BookingDetailModel} from '../../../../models/customer-portal/BookingDetailModel'
import {
  EventPortalDetailInnerRoutes,
  isSeatShow,
  isTicketShow,
} from './EventPortalDetailInnerRoutes'
import {TicketModel} from '../../../../models/ems/TicketModel'

export interface EventPortalDetailTicketsProps {
  event: EventModel
}

interface RouteMatch {
  bookingCode?: string
  customerCode?: string
  ticketCode?: string
  bookingProductCode?: string
  path: string
}

export interface LinksTypes {
  [key: string]: LinkType[]
}
const EventPortalDetailTickets = ({event}: EventPortalDetailTicketsProps) => {
  const match = useRouteMatch<RouteMatch>()
  const [currentTicket, setCurrentTicket] = useState<TicketModel>()
  const [currentBookingsDetails, setCurrentBookingDetail] =
    useState<GlobalSearchModel<BookingDetailModel>>()
  const {bookingCode, customerCode, ticketCode, bookingProductCode} = match.params
  const history = useHistory()
  const breakpoints = useBreakpoint()
  const isMobile = useMemo(() => breakpoints.down('md'), [breakpoints])
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const [currentBooking, setCurrentBooking] = useState<BookingModel>()
  const searchDebounce = useDebounce(400)
  const {setFilter} = useEntityFilter('customer-portal-ticket')
  const {setFilter: setBookingProductFilter} = useEntityFilter('customer-portal-booking-product')
  const dispatch = useDispatch()

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        type: ['e-ticket'],
        eventCode: event?.code,
      },
    }
    return filters
  }, [event])

  const links: LinksTypes = useMemo(() => {
    let tickets = [
      {
        title: 'Booking details',
        to: `/event/${event?.code}/tickets/customer/${customerCode}/booking/${bookingCode}/booking-detail`,
      },
    ]

    if (isTicketShow(match)) {
      tickets.push({
        title: 'Tickets',
        to: `/event/${event?.code}/tickets/customer/${customerCode}/booking/${bookingCode}/booking-product/${bookingProductCode}/ticket`,
      })
    }
    if (isSeatShow(match)) {
      tickets.push({
        title: 'Ticket Seats',
        to: `/event/${event?.code}/tickets/customer/${customerCode}/booking/${bookingCode}/booking-product/${bookingProductCode}/ticket/${ticketCode}/seat`,
      })
    }
    return {
      tickets,
    }
  }, [bookingCode, bookingProductCode, customerCode, event?.code, match, ticketCode])

  useOnChange(ticketCode, async () => {
    if (ticketCode) {
      const {data} = await GetTicketByCode(ticketCode)
      if (data) {
        setCurrentTicket(data)
      }
    }
  })

  const getBookingDetail = useCallback(async () => {
    const {data} = await GetBookingDetail({
      filters: {
        search: '',
        booking: bookingCode,
      },
    })
    if (data) {
      setCurrentBookingDetail(data)
    }
  }, [bookingCode])

  useOnChange(bookingCode, () => {
    if (bookingCode) {
      getBookingDetail()
      refreshCurrentTicketDetails()
    }
  })

  const refreshCurrentTicketDetails = useCallback(async () => {
    if (bookingCode) {
      const {data} = await GetBookingByCode(bookingCode)
      setCurrentBooking(data)
    }
  }, [bookingCode])

  const refreshTicketsTable = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.tickets.search())
    })
  }, [dispatch, searchDebounce])

  const onFilterProductHandler = useCallback(
    (filter: FilterModel) => {
      setBookingProductFilter({
        ...filter,
        filters: {...filter.filters, booking: bookingCode},
      })
      dispatch(actions.bookingProducts.search())
    },
    [setBookingProductFilter, bookingCode, dispatch]
  )

  const handleFilterTicketsTable = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshTicketsTable()
    },
    [setFilter, refreshTicketsTable]
  )

  const handleSplitPaneClose = useCallback(() => {
    if (event) history.push(`/event/${event.code}/tickets`)
  }, [event, history])

  const ticketInnerPages = useMemo(() => {
    return currentBookingsDetails ? (
      <>
        <CustomerDetailHeader
          customer={currentBooking?.customer}
          booking={currentBooking}
          onRefresh={refreshTicketsTable}
        />
        <PortalHeader links={links.tickets} />
        <EventPortalDetailInnerRoutes
          toPath='tickets'
          match={match}
          event={event}
          currentTicket={currentTicket}
          bookingCode={bookingCode}
          bookingDetails={currentBookingsDetails}
          bookingProductCode={bookingProductCode}
          locationCode={currentTicket?.location?.code}
          productCode={currentTicket?.product?.code}
          onFilterProductHandler={onFilterProductHandler}
          refreshCurrentDetails={refreshCurrentTicketDetails}
          refreshTable={refreshTicketsTable}
          customerCode={customerCode}
        />
      </>
    ) : null
  }, [
    bookingCode,
    bookingProductCode,
    currentBooking,
    currentBookingsDetails,
    currentTicket,
    customerCode,
    event,
    links.tickets,
    match,
    onFilterProductHandler,
    refreshCurrentTicketDetails,
    refreshTicketsTable,
  ])

  const ticketTable = useMemo(() => {
    return (
      <>
        <div className='d-none d-md-block'>
          <TicketTable
            initialFilters={initialFilters}
            onRefresh={refreshTicketsTable}
            onRefreshCallback={refreshCurrentTicketDetails}
            data={tickets}
            onFilter={handleFilterTicketsTable}
            event={event}
          />
        </div>
        <TicketCardGrid
          initialFilters={initialFilters}
          className='d-md-none'
          onRefresh={refreshTicketsTable}
          onRefreshCallback={refreshCurrentTicketDetails}
          data={tickets}
          onFilter={handleFilterTicketsTable}
        />
      </>
    )
  }, [
    event,
    handleFilterTicketsTable,
    initialFilters,
    refreshCurrentTicketDetails,
    refreshTicketsTable,
    tickets,
  ])

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <PaneContainer className='flex-grow-1' direction='horizontal'>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '0%' : '35%'}>
              {ticketTable}
            </Pane>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '100%' : '65%'}>
              <MetronicIconButton
                size='sm'
                iconType='Navigation'
                iconName='Arrow-from-left'
                onClick={handleSplitPaneClose}
                variant='primary'
                tooltip='Close Pane'
                className='mb-1'
              />
              {ticketInnerPages}
            </Pane>
          </PaneContainer>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export default EventPortalDetailTickets
