import {useCallback, useMemo} from 'react'
import {MultiSelectInput} from '../../inputs/MultiSelectInput/MultiSelectInput'
import {SelectInputItem} from '../../inputs/SelectInput'
import {useTableFilterState} from '../useTableFilterState'

export interface MultiSelectToggleFilterInputProps {
  label: string
  items: SelectInputItem[]
  field: string
}

export const MultiSelectToggleFilterInput = ({
  items,
  label,
  field,
}: MultiSelectToggleFilterInputProps) => {
  const {setAdvancedFilterValue, getAdvancedFilterValue, clearAdvancedFilterValue} =
    useTableFilterState()

  const value = useMemo(() => {
    const value = getAdvancedFilterValue(field)
    if (Array.isArray(value)) {
      return value
    }
    return []
  }, [field, getAdvancedFilterValue])

  const handleChange = useCallback(
    (values: string[]) => {
      if (values.length) {
        setAdvancedFilterValue(field, values)
      } else {
        clearAdvancedFilterValue(field)
      }
    },
    [clearAdvancedFilterValue, field, setAdvancedFilterValue]
  )

  return (
    <MultiSelectInput
      hasSelectAll
      allowClear
      placeholder={`Filter ${label}`}
      items={items}
      label={label}
      value={value}
      onChange={handleChange}
    />
  )
}
