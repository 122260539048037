import clsx from 'clsx'
import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {useLayoutHelpers} from '../useLayoutHelpers'

export interface NavDrawerMenuProps {
  item: NavigationSectionMenuItem
  isChild?: boolean
}

export const NavDrawerMenuItem = ({item, isChild}: NavDrawerMenuProps) => {
  const {childrenIsActive, itemHasSubMenu} = useLayoutHelpers()
  const hasSubMenus = useMemo(() => itemHasSubMenu(item), [item, itemHasSubMenu])

  const content = useMemo(() => {
    const isActive = childrenIsActive([item])
    const content = (
      <>
        {!hasSubMenus && isChild && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        <span
          className={clsx(
            'menu-title',
            {
              'text-primary': isActive,
            },
            !isActive && item.status && `text-${item.status}`
          )}
        >
          {item.title}
        </span>
        {hasSubMenus && <span className='menu-arrow'></span>}
      </>
    )
    if (item.to) {
      return (
        <Link to={item.to} className='menu-link py-3'>
          {content}
        </Link>
      )
    }
    return <div className='menu-link py-3'>{content}</div>
  }, [childrenIsActive, hasSubMenus, isChild, item])

  return (
    <div
      className={clsx('menu-item', {
        'menu-accordion': hasSubMenus,
      })}
      data-kt-menu-trigger='click'
    >
      {content}
      {hasSubMenus && (
        <div className='menu-sub menu-sub-accordion pt-3'>
          {item.subMenus?.map((item, i) => {
            return <NavDrawerMenuItem key={i} item={item} isChild />
          })}
        </div>
      )}
    </div>
  )
}
