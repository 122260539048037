import clsx from 'clsx'
import {ReactNode, useMemo, useRef} from 'react'
import {DateRange} from '../../../utils/DateRange'
import {useBreakpoint} from '../../hooks/useBreakpoint'
import {CalendarDateItem, CalendarDateItemProps} from './CalendarDateItem'
import {addDays, getStartAndEndDateOfMonthOfDate, subtractDays} from './utils'

export interface CalendarProps {
  children?: ReactNode
  date?: Date
  dateClasses?: CalendarDateItemProps['classes']
  className?: string
}

export const Calendar = ({children, date, dateClasses, className}: CalendarProps) => {
  const currentDay = useRef(new Date())
  const focusedDate = useMemo(() => date || currentDay.current, [date])
  const breakpoint = useBreakpoint()
  const isMobile = useMemo(() => breakpoint.down('sm'), [breakpoint])
  const days = useMemo(() => (isMobile ? DAYS_SHORT : DAYS), [isMobile])

  const monthDateRange = useMemo(() => {
    return getStartAndEndDateOfMonthOfDate(focusedDate)
  }, [focusedDate])

  const paddingDaysStart = useMemo(() => {
    const nodes: ReactNode[] = []
    const startingDate = monthDateRange.getStartOrFail()

    if (startingDate.getDay() !== STARTING_DAY) {
      new DateRange(
        subtractDays(startingDate, startingDate.getDay()),
        subtractDays(startingDate, 1)
      ).forEachDayBetweenInclusive((date) => {
        nodes.push(
          <CalendarDateItem inactive key={date.valueOf()} date={date} classes={dateClasses}>
            {children}
          </CalendarDateItem>
        )
      })
    }

    return nodes
  }, [children, dateClasses, monthDateRange])

  const paddingDaysEnd = useMemo(() => {
    const nodes: ReactNode[] = []
    const endingDate = monthDateRange.getEndOrFail()

    if (endingDate.getDay() !== days.length - 1 - STARTING_DAY) {
      new DateRange(
        addDays(endingDate, 1),
        addDays(endingDate, days.length - 1 - endingDate.getDay())
      ).forEachDayBetweenInclusive((date) => {
        nodes.push(
          <CalendarDateItem inactive key={date.valueOf()} date={date} classes={dateClasses}>
            {children}
          </CalendarDateItem>
        )
      })
    }

    return nodes
  }, [children, dateClasses, days.length, monthDateRange])

  const monthDays = useMemo((): ReactNode[] => {
    const nodes: ReactNode[] = []

    monthDateRange.forEachDayBetweenInclusive((date) => {
      nodes.push(
        <CalendarDateItem key={date.valueOf()} date={date} classes={dateClasses}>
          {children}
        </CalendarDateItem>
      )
    })

    return nodes
  }, [children, dateClasses, monthDateRange])

  const dayHeaders = useMemo(() => {
    const nodes: ReactNode[] = []

    for (let i = STARTING_DAY; i < days.length; i++) {
      nodes.push(
        <div key={i} className={clsx({'text-danger': i === 0})}>
          {days[i]}
        </div>
      )
    }

    for (let i = 0; i < STARTING_DAY; i++) {
      nodes.push(
        <div key={i} className={clsx({'text-danger': i === 0})}>
          {days[i]}
        </div>
      )
    }

    return nodes
  }, [days])

  return (
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
      }}
    >
      {dayHeaders}
      {paddingDaysStart}
      {monthDays}
      {paddingDaysEnd}
    </div>
  )
}

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const STARTING_DAY = 0
