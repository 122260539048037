import {useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {actions} from '../../../redux/CustomerPortalRedux'
import {PostBulkBooking} from '../../../redux/CustomerPortalCRUD'
import {BookingWizard} from './BookingWizard'

import {
  getPayload,
  EMPTY_FORM_VALUES,
  bookingWizardValidationSchema,
} from '../../../../../components/BookingWizard/hook/BookingWizardHelper'

import {useBookingWiard} from './hook/useBookingWizard'

export interface BookingCreateWizardProps {
  onSuccess?: () => void
  event?: EventModel | ActivityModel | null
  step?: number
}

interface RouterProps {
  params: {
    eventCode: string
    customerCode: string
  }
}

export const BookingCreateWizard = ({onSuccess, event, step}: BookingCreateWizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {pushError, push} = useAlerts()

  const dispatch = useDispatch()
  const {
    params: {customerCode},
  }: RouterProps = useRouteMatch()

  const {customer, getCustomer} = useBookingWiard()

  useOnChange(customerCode, () => {
    if (customerCode) getCustomer(customerCode)
  })

  useOnChange(step, () => {
    if (step) setCurrentStep(step)
  })

  const formik = useFormik({
    initialValues: EMPTY_FORM_VALUES,
    validationSchema: bookingWizardValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = getPayload(values)
        const {data} = await PostBulkBooking([payload])
        if (data) {
          push({
            message: `Booking created successfully`,
            variant: 'success',
            timeout: 3000,
          })
          dispatch(actions.bookingForm.resetBookingForm())
          onSuccess?.()
        }
      } catch (e: any) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useOnChange(customer, () => {
    if (customer) formik.setFieldValue('customer', customer)
  })

  const handleStepChange = useCallback(
    async (step: number) => {
      setCurrentStep(step)
      dispatch(actions.bookingForm.setBookingForm(formik.values))
    },
    [dispatch, formik.values]
  )

  return (
    <BookingWizard
      formik={formik}
      step={currentStep}
      onStepChange={handleStepChange}
      event={event}
      customer={customer}
    />
  )
}
