import {MercatorChart} from '../../../../../../components/charts/MercatorChart/MercatorChart'
import {MercatorMapFeature} from '../../../../../../components/charts/MercatorChart/MercatorMap'
import {EventMercatorChartTooltip} from './EventMercatorChartTooltip'

export const EventMercatorChart = () => {
  return (
    <MercatorChart
      getColor={getColor}
      tooltip={<EventMercatorChartTooltip data={eventMapData} />}
    />
  )
}

const getColor = (feature: MercatorMapFeature) => {
  return eventMapData.some((data) => data.countryCode === feature.id) ? '#187de4' : '#323438'
}

export interface EventMapData {
  events: EventData[]
  countryCode: string
}

interface EventData {
  total: number
  name: string
  date: [Date, Date]
}

const eventMapData: EventMapData[] = [
  // {
  //   countryCode: 'ARE',
  //   events: [
  //     {
  //       name: 'Abu Dhabi Grand Prix 2022',
  //       total: 0,
  //       date: [moment('2022-10-22').toDate(), moment('2022-10-25').toDate()],
  //     },
  //     {
  //       name: 'World Government Summit',
  //       total: 283,
  //       date: [moment('2022-02-25').toDate(), moment('2022-02-28').toDate()],
  //     },
  //   ],
  // },
  // {
  //   countryCode: 'USA',
  //   events: [
  //     {
  //       name: 'Abu Dhabi Grand Prix 2022',
  //       total: 0,
  //       date: [moment('2022-10-22').toDate(), moment('2022-10-25').toDate()],
  //     },
  //     {
  //       name: 'World Government Summit',
  //       total: 283,
  //       date: [moment('2022-02-25').toDate(), moment('2022-02-28').toDate()],
  //     },
  //   ],
  // },
]
