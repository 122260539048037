import {Badge} from '../../../../components/badge/Badge'
import {ColorVariant} from '../../../../components/inputs/Button'
import {CustomerModel} from '../../../../models/CustomerModel'

export const CustomerStatusColumn = ({data}: {data?: CustomerModel}) => {
  return (
    <Badge variant={getBadgeVariant(data)} uppercase>
      {data?.status}
    </Badge>
  )
}

const getBadgeVariant = (customer?: CustomerModel): ColorVariant => {
  switch (customer?.status) {
    case 'active':
      return 'success'
    case 'disabled':
      return 'danger'
    case 'pending':
      return 'warning'
    case 'queue':
      return 'dark'
    case 'sent':
      return 'info'
    case 'submitted':
      return 'primary'
    default:
      return 'dark'
  }
}
