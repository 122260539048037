import clsx from 'clsx'
import {useMemo} from 'react'
import color from 'chroma-js'
import {useCalendarItemData} from '../../../../../../components/charts/Calendar/useCalendarItemData'
import {useDefaultColorScalePreset} from '../../../../../../components/charts/hooks/useDefaultColorScalePreset'
import {CalendarWidgetModel} from '../../../../../../models/ems/WidgetModel'
import styles from './EventCalendarChild.module.scss'
import moment from 'moment'
import {DateUtil} from '../../../../../../utils/DateUtil'

export interface EventCalendarChildProps {
  data?: EventCalendarData
  domain: string[]
}

export const EventCalendarChild = ({data, domain}: EventCalendarChildProps) => {
  const {date, inactive} = useCalendarItemData()

  const getColor = useDefaultColorScalePreset({domain})

  const dateIsToday = useMemo(() => {
    return getDateString(date) === getDateString(today)
  }, [date])

  const currentDateData = useMemo(
    (): Array<CalendarWidgetModel | null> => data?.[getDateString(date)] || [],
    [data, date]
  )

  const weekData = useMemo((): EventCalendarData => {
    if (data) {
      const startOfWeek = moment(date).startOf('week').toDate()
      return Object.entries(data).reduce<EventCalendarData>((acc, [dateString, events]) => {
        const datumDate = moment(parseDateString(dateString))
        if (datumDate.isBefore(date, 'day') && datumDate.isSameOrAfter(startOfWeek, 'day')) {
          acc[dateString] = events
        }
        return acc
      }, {})
    }
    return {}
  }, [data, date])

  const rows = useMemo(() => {
    let spacing = 0
    return currentDateData?.map((event, i) => {
      if (!event) {
        spacing++
        return null
      }
      const hasExistedThisWeek = Object.values(weekData).some((weekEvent) =>
        weekEvent.some((dayEvent) => dayEvent && dayEvent.code === event.code)
      )
      const shouldShow = !hasExistedThisWeek
      const isStart = isSameDay(DateUtil.getDateFromApiString(event.startedAt), date)
      const isEnd = isSameDay(DateUtil.getDateFromApiString(event.endedAt), date)
      const row = (
        <div
          style={{
            height: '2rem',
            marginTop: `${spacing * 2}rem`,
          }}
          className={clsx('w-100 py-1', {
            'pe-1': isEnd,
            'ps-1': isStart,
          })}
          key={`${event?.code}${i}`}
        >
          <div
            style={{
              backgroundColor: event ? color(getColor(event.code)).alpha(0.7).css() : undefined,
            }}
            className={clsx(
              'h-100 badge text-white w-100 d-flex justify-content-start align-items-center',
              styles.event,
              {
                [styles.eventEnd]: isEnd,
                [styles.eventStart]: isStart,
              }
            )}
          >
            <span style={{zIndex: 1}} className={clsx({'opacity-0': !shouldShow})}>
              {event?.name}
            </span>
          </div>
        </div>
      )
      spacing = 0
      return row
    })
  }, [currentDateData, date, getColor, weekData])

  return (
    <div
      style={{
        minHeight: '8rem',
        width: '100%',
      }}
    >
      <div
        className={clsx({
          'text-danger': !dateIsToday && date.getDay() === 0,
          'text-primary': dateIsToday,
          'opacity-25': inactive,
        })}
      >
        {date.getDate()}
      </div>
      <div>{rows}</div>
    </div>
  )
}

const today = new Date()

export type EventCalendarData = Record<string, Array<null | CalendarWidgetModel>>

export const getDateString = (date: Date) => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export const parseDateString = (date: string) => {
  return moment(date, 'YYYY/MM/DD').toDate()
}

export const isSameDay = (a: Date, b: Date) => {
  return getDateString(a) === getDateString(b)
}
