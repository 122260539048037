import {FormikContextType} from 'formik'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ReservationFormValues} from '../ReservationWizard'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../setup'
import {useReservationFormData} from '../hooks/useReservationFormData'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useOnChange} from '../../../../../../components/hooks/useOnChange'
import {TableRowId} from '../../../../../../components/tables/TableRow'
import {FilterModel} from '../../../../../../models/FilterModel'
import {Button} from '../../../../../../components/inputs/Button'
import {CustomerWizardTable} from '../table/CustomerWizardTable'

export interface ReservationWizardCustomerStepFormValues {
  customer: CustomerModel | null
}

export interface ReservationWizardCustomerStepProps<
  T extends ReservationWizardCustomerStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | null
  disabledFields?: Partial<Record<keyof ReservationWizardCustomerStepFormValues, boolean>>
  isEdit?: boolean
  onSelectCustomer?: (customerSelected: boolean) => void
  onStepChange?: (step: number) => void
}

export const ReservationWizardCustomerStep = <T extends ReservationWizardCustomerStepFormValues>({
  formik,
  event,
  isEdit,
  onSelectCustomer,
  onStepChange,
}: ReservationWizardCustomerStepProps<T>) => {
  const reservationForm: ReservationFormValues = useSelector<RootState>(
    ({ems}) => ems.reservationForm
  ) as ReservationFormValues

  const {searchCustomers, customers} = useReservationFormData({
    eventCode: event?.code,
  })
  const [isStart, setIsStart] = useState<boolean>(false)

  useOnChange(reservationForm, () => {
    if (reservationForm) {
      if (reservationForm.customer) formik.setFieldValue('customer', reservationForm.customer)
    }
  })

  useEffect(() => {
    searchCustomers()
  }, [searchCustomers])

  const selectedItems = useMemo(() => {
    return formik.values?.customer ? [formik.values.customer.code] : []
  }, [formik.values.customer])

  const handleOnRowSelectionChange = useCallback(
    (rows: TableRowId[]) => {
      if (isStart) {
        const foundData = customers?.data.find((customer) => {
          return customer.code === rows[0]
        })
        if (foundData) {
          if (foundData.code === formik.values.customer?.code) formik.setFieldValue('customer', '')
          else formik.setFieldValue('customer', foundData)
        }
      }
    },
    [customers?.data, formik, isStart]
  )

  useOnChange(formik.values.customer, () => {
    if (!isStart) {
      if (formik.values.customer) {
        setTimeout(() => {
          setIsStart(true)
          formik.setFieldValue('customer', formik.values?.customer)
        }, 1000)
      } else {
        setTimeout(() => {
          setIsStart(true)
        }, 1000)
      }
    }
  })

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      searchCustomers(filter)
    },
    [searchCustomers]
  )

  const handleIsSelectDisabled = useCallback(
    (rowData: CustomerModel) => {
      if (isEdit) {
        return true
      }
      const firstSelection = formik.values.customer
      const foundData = customers?.data.find((customer) => {
        return customer.code === firstSelection?.code
      })
      if (foundData) {
        return foundData?.code !== rowData?.code
      }
      return false
    },
    [customers?.data, formik.values.customer, isEdit]
  )

  const noCustomerFound = useMemo(() => {
    const noCustomer = customers?.data && customers.data.length > 0

    return noCustomer
  }, [customers?.data])

  useOnChange(noCustomerFound, () => {
    if (!noCustomerFound) onSelectCustomer && onSelectCustomer(true)
    else if (noCustomerFound) onSelectCustomer && onSelectCustomer(false)
  })

  const customerTable = useMemo(() => {
    return (
      <div className='mb-5'>
        <CustomerWizardTable
          onFilter={onFilterHandler}
          data={customers}
          viewOnly={true}
          hideSelectAll={true}
          onRowSelectionChange={handleOnRowSelectionChange}
          selectedItems={selectedItems}
          isSelectDisabled={handleIsSelectDisabled}
          isEdit={isEdit}
        />
        {!noCustomerFound && (
          <div className='text-center'>
            <Button
              variant='primary'
              className='btn'
              onClick={() => onStepChange && onStepChange(1)}
            >
              Create new Reseller
            </Button>
          </div>
        )}
      </div>
    )
  }, [
    customers,
    handleIsSelectDisabled,
    handleOnRowSelectionChange,
    isEdit,
    noCustomerFound,
    onFilterHandler,
    onStepChange,
    selectedItems,
  ])

  return (
    <>
      <div className='container'>
        <div className='row g-5'>
          <div className='col-12'>
            <h5 className='mt-10 mb-0 px-8'>Select a reseller:</h5>
          </div>
          {customerTable}
        </div>
      </div>
    </>
  )
}
