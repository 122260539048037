import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      {/* THEME: LOGO */}
      <img src={toAbsoluteUrl('/media/saas/webntech.svg')} alt='WEB·N·TECH' />
      <span>Loading ...</span>
    </div>
  )
}
