import {ReactNode, useMemo} from 'react'
import {useOutletAuth} from '../../../app/components/hooks/useOutletAuth'
import {OutletModel} from '../../../app/models/fnb/OutletModel'
import {UserProfileMenu} from '../../../app/modules/outlet/components/UserProfileMenu'
import {toAbsoluteUrl} from '../../helpers'
import {Header} from '../common/Header'
import {MasterInit} from '../MasterInit'

export interface FohLayoutProps {
  children?: ReactNode
  outlet?: OutletModel
  title?: string
}

export const FohLayout = ({children, outlet}: FohLayoutProps) => {
  const auth = useOutletAuth()

  const user = useMemo(() => {
    return auth?.getUser()
  }, [auth])

  return (
    <>
      <MasterInit />
      <div className='foh-layout-root'>
        <Header>
          <img alt='Logo' className='h-20px logo' src={toAbsoluteUrl('/media/saas/webntech.svg')} />
          <h1 className='fs-1 fw-bold flex-grow-1 m-0 ms-20'>{outlet?.name}</h1>
          <UserProfileMenu
            signoutLink='/logout'
            initials={user?.avatarCode || ''}
            name={user?.name || ''}
            email={user?.email || ''}
            userType={user?.type}
          />
        </Header>
        <div className='foh-content'>{children}</div>
      </div>
    </>
  )
}
