import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {NavButton} from './NavButton'

export interface NavHeaderProps {
  menus: NavigationSectionMenuItem[]
}

export const NavHeader = ({menus}: NavHeaderProps) => {
  return (
    <div className='d-flex overflow-auto h-100'>
      {menus.map((item, i) => (
        <NavButton className='text-nowrap h-100' key={i} item={item} />
      ))}
    </div>
  )
}
