import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {useAuthRole} from '../../../../../../components/hooks/useAuthRole'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {CustomerModel, CustomerModelStatus} from '../../../../../../models/CustomerModel'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {Plural} from '../../../../../../utils/Plural'
import {SwalUtils} from '../../../../../../utils/SwalUtils'
import {useAppConfig} from '../../../../../app-config/hooks/useAppConfig'
import {
  ActivateCustomers,
  DeleteCustomer,
  GetRegistrationLink,
  SendRegistrationLink,
} from '../../../redux/SvcCRUD'

export interface UseCustomerHelpersParams {
  onRefresh?: () => void
}

export const useCustomerHelpers = ({onRefresh}: UseCustomerHelpersParams = {}) => {
  const auth = useAuthRole()
  const {push, pushError} = useAlerts()
  const {isSiteRegistrationEnabled} = useAppConfig()
  const {setIsLoading, isLoading, isKeyLoading} = useLoadingState()

  const activateCustomersByCodes = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncConfirm(
        async () => {
          try {
            await ActivateCustomers(codes)
            push({
              message: `Successfully activated ${Plural.pluralize(
                codes.length,
                'customer',
                'customers'
              )}.`,
              timeout: 5000,
              variant: 'success',
            })
            onRefresh?.()
          } catch (e) {
            pushError(e)
          }
        },
        {
          text: `Activate ${Plural.pluralize(codes.length, 'customer', 'customers')}?`,
        }
      )
    },
    [onRefresh, push, pushError]
  )

  const deleteCustomersByCodes = useCallback(
    async (codes: string[]) => {
      const response = await SwalUtils.deleteItem()
      if (response.isConfirmed) {
        const doneLoading = setIsLoading(codes)
        try {
          await DeleteCustomer(codes)
          push({
            message: `Customer successfully deleted.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh?.()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [onRefresh, push, pushError, setIsLoading]
  )

  const deleteCustomer = useCallback(
    async (data: CustomerModel[] | CustomerModel) => {
      const codes = Array.isArray(data) ? data.map((item) => item.code) : [data.code]
      await deleteCustomersByCodes(codes)
    },
    [deleteCustomersByCodes]
  )

  const activateCustomer = useCallback(
    (data: CustomerModel | CustomerModel[]) => {
      const codes = Array.isArray(data) ? data.map((item) => item.code) : [data.code]
      activateCustomersByCodes(codes)
    },
    [activateCustomersByCodes]
  )

  const canManage = useMemo(() => {
    return auth?.canManageFeature('SVCCUST')
  }, [auth])

  const customerCanBeSentRegistration = useCallback(
    (customer: CustomerModel) => {
      if (!isSiteRegistrationEnabled) {
        return false
      }
      const allowedStatus: CustomerModelStatus[] = ['pending', 'sent', 'submitted']
      return allowedStatus.includes(customer.status)
    },
    [isSiteRegistrationEnabled]
  )

  const customerRegistrationLinkCanBeCopied = useCallback(
    (customer: CustomerModel) => {
      if (!isSiteRegistrationEnabled) {
        return false
      }
      return customer.status === 'sent'
    },
    [isSiteRegistrationEnabled]
  )

  const customerCanBeActivated = useCallback((customer: CustomerModel) => {
    const allowedStatus: CustomerModelStatus[] = ['pending']
    return allowedStatus.includes(customer.status)
  }, [])

  const copyCustomerRegistrationLink = useCallback(
    async (customer: CustomerModel, event: EventModel) => {
      const doneLoading = setIsLoading(customer.code)
      try {
        const {data} = await GetRegistrationLink(customer.code, event.code)
        navigator.clipboard.writeText(data.url)
        push({
          message: 'Registration link copied to clipboard.',
          variant: 'primary',
          timeout: 5000,
        })
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [push, pushError, setIsLoading]
  )

  const sendCustomerRegistration = useCallback(
    async (customer: CustomerModel | CustomerModel[], event: EventModel) => {
      const codes = Array.isArray(customer) ? customer.map((c) => c.code) : [customer.code]
      const doneLoading = setIsLoading(codes)
      try {
        await SendRegistrationLink(codes, event.code)
        push({
          message: 'Registration links has been sent.',
          variant: 'primary',
          timeout: 5000,
        })
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [push, pushError, setIsLoading]
  )

  const isCustomerLoading = useCallback(
    (customer: CustomerModel) => {
      return isKeyLoading(customer.code)
    },
    [isKeyLoading]
  )

  return {
    customerCanBeSentRegistration,
    customerRegistrationLinkCanBeCopied,
    customerCanBeActivated,
    deleteCustomer,
    activateCustomer,
    copyCustomerRegistrationLink,
    sendCustomerRegistration,
    canManage,
    isLoading,
    isCustomerLoading,
    isSiteRegistrationEnabled,
  }
}
