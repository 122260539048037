import {ProvidedProps, PieArcDatum} from '@visx/shape/lib/shapes/Pie'
import {animated, useTransition, to} from 'react-spring'

export interface PieChartDatum {
  value: number
  label: string
  key: string
}

export interface PieChartSegmentProps<Datum> extends ProvidedProps<Datum> {
  animate?: boolean
  getColor: (d: PieArcDatum<Datum>) => string
  onClickDatum?: (d: PieArcDatum<Datum>) => void
  delay?: number
  onMouseMove?: (e: React.MouseEvent<SVGGElement, MouseEvent>, d: PieArcDatum<Datum>) => void
}

interface AnimatedStyles {
  startAngle: number
  endAngle: number
  opacity: number
}

export const PieChartSegment = ({
  animate,
  arcs,
  path,
  getColor,
  onClickDatum,
  onMouseMove,
}: PieChartSegmentProps<PieChartDatum>) => {
  const transitions = useTransition<PieArcDatum<PieChartDatum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })

  return transitions((props, arc, {key}) => {
    const [centroidX, centroidY] = path.centroid(arc)
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1

    return (
      <g key={key} onMouseMove={(e) => onMouseMove?.(e, arc)}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum?.(arc)}
          onTouchStart={() => onClickDatum?.(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{opacity: props.opacity}}>
            <text
              fill='white'
              x={centroidX}
              y={centroidY}
              dy='.33em'
              fontSize={19}
              textAnchor='middle'
              pointerEvents='none'
            >
              {arc.data.label}
            </text>
          </animated.g>
        )}
      </g>
    )
  })
}

const fromLeaveTransition = ({endAngle}: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})
const enterUpdateTransition = ({startAngle, endAngle}: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
})

const getKey = (datum: PieArcDatum<PieChartDatum>) => {
  return datum.data.key
}
