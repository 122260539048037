import clsx from 'clsx'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import Pie, {PieArcDatum} from '@visx/shape/lib/shapes/Pie'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import {ReactNode, useCallback, useMemo, useRef} from 'react'
import {ColorLegend, ColorLegendItem} from '../ColorLegend/ColorLegend'
import {PieChartDatum, PieChartSegment} from './PieChartSegment'
import styles from './PieChart.module.scss'
import {TooltipContextProvider} from '../TooltipContext/TooltipContextProvider'

export interface PieChartProps {
  datum: PieChartDatum[]
  tooltip?: ReactNode
  getColor: (key: string) => string
}

export const PieChart = ({datum, tooltip, getColor}: PieChartProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()

  const getDatumColor = useCallback(
    (datum: PieArcDatum<PieChartDatum>) => getColor(datum.data.key),
    [getColor]
  )

  const legends = useMemo((): ColorLegendItem[] => {
    return datum.map((datum) => ({
      color: getColor(datum.key),
      label: datum.label,
      key: String(datum.key),
    }))
  }, [datum, getColor])

  const handleLegendMouseMove = useCallback(
    (e: unknown, key: string | number) => {
      showTooltip({
        tooltipData: String(key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handlePieSegmentMouseMove = useCallback(
    (e: unknown, datum: PieArcDatum<PieChartDatum>) => {
      showTooltip({
        tooltipData: String(datum.data.key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  return (
    <div
      className={clsx('h-100 w-100 d-flex', styles.root)}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
    >
      <ColorLegend
        className={styles.legends}
        legends={legends}
        onMouseLeave={hideTooltip}
        onMouseMove={handleLegendMouseMove}
      />
      <ParentSize>
        {({width, height}) => {
          const innerWidth = width
          const innerHeight = height
          const radius = Math.min(innerWidth, innerHeight) / 2
          const centerY = innerHeight / 2
          const centerX = innerWidth / 2

          return (
            <svg width={width} height={height}>
              <Group top={centerY} left={centerX}>
                <Pie data={datum} pieValue={pieValueAccessor} outerRadius={radius} cornerRadius={3}>
                  {(pie) => (
                    <PieChartSegment
                      {...pie}
                      animate
                      getColor={getDatumColor}
                      onMouseMove={handlePieSegmentMouseMove}
                    />
                  )}
                </Pie>
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
            {tooltip}
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}

const pieValueAccessor = (datum: PieChartDatum) => {
  return datum.value
}
