import { useCallback, useMemo, useState } from "react"
import { ReservationSeatInputItemAttributes, ReservationSeatInputItemValue } from "../../../../../../../components/inputs/ReservationSeatInput/PortalReservationSeatInputItem"
import { LocationModel } from "../../../../../../../models/acs/LocationModel"
import {Modal} from 'react-bootstrap'
import { KTSVG } from "../../../../../../../../_metronic/helpers"
import { ReservationSeatInput } from "../../../../../../../components/inputs/ReservationSeatInput/ReservationSeatInput"
import { Button } from "../../../../../../../components/inputs/Button"
import { LoadingSpinner } from "../../../../../../../components/utils/LoadingSpinner"


interface PortalAssignSeatsLocationModalProps {
    onModalClose: () => void
    onAdd: (newValues: ReservationSeatInputItemValue[]) => void
    onSave: () => void
    open: boolean
    locations: LocationModel[]
    eventCode: string | null
    selectedLocations: ReservationSeatInputItemValue[]
    qty: number
    productCode: string
    productName: string
    
  }
  
  export const PortalAssignSeatsLocationModal = ({
    onModalClose,
    locations,
    onAdd,
    onSave,
    open,
    eventCode,
    selectedLocations,
    qty,
    productCode,
    productName
  }: PortalAssignSeatsLocationModalProps) => {
    const [nextLocationId, setNextLocationId] = useState(0)
  
    
    const mapLocations = useCallback((data: LocationModel[]): ReservationSeatInputItemAttributes[] => {
      return data.map((_location) => ({
        label: _location.name,
        value: _location.code,
        items: _location.children ? mapLocations(_location.children) : undefined,
      }));
    }, []);
    
    const locationInputItems = useMemo(() => {
      if (locations) {
        return mapLocations(locations);
      } else {
        return [];
      }
    }, [locations, mapLocations]);
  
    const hasLocationsAvailable = useMemo(() => {
      return locationInputItems.length > 0
    }, [locationInputItems.length])
  
    return (
      <Modal
        className='center-modal overflow-visible'
        role='document'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={open}
        onHide={onModalClose}
        animation={false}
      >
        <div className=' px-5 py-3'>
          <>
              <div className='d-flex justify-content-between'>
                <h3 className='mt-3'>Select Location</h3>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  onClick={onModalClose}
                >
                  <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
                </button>
              </div>
              <ReservationSeatInput
                qty={qty}
                disabled={!hasLocationsAvailable}
                items={locationInputItems}
                label='Location'
                onChange={onAdd}
                values={selectedLocations}
                eventCode={eventCode}
                nextLocationId={nextLocationId}
                setNextLocationId={setNextLocationId}
                productCode={productCode}
                productName={productName}
                isPortal
              />
          </>
          <div className='d-flex justify-content-end my-2'>
            <Button className='me-5' onClick={onModalClose}>
              Close
            </Button>
            <Button variant='primary' onClick={onSave}>
              <LoadingSpinner loading={false}>Save</LoadingSpinner>
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
  