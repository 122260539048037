import {FormikContextType} from 'formik'
import {useBookingFormData} from '../../hook/useBookingFormData'
import {EventModel} from '../../../../../../../models/ems/EventModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../../setup'
import {
  BookingWizardBulkCustomerStepFormValues,
  BulkBookingFormValues,
} from '../../../../../../../models/booking-wizard/BulkBookingWizard'
import {BookingWizardSharedBulkCustomerStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedBulkCustomerStep'

export interface BookingWizardBulkCustomerStepProps<
  T extends BookingWizardBulkCustomerStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | null
  isEdit?: boolean
  disabledFields?: Partial<Record<keyof BookingWizardBulkCustomerStepFormValues, boolean>>
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
}

export const BookingWizardBulkCustomerStep = <T extends BookingWizardBulkCustomerStepFormValues>({
  formik,
  event,
  isEdit,
  onNoCustonmers,
  onStepChange,
}: BookingWizardBulkCustomerStepProps<T>) => {
  const bookingBulkForm: BulkBookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkForm
  ) as BulkBookingFormValues

  const {getCustomers, customers} = useBookingFormData({})

  return (
    <>
      <BookingWizardSharedBulkCustomerStep
        formik={formik}
        bookingBulkForm={bookingBulkForm}
        onNoCustonmers={onNoCustonmers}
        onStepChange={onStepChange}
        customers={customers}
        searchCustomers={getCustomers}
        isEdit={isEdit}
      />
    </>
  )
}
