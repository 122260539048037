import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'
import {MetronicIconButton} from '../inputs/MetronicIconButton'

export interface TableActionHeaderProps {
  className?: string
  left?: ReactNode
  right?: ReactNode
  onOptionClick?: () => void
  noPadding?: boolean
}

export const TableActionHeader = ({
  className,
  left,
  onOptionClick,
  right,
  noPadding,
}: TableActionHeaderProps) => {
  const options = useMemo(() => {
    return (
      onOptionClick && (
        <MetronicIconButton
          onClick={onOptionClick}
          className='ms-1'
          iconType='General'
          tooltip='Table options'
          iconName='Other1'
          variant='text'
        />
      )
    )
  }, [onOptionClick])

  return (
    <div
      className={
        noPadding
          ? clsx('px-7 flex-wrap', className)
          : clsx('table-action-header flex-wrap', className)
      }
    >
      {left && <div className='table-action-header-left'>{left}</div>}
      {right && (
        <div className='table-action-header-right'>
          {right} {options}
        </div>
      )}
      {/* {options && <div className='table-action-header-option'>{options}</div>} */}
    </div>
  )
}
