import {useMemo} from 'react'
import {CounterChart} from '../../../components/charts/CounterChart'
import {OccupancyCounter} from '../../../components/charts/OccupancyCounter'
import {EventModel} from '../../../models/ems/EventModel'
import {WidgetModel} from '../../../models/customer-portal/WidgetModel'
import {useEventDashboardWidgetData} from '../hooks/useEventDashboardWidgetData'

export interface DashboardProps {
  event?: EventModel | null
}

export const EventOverview = ({event}: DashboardProps) => {
  const {data: eventData} = useEventDashboardWidgetData({
    widgets: PORTAL_WIDGETS,
    eventCode: event?.code,
  })

  const occupancyProducts = useMemo(() => {
    return (
      <>
        {eventData?.WIDGETP01?.map((product) => {
          return (
            <div
              key={product.productCode}
              className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'
            >
              <OccupancyCounter
                className='h-100'
                hideCheckInOut
                counter={`${Number(product.used)} / ${Number(product.total)}`}
                title={product.productName}
              />
            </div>
          )
        })}
      </>
    )
  }, [eventData?.WIDGETP01])

  const occupancyVouchers = useMemo(() => {
    return (
      <>
        {eventData?.WIDGETP02?.map((voucher) => {
          return (
            <div
              key={voucher.voucherCode}
              className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12'
            >
              <OccupancyCounter
                className='h-100'
                hideCheckInOut
                counter={voucher.qty}
                title={voucher.voucherName}
              />
            </div>
          )
        })}
      </>
    )
  }, [eventData?.WIDGETP02])

  return (
    <div>
      <div className='row mb-6'>
        <div className='col-md-6 col-xs-12 row g-6'>
          <div className='col-12 m-0'>
            <CounterChart
              label='Total products'
              value={eventData?.WIDGETP03 ? Number(eventData?.WIDGETP03) : 0}
            />
          </div>
          <div className='col-12 row g-6 m-0'>{occupancyProducts}</div>
        </div>
        <div className='col-md-6 col-xs-12 row g-6'>
          <div className='col-12 m-0'>
            <CounterChart
              label='Total Vouchers'
              value={eventData?.WIDGETP03 ? Number(eventData?.WIDGETP04) : 0}
            />
          </div>
          <div className='col-12 row g-6 m-0'>{occupancyVouchers}</div>
        </div>
      </div>
    </div>
  )
}

const PORTAL_WIDGETS: (keyof WidgetModel)[] = ['WIDGETP01', 'WIDGETP02', 'WIDGETP03', 'WIDGETP04']
