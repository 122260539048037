import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {WidgetF07Model} from '../../../../../../models/fnb/WidgetModel'

export interface VoucherIssuedTooltipProps {
  data?: WidgetF07Model[]
}

export const VoucherIssuedTooltip = ({data}: VoucherIssuedTooltipProps) => {
  const {key} = useBarChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.voucherName === key)
    }
  }, [data, key])

  if (!hoveredData) {
    return null
  }

  return (
    <MarkdownTooltip>{`
### ${hoveredData.voucherName}

**Event**: ${hoveredData.eventName}

**Amount Issued**: ${hoveredData.quantity}
`}</MarkdownTooltip>
  )
}
