import {FormikContextType} from 'formik'
import {useBookingFormData} from '../hook/useBookingFormData'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../setup'
import {
  BookingFormValues,
  BookingWizardProductVenueStepFormValues,
} from '../../../../../../models/booking-wizard/BookingWizard'
import {BookingWizardProductVoucherSharedStep} from '../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardProductVenueStep'

export interface BookingWizardProductVenueStepProps<
  T extends BookingWizardProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | null
}

export const BookingWizardProductVenueStep = <T extends BookingWizardProductVenueStepFormValues>({
  formik,
  event,
}: BookingWizardProductVenueStepProps<T>) => {
  const {productSearchResults, refreshProductsList, searchVouchers, voucherSearchResults} =
    useBookingFormData({
      eventCode: event?.code || formik.values.eventCode,
    })
  const bookingForm: BookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingForm
  ) as BookingFormValues

  return (
    <BookingWizardProductVoucherSharedStep
      formik={formik}
      searchVouchers={searchVouchers}
      refreshProductsList={refreshProductsList}
      productSearchResults={productSearchResults}
      voucherSearchResults={voucherSearchResults}
      bookingForm={bookingForm}
    />
  )
}
