import {ReactNode, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {TableColumnIcon} from './TableColumnIcon'

export interface TableHeaderProps {
  label: string | ReactNode
  className?: string
  onSort?: (isAscending: boolean) => void
  isAscending?: boolean
  align?: 'end' | 'start' | 'center'
}

export const TableHeader = ({label, className, isAscending, onSort, align}: TableHeaderProps) => {
  const handleOnClick = useCallback(() => {
    onSort && onSort(!isAscending)
  }, [onSort, isAscending])

  const sortIconButton = useMemo(() => {
    const baseClass = clsx(`d-block px-0 text-nowrap`, align && `text-${align}`)

    if (onSort) {
      let iconName: string | undefined = undefined
      if (typeof isAscending === 'boolean') {
        iconName = isAscending ? 'Arrow-up' : 'Arrow-down'
      }

      return (
        <TableColumnIcon
          className={baseClass}
          onClick={handleOnClick}
          iconType='Navigation'
          iconName={iconName}
          label={label}
        />
      )
    }
    return <span className={clsx(baseClass, 'text-uppercase fw-bolder text-white')}>{label}</span>
  }, [onSort, isAscending, handleOnClick, label, align])

  return <th className={clsx(`align-middle`, className)}>{sortIconButton}</th>
}
