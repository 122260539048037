import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import FirstLoginPasswordResetForm from './components/FirstLoginPasswordReset'
import {AuthBackground, AuthFormLogoHeader} from '../../../../config/logos'

export function AuthPage() {
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${AuthBackground.src})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        {/* begin::Logo */}
        {/* THEME: LOGO */}
        <img alt={AuthFormLogoHeader.alt} src={AuthFormLogoHeader.src} className='h-30px mb-12' />
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-sm-500px w-300px bg-body opacity-75 rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route exact path='/auth/login' component={Login} />
            <Route exact path='/auth/login/:hash' component={Login} />
            <Route exact path='/auth/forgot-password' component={ForgotPassword} />
            <Route exact path='/auth/password-reset' component={FirstLoginPasswordResetForm} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}
