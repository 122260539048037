import clsx from 'clsx'
import {forwardRef} from 'react'
import {LoadingSpinner} from '../utils/LoadingSpinner'
import {Button, ButtonVariant, ButtonProps} from './Button'
import {MetronicIcon} from './MetronicIcon'

export type ButtonSize = 'sm' | 'md' | 'lg'

export interface MetronicIconButtonProps extends Omit<ButtonProps, 'children'> {
  iconType: string
  iconName: string
  className?: string
  variant?: ButtonVariant
  tooltip?: string
  size?: ButtonSize
  loading?: boolean
  classes?: {
    icon?: string
  }
}

export const MetronicIconButton = forwardRef<HTMLButtonElement, MetronicIconButtonProps>(
  (
    {iconName, classes, iconType, className, size, loading = false, disabled, ...buttonProps},
    ref
  ) => {
    return (
      <Button
        className={clsx('btn-icon', className)}
        ref={ref}
        size={size}
        disabled={loading || disabled}
        {...buttonProps}
      >
        <LoadingSpinner spinnerOnly loading={loading}>
          <MetronicIcon
            className={clsx('m-0', classes?.icon)}
            size={size}
            iconType={iconType}
            iconName={iconName}
          />
        </LoadingSpinner>
      </Button>
    )
  }
)
