import clsx from 'clsx'
import {DragEvent, useCallback, useMemo} from 'react'
import {MetronicIcon} from '../../MetronicIcon'
import {BaseFileInputValue} from '../BaseFileInputValue'
import {FileInputValueCollection} from '../FileInputValueCollection'
import {DragDropFileInputDragAreaThumbnail} from './DragDropFileInputDropAreaThumbnail'

export interface DragDropFileInputDropAreaProps<T extends BaseFileInputValue> {
  id: string
  placeholderText: string
  value: FileInputValueCollection<T>
  fileFactory: (file: File) => T
  onChange: (file: FileInputValueCollection<T>) => void
  limit?: number
  className?: string
}

export const DragDropFileInputDropArea = <T extends BaseFileInputValue>({
  id,
  placeholderText,
  value,
  fileFactory,
  onChange,
  limit = 1,
  className,
}: DragDropFileInputDropAreaProps<T>) => {
  const handleDrop = useCallback(
    (e: DragEvent<HTMLLabelElement>) => {
      e.stopPropagation()
      e.preventDefault()
      const fileList = e.dataTransfer?.files
      if (fileList) {
        const newValue = value.clone()
        newValue.addFileList(fileList, fileFactory, limit)
        onChange(newValue)
      }
    },
    [value, fileFactory, onChange, limit]
  )

  const handleDragOver = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const handleRemove = useCallback(
    (file: BaseFileInputValue) => {
      const newValue = value.clone()
      newValue.remove(file.id)
      onChange && onChange(newValue)
    },
    [onChange, value]
  )

  const thumbnails = useMemo(() => {
    if (!value.hasValue()) {
      return (
        <>
          <MetronicIcon size='xl' iconType='Files' iconName='Uploaded-file' />
          <span className='text-center'>{placeholderText}</span>
        </>
      )
    }
    return value
      .getFileArray()
      .map((file) => (
        <DragDropFileInputDragAreaThumbnail onClose={handleRemove} key={file.id} file={file} />
      ))
  }, [handleRemove, placeholderText, value])

  return (
    <label
      role='button'
      htmlFor={id}
      className={clsx(
        'drag-drop-file-input-drop-area',
        {
          'drag-drop-file-input-drop-area--empty': !value.hasValue(),
          'drag-drop-file-input-drop-area--has-value': value.hasValue(),
        },
        className
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {thumbnails}
    </label>
  )
}
