import {useCallback, useMemo, useState} from 'react'
import {FilterTable} from '../../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {BookingDetailModel} from '../../../../../../models/customer-portal/BookingDetailModel'
import {DateUtil} from '../../../../../../utils/DateUtil'
import {CustomerBookingTableActions} from '../../../tables/BookingProductTable/CustomerBookingDetailTableActions'
import {
  SeatMapSelectionModalFormValues,
  getPayload,
} from '../../../../../../components/forms/SeatMapSelectionModalForm'
import {useModalState} from '../../../../../../components/modals/useModalState'
import {FulfillNonSeated, FulfillTicket} from '../../../../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {BookingProductStatusColumn} from '../../../tables/BookingProductStatusColumn'
import {FilterModel} from '../../../../../../models/FilterModel'
import {useTableOptions} from '../../../../../../components/tables/useTableOptions'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {ColumnStyle} from '../../../../../../components/tables/constants/ColumnStyle'
import {Link} from 'react-router-dom'
import {BookingProductTypeColumn} from '../../../../../default/ems/components/tables/column-formatters/BookingProductTypeColumn'
import {ProductTicketTypeColumn} from '../../../../../default/ems/components/tables/column-formatters/ProductTicketTypeColumn'
import {SeatMapPortalSelectionModalForm} from '../../../../../../components/forms/SeatMapPortalSelectionModalForm'

export interface ProductBookingDetailsTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<BookingDetailModel>
  onRefresh?: () => void
  linkFactory?: (data: BookingDetailModel) => string | void | (() => void)
  removedColumns?: string[]
  customerCode?: string
}

export const ProductBookingDetailsTable = ({
  data,
  onFilter,
  onRefresh,
  linkFactory,
  removedColumns,
  customerCode,
}: ProductBookingDetailsTableProps) => {
  const {isKeyLoading, setIsLoading} = useLoadingState()
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const [activeProduct, setActiveProduct] = useState<BookingDetailModel | null>(null)
  const {push, pushError} = useAlerts()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'product-booking-detail',
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: BookingDetailModel) => {
      setActiveProduct(data)
      openModal()
    },
    [openModal]
  )

  const fulfillNonSeatedProduct = useCallback(
    async (data: BookingDetailModel) => {
      const doneLoading = setIsLoading(data.code)
      try {
        await FulfillNonSeated(data.bookingProductCode)
        push({
          message: 'Successfully fulfilled ticket.',
          timeout: 5000,
          variant: 'success',
        })
        onRefresh && onRefresh()
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [onRefresh, push, pushError, setIsLoading]
  )

  const handleSelectionSubmit = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      if (activeProduct) {
        const payload = getPayload(values, activeProduct.bookingProductCode)
        const doneLoading = setIsLoading(payload.productCode)
        try {
          await FulfillTicket(payload)
          push({
            message: 'Successfully fulfilled ticket.',
            timeout: 5000,
            variant: 'success',
          })
          hideModal()
          onRefresh && onRefresh()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [activeProduct, hideModal, onRefresh, push, pushError, setIsLoading]
  )

  const idExtractor = useCallback((data: BookingDetailModel) => {
    return data.code + data.bookingProductCode + data.customerCode
  }, [])

  const rowActions = useCallback(
    (data: BookingDetailModel) => (
      <CustomerBookingTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        onFulfillNonSeatedProduct={fulfillNonSeatedProduct}
        loading={isKeyLoading(data.code)}
      />
    ),
    [fulfillNonSeatedProduct, getSeatAssignmentHandler, isKeyLoading]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<BookingDetailModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        render: ({data}) => {
          if (data.bookingProductStatus === 'pending') {
            return <>{data.code}</>
          }
          if (linkFactory) {
            const link = linkFactory(data)
            if (typeof link === 'function') {
              return (
                <span className='text-primary' role='button' onClick={link}>
                  {data.code}
                </span>
              )
            }
            if (link) {
              return <Link to={link}>{data.code}</Link>
            }
          }
          return <>{data.code}</>
        },
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'description',
        label: 'Description',
        cellStyle: ColumnStyle.DESCRIPTION,
        hideable: true,
      },
      {
        field: 'productCategory',
        label: 'Category',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product?.productCategoryName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.startedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.endedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'type',
        label: 'Ticket Type',
        sortable: true,
        hideable: true,
        render: ProductTicketTypeColumn,
      },
      {
        field: 'isSeated',
        label: 'Seated',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isSeated,
      },
      {
        field: 'booking',
        label: 'Qty',
        sortable: false,
        hideable: true,
        type: 'number',
        dataExtract: (product) => product.bookingProductQty,
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: BookingProductStatusColumn,
      },
      {
        field: 'bookingProductType',
        label: 'Product Type',
        sortable: true,
        hideable: true,
        render: BookingProductTypeColumn,
      },
    ]
    if (removedColumns) {
      return columns.filter((column) => !removedColumns.includes(column.field))
    }
    return columns
  }, [linkFactory, removedColumns])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total}
        actions={rowActions}
      />
      <SeatMapPortalSelectionModalForm
        onSubmit={handleSelectionSubmit}
        product={activeProduct}
        customerCode={customerCode}
        {...getModalState()}
      />
    </>
  )
}
