import {FC, useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useRouteMatch} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {CustomerModel} from '../../../models/CustomerModel'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {CustomerFormEdit} from './DrawerForm/CustomerFormDrawer'

type Props = {
  customer?: CustomerModel
  onRefresh?: () => void
}

interface RouteMatch {
  bookingCode: string
  path: string
}

const CustomerDetailHeader: FC<Props> = ({customer, onRefresh}) => {
  const location = useLocation()
  const match = useRouteMatch<RouteMatch>()
  const {bookingCode} = match.params
  const {staticUrls} = useAppConfig()
  const [drawer, setDrawer] = useDrawerRef()

  const avatar = useMemo(() => {
    if (customer)
      if (customer.photo) {
        const imageValue = new ImageInputValue(staticUrls.public, customer.photo)
        return imageValue.url
      }
  }, [customer, staticUrls.public])

  return (
    <>
      {customer ? (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-row'>
                      {avatar && (
                        <div className='d-flex drag-drop-file-input-drop-area-thumbnail'>
                          <img src={avatar} alt='' className=' h-100' />
                        </div>
                      )}

                      <div className='d-flex flex-column px-2'>
                        <div className='d-flex align-items-center'>
                          <p className='text-gray-800 fs-2 fw-bolder mb-1'>{customer.name}</p>
                        </div>
                        {customer.mobile && customer.mobile.length > 0 && (
                          <div className='d-flex flex-wrap mt-4 mb-2 pe-2'>
                            <span className='d-flex align-items-center me-5'>
                              <KTSVG
                                path='/media/icons/duotone/Devices/Phone.svg'
                                className='svg-icon-4 me-1'
                              />
                              {customer.mobile}
                            </span>
                          </div>
                        )}
                        {customer.email && customer.email.length > 0 && (
                          <div className='d-flex flex-wrap mb-4 pe-2'>
                            <span className='d-flex align-items-center text-muted me-5 mb-2'>
                              <KTSVG
                                path='/media/icons/duotone/Communication/Mail.svg'
                                className='svg-icon-4 me-1'
                              />
                              {customer.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='d-flex my-4 flex-row align-items-end'>
                      <div className='mx-2'>
                        <button
                          type='button'
                          className='btn btn-flush btn-color-warning'
                          onClick={drawer?.show}
                        >
                          <KTSVG path='/media/icons/duotone/Design/Edit.svg' />
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        ((location.pathname === `/customer/${customer.code}/booking` ||
                          location.pathname.includes('/booking/edit')) &&
                          'active')
                      }
                      to={`/customer/${customer.code}/booking`}
                    >
                      Bookings
                    </Link>
                  </li>
                  {location.pathname.includes(`booking/new`) &&
                    !location.pathname.includes(`bulk`) && (
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/customer/${customer.code}/booking/new` &&
                              'active')
                          }
                          to={`/customer/${customer.code}/booking/new`}
                        >
                          Create Bookings
                        </Link>
                      </li>
                    )}

                  {location.pathname.includes('/new/bulk') && (
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === `/customer/${customer.code}/booking/new/bulk` &&
                            'active')
                        }
                        to={`/customer/${customer.code}/booking/new/bulk`}
                      >
                        Create Bulk Bookings
                      </Link>
                    </li>
                  )}

                  <li className='nav-item'>
                    {bookingCode && (
                      <Link
                        className={`nav-link text-active-primary me-6 ` + (bookingCode && 'active')}
                        to={`/customer/${customer.code}/booking/${bookingCode}/booking-detail`}
                      >
                        Booking Details
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <CustomerFormEdit
            customerToEdit={customer}
            drawerRef={setDrawer}
            onChange={() => onRefresh && onRefresh()}
          />
        </>
      ) : null}
    </>
  )
}

export default CustomerDetailHeader
