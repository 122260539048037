import clsx from 'clsx'
import {memo, ReactNode, useMemo} from 'react'
import {TooltipContextProvider} from '../TooltipContext/TooltipContextProvider'

export interface CalendarDateItemProps {
  date: Date
  children?: ReactNode
  inactive?: boolean
  classes?: {
    root?: string
    rootInactive?: string
    rootActive?: string
  }
}

export interface CalendarItemData {
  date: Date
  inactive: boolean
}

export const CalendarDateItem = memo(
  ({date, children, inactive, classes}: CalendarDateItemProps) => {
    const context = useMemo(
      (): CalendarItemData => ({date, inactive: Boolean(inactive)}),
      [date, inactive]
    )

    return (
      <TooltipContextProvider value={context}>
        <div
          className={clsx(classes?.root, inactive ? classes?.rootInactive : classes?.rootActive)}
        >
          {children}
        </div>
      </TooltipContextProvider>
    )
  }
)
