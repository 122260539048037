import clsx from 'clsx'
import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {
  BarChartDatum,
  HorizontalBarChart,
} from '../../../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {SelectInput} from '../../../../../../components/inputs'
import {FilterType} from '../../../../../../components/tables/constants/FilterType'
import {Paper} from '../../../../../../components/utils/Paper'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {GuestCountPerProductStatusModel} from '../../../../../../models/ems/WidgetModel'
import {GuestCountPerProductTooltip} from './GuestCountPerProductTooltip'

export interface GuestCountPerProductProps {
  data?: GuestCountPerProductStatusModel[]
  event?: EventModel
  className?: string
}

export const GuestCountPerProduct = ({data, event, className}: GuestCountPerProductProps) => {
  const [topCategoryTab, setTopCategoryTab] = useState('total')

  const getCount = useCallback(
    (data: GuestCountPerProductStatusModel) => {
      const statusCount = data[topCategoryTab as keyof GuestCountPerProductStatusModel]
      if (statusCount !== undefined) {
        return parseInt(statusCount as string)
      }
      return 0
    },
    [topCategoryTab]
  )

  const datum = useMemo((): BarChartDatum[] => {
    if (data) {
      return data.map((d) => ({
        key: d.code,
        label: d.name,
        value: getCount(d),
      }))
    }
    return []
  }, [data, getCount])

  const handleTopCategoriesTabChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTopCategoryTab(e.target.value)
  }, [])

  return (
    <Paper className={clsx('p-10 pt-8 d-flex flex-column', className)} rounded>
      <div className='mb-3 d-flex justify-content-between'>
        <p className='fs-1'>Top categories</p>
        <SelectInput
          items={TOP_CATEGORY_SELECT_LIST}
          value={topCategoryTab}
          onChange={handleTopCategoriesTabChange}
          noMargin
        />
      </div>
      <div className='flex-grow-1 p-10'>
        <HorizontalBarChart
          datum={datum}
          xLabel='Category'
          yLabel='Total Guests'
          tooltip={
            <GuestCountPerProductTooltip data={data} event={event} filterKey={topCategoryTab} />
          }
        />
      </div>
    </Paper>
  )
}

const kebabToCamel = (kebab: string) => {
  return kebab
    .split('-')
    .map((e, i) => {
      if (i === 0) {
        return e[0].toLowerCase() + e.slice(1)
      }
      return e[0].toUpperCase() + e.slice(1)
    })
    .join('')
}

const TOP_CATEGORY_SELECT_LIST = [
  {
    label: 'All',
    value: 'total',
  },
  ...FilterType.GUEST_STATUS.map((item) => ({label: item.label, value: kebabToCamel(item.value)})),
]
