import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isVoucherDownloadable, isVoucherShareable} from '../../../hooks/useVoucherTableActions'
export interface VoucherTableSelectionActionsProps {
  onDisable: (vouchers: VoucherModel[]) => void
  onDownload: (vouchers: VoucherModel[]) => void
  items: VoucherModel[]
  selected: TableRowId[]
  disabled?: boolean
}

export const VoucherTableSelectionActions = ({
  items,
  onDisable,
  onDownload,
  selected,
  disabled,
}: VoucherTableSelectionActionsProps) => {
  return (
    <>
      <TableSelectionAction
        disabled={disabled}
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onDownload}
        variant='success'
        filter={isVoucherDownloadable}
      >
        Download Vouchers
      </TableSelectionAction>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onDisable}
        selected={selected}
        items={items}
        filter={isVoucherShareable}
        variant='primary'
        disabled={disabled}
      >
        Disable Vouchers
      </TableSelectionAction>
    </>
  )
}
