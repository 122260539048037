import {useCallback, useMemo, useState} from 'react'
import {FilterTable} from '../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {FilterModel} from '../../../../models/FilterModel'
import {ColumnStyle} from '../../../../components/tables/constants/ColumnStyle'
import {useModalState} from '../../../../components/modals/useModalState'
import {CustomerReservationLocationTableActions} from './CustomerReservationLocationTableActions'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {GetLocationByCode} from '../../acs/redux/AcsCRUD'
import {useSeatMapState} from '../../../../components/hooks/useSeatMapState'
import {SeatMapsLocationModal} from '../../../../components/inputs/LocationInput/SeatMapsLocationModal'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {ReservationItemModel} from '../../../../models/ems/ReservationModel'
import {useTableOptions} from '../../../../components/tables/useTableOptions'
import {Link} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

export interface CustomerReservationTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ReservationItemModel>
  eventCode?: string
  onRefresh?: () => void
  linkFactory?: (data: ReservationItemModel) => string | void | (() => void)
  removedColumns?: string[]
}

export const CustomerReservationTable = ({
  data,
  onFilter,
  onRefresh,
  eventCode,
  linkFactory,
  removedColumns,
}: CustomerReservationTableProps) => {
  const {isOpen: isSelectionModalOpen, hide: hideModal, open: openModal} = useModalState()
  const [_locationName, setLocationName] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<Record<string, number[]>>()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-reservation-details',
    defaults: {
      hiddenColumns: ['productCategoryName', 'description'],
    },
  })
  const {pushError} = useAlerts()

  const {
    seatMapSpacingX,
    disabled,
    columns,
    rows,
    hidden,
    isBottomToTop,
    isRightToLeft,
    resetState: resetSeatMapState,
  } = useSeatMapState()

  const getSeatAssignmentHandler = useCallback(
    async (item: ReservationItemModel) => {
      try {
        if (item.productLocationCode) {
          const {data} = await GetLocationByCode(item.productLocationCode)
          resetSeatMapState(data.seatMap)
          setLocationName(data.name)
          if (!data.seatMap) {
            throw new Error('Location has no seat map!')
          }
          setSelectedLocation(item.details)
        }
      } catch (e: any) {
        pushError(e)
      }

      openModal()
    },
    [openModal, pushError, resetSeatMapState]
  )

  const idExtractor = useCallback((data: ReservationItemModel) => {
    return data.code + uuidv4()
  }, [])

  const rowActions = useCallback(
    (data: ReservationItemModel) => (
      <CustomerReservationLocationTableActions data={data} onViewSeats={getSeatAssignmentHandler} />
    ),
    [getSeatAssignmentHandler]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const occupied = useMemo(() => {
    if (selectedLocation) {
      return new SeatMapValue(selectedLocation)
    }
  }, [selectedLocation])
  const tableColumns = useMemo(() => {
    const columns: TableColumnOptions<ReservationItemModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        render: ({data}) => {
          if (linkFactory) {
            const link = linkFactory(data)
            if (typeof link === 'function') {
              return (
                <span className='text-primary' role='button' onClick={link}>
                  {data.code}
                </span>
              )
            }
            if (link) {
              return <Link to={link}>{data.code}</Link>
            }
          }
          return <>{data.code}</>
        },
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Item',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'productLocationName',
        label: 'Section Name',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'productLocationSlug',
        label: 'Section Code',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'productCategoryName',
        label: 'Item Category',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'description',
        label: 'Description',
        sortable: false,
        hideable: true,
        cellStyle: ColumnStyle.DESCRIPTION,
      },
      {
        field: 'reservationModuleQty',
        label: 'Qty',
        sortable: false,
        hideable: true,
        type: 'number',
      },
    ]
    return columns
  }, [linkFactory])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={tableColumns}
        totalItems={data?.total || 10}
        actions={rowActions}
      />
      <SeatMapsLocationModal
        open={isSelectionModalOpen}
        onClose={hideModal}
        isBottomToTop={isBottomToTop}
        isRightToLeft={isRightToLeft}
        columns={columns}
        rows={rows}
        hidden={hidden}
        spacingX={seatMapSpacingX}
        occupied={occupied}
        onSubmit={hideModal}
        disabled={disabled}
        isViewOnly
        locationName={_locationName}
      />
    </>
  )
}
