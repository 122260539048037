import {useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../models/FilterModel'
import {GlobalSearchModel} from '../../models/GlobalSearchModel'

interface UseLocalTableSearchProps<T> {
  data: T[] | null
  columns: Array<keyof T> | null
  additionColumns?: Array<keyof T> | null
}
interface tObject {
  [key: string]: any
}

export const useLocalTableSearch = <T>({
  data,
  columns,
  additionColumns,
}: UseLocalTableSearchProps<T>) => {
  const [search, setSearch] = useState<string>('')
  const allcolumns = useMemo(() => {
    return additionColumns ? columns?.concat(additionColumns) : columns
  }, [additionColumns, columns])
  const searchableLocalTableData = useMemo<GlobalSearchModel<T>>(() => {
    let all: T[] = []
    if (data) {
      data.forEach((item) => {
        let newItem: tObject = {}
        allcolumns?.forEach((key) => {
          if (item && Object.keys(item).find((k) => k === key)) {
            newItem[key as string] = item[key]
          }
        })
        all.push(newItem as T)
      })
      const result: GlobalSearchModel<T> = {
        data: all,
        total: all.length,
        page: 1,
        limit: all.length,
      }

      let newData = {...result}
      newData.data = newData.data.filter((item) => {
        const cols = allcolumns?.find((k) => {
          if (item && Object.keys(item).find((key) => key === k)) {
            return (item?.[k] as any)
              .toString()
              .toLocaleLowerCase()
              ?.includes(search.toLocaleLowerCase())
          }
          return false
        })
        if (cols) return item
        else return null
      })

      return newData
    }
    return DEFAULE_TABLE_VALUE
  }, [allcolumns, data, search])

  const filterSearchableLocalTableData = useCallback((filter: FilterModel) => {
    const search = (filter.filters?.search || '') as string
    setSearch(search)
  }, [])

  return useMemo(
    () => ({
      filterSearchableLocalTableData,
      searchableLocalTableData,
    }),
    [filterSearchableLocalTableData, searchableLocalTableData]
  )
}

const DEFAULE_TABLE_VALUE = {
  data: [],
  total: 0,
  limit: 0,
  page: 0,
}
