import * as yup from 'yup'
import {BulkBookingFormValues} from '../../../models/booking-wizard/BulkBookingWizard'
import {BulkBookingModelCreateParams} from '../../../models/ems/BookingModel'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'

export const bulkBookingValidationSchema = yup.object().shape({
  eventCode: yup.string().required(),
  customers: yup
    .array()
    .test('has-atleast-one-customer', 'Please add at least one customer', (value) => {
      if (!value || value.length === 0) {
        return false
      }

      return true
    }),
  product: yup
    .object()
    .nullable()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as BulkBookingFormValues
      if (formValues.voucher && formValues.voucher.qty > 0) {
        return true
      }
      if (!value && !formValues.voucher) {
        return false
      }

      return true
    }),

  voucher: yup
    .object()
    .nullable()
    .test('has-atleast-one-voucher', 'Please add at least one voucher', (value, context) => {
      const formValues = context.parent as BulkBookingFormValues

      if (!value && formValues.product && formValues.product.qty > 0) {
        return true
      }
      if (!value && !formValues.product) {
        return false
      }

      return true
    }),

  customersSeats: yup
    .array()
    .test('has-atleast-one-seat', 'Please add at least one Seat', (value, context) => {
      const formValues = context.parent as BulkBookingFormValues

      if (formValues.product?.isSeated) {
        if (value && formValues.customers && value.length === formValues.customers.length) {
          return true
        }
        return false
      }
      return true
    }),
})

export const EMPTY_FORM_VALUES: BulkBookingFormValues = {
  customers: [],
  eventCode: '',
  product: null,
  voucher: null,
  customersSeats: null,
}

export const getBulkPayload = (values: BulkBookingFormValues): BulkBookingModelCreateParams[] => {
  if (!values.customers) {
    throw new Error('Invalid form data.')
  }

  const payload: BulkBookingModelCreateParams[] = []

  for (let customer of values.customers) {
    let found: TicketModelFulfillBulkParams | null | undefined = null
    if (values.customersSeats) {
      found = values.product?.isSeated
        ? values.customersSeats.find((item) => item.customerCode === customer.code)
        : null
    }
    const products = values.product
      ? found
        ? [
            {
              code: values.product.code,
              qty: values.product.qty,
              locationCode: found.locationCode,
              seats: found.seats.getSeatMapObject(),
            },
          ]
        : [
            {
              code: values.product.code,
              qty: values.product.qty,
            },
          ]
      : []

    const vouchers = values.voucher
      ? [
          {
            code: values.voucher.code,
            qty: values.voucher.qty,
          },
        ]
      : []

    payload.push({
      eventCode: values.eventCode || '',
      customerCode: customer.code,
      products: products,
      vouchers: vouchers,
    })
  }

  return payload
}
