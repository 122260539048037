import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {loginWithHash, validateMfa} from '../redux/CustomerPortalCRUD'
import * as _redux from '../redux/CustomerPortalRedux'
import {FormikProps} from '../components/Login'
import {useDispatch} from 'react-redux'

interface UseFormikLoginProps {
  setMfaLink: (value: string) => void
  setAuthToken: (value: string) => void
  otpInputRef: React.MutableRefObject<HTMLInputElement | null>
}

export const useFormikLogin = ({setMfaLink, setAuthToken, otpInputRef}: UseFormikLoginProps) => {
  const dispatch = useDispatch()

  const sendRequestByOTP = useCallback(
    async (otp: string, formik: FormikContextType<FormikProps>, authToken: string) => {
      if (otp && authToken) {
        try {
          const {data} = await validateMfa(otp, authToken)
          dispatch(_redux.actions.auth.login(data.token))
        } catch (e) {
          formik.setFieldValue('otpValue', '')
          formik.setStatus('Invalid OTP')
        }
      }
    },
    [dispatch]
  )

  const sendRequestByHash = useCallback(
    async (username: string, password: string, hash: string) => {
      const {data, status} = await loginWithHash(username, password, hash)
      setMfaLink(data.qrCode)
      if (status === 202) {
        setAuthToken(data.token)
        otpInputRef.current?.focus()
      } else {
        dispatch(_redux.actions.auth.login(data.token))
      }
    },
    [dispatch, otpInputRef, setAuthToken, setMfaLink]
  )

  return {sendRequestByOTP, sendRequestByHash}
}
