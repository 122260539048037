import {useMemo} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {ColumnStyle} from '../../tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../tables/TableColumn'
import {BasicTable} from '../../tables/BasicTable'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {Badge} from '../../badge/Badge'

export interface ProductVoucherModel {
  name: string
  code: string
  qty: number
  type: 'product' | 'voucher'
  isSeated: boolean
}

export interface ProductVoucherTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ProductVoucherModel>
  onRefresh?: () => void
  onDelete?: (code: string[]) => void
  onEdit?: (data: ProductVoucherModel) => void
}

export const ProductVoucherTable = ({
  data,
  onFilter,
  onDelete,
  onEdit,
}: ProductVoucherTableProps) => {
  const tableColumns = useMemo(() => {
    return columns
  }, [])

  return (
    <>
      <BasicTable
        data={data}
        onFilter={onFilter}
        onDelete={onDelete}
        name='Product & Voucher'
        columns={tableColumns}
        onEdit={onEdit}
      />
    </>
  )
}

export const columns: TableColumnOptions<ProductVoucherModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'qty',
    label: 'Qty',
    sortable: true,
    cellStyle: ColumnStyle.NAME,
  },
  {
    field: 'type',
    label: 'Type',
    sortable: true,
    cellStyle: ColumnStyle.NAME,
    render: ({data}) => (
      <Badge
        className='text-nowrap'
        uppercase
        variant={data.type === 'product' ? 'info' : 'warning'}
      >
        {data.type}
      </Badge>
    ),
  },
  {
    field: 'isSeated',
    label: 'Seated?',
    sortable: true,
    hideable: true,
    render: ({data}) =>
      data.isSeated ? (
        <MetronicIcon
          className='svg-icon-success svg-icon-1hx'
          iconType='Navigation'
          iconName='Check'
        />
      ) : null,
  },
]
