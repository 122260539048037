import {CustomersProductProps} from '../../../models/booking-wizard/BulkBookingWizard'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'

interface SeatTableColumnProps {
  customersSeats: TicketModelFulfillBulkParams[] | null
  data: CustomersProductProps
}
export const SeatTableColumn = ({customersSeats, data}: SeatTableColumnProps) => {
  return (
    <div className='d-flex gap-1 flex-wrap w-200px'>
      {customersSeats
        ?.filter((item) => item.customerCode === data.code)
        .map((item) =>
          Object.keys(item.seats.getSeatMapObject()).map((seat, index, seatsArray) => {
            const isFirstLast = index === seatsArray.length - 1
            return item.seats.getSeatMapObject()[seat].map((s, idx, array) => {
              const isLast = idx === array.length - 1
              return (
                <div key={seat + idx}>{`${seat}${s}${
                  !isLast ? ', ' : !isFirstLast ? ', ' : ''
                }`}</div>
              )
            })
          })
        )}
    </div>
  )
}
