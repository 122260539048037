import type {DetailedHTMLProps, InputHTMLAttributes} from 'react'

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface RadioInputProps extends InputProps {
  label: string
}

export const RadioInput = ({label, ...inputProps}: RadioInputProps) => {
  return (
    <div className='form-check form-check-solid form-check-inline'>
      <label className='form-check-label text-muted'>
        <input {...inputProps} className='form-check-input' type='radio' />
        {label}
      </label>
    </div>
  )
}
