import {useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {ColumnStyle} from '../../../../../../components/tables/constants/ColumnStyle'
import { ProductLocationModel } from '../../../../../../models/ems/ReservationModel'
import { FilterTable } from '../../../../../../components/tables/FilterTable'
import { ReservationSeatInputItemValue } from '../../../../../../components/inputs/ReservationSeatInput/ReservationSeatInputItem'
import { useOnChange } from '../../../../../../components/hooks/useOnChange'
import {v4 as uuidv4} from "uuid"
import { ReservationProductLocationTableActions } from '../../../../../default/ems/components/wizards/ReservationWizard/components/ReservationProductLocationTableActions'
import { FormikContextType } from 'formik'
import { ReservationWizardProductVenueStepFormValues } from '../steps/ReservationWizardProductVenueStep'
import { useModalState } from '../../../../../../components/modals/useModalState'
import { PortalAssignSeatsLocationModal } from '../../../../../default/ems/components/wizards/ReservationWizard/modals/PortalAssignSeatsLocationModal'
import { useReservationProductData } from '../../../../../default/ems/components/drawers/ReservationFormDrawer/useReservationProductData'
import { GetProductByCode } from '../../../../redux/CustomerPortalCRUD'

export interface ProductLocationTableProps<T extends ReservationWizardProductVenueStepFormValues> {
  formik: FormikContextType<T>
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ProductLocationModel>
  onRefresh?: () => void
  onDelete?: (data: ProductLocationModel) => void
  onEdit?: (item: any) => void
  eventCode: string | null
  onChangePageNumber?: (pageNumber: number) => void,
  onChangePageSize?: (size: number) => void,
  currentPageSize?: number
  currentPageNumber?: number
}

export const ProductLocationWizardTable = <T extends ReservationWizardProductVenueStepFormValues>({
  data,
  onFilter,
  onDelete,
  onEdit,
  eventCode,
  formik,
  onChangePageNumber,
  onChangePageSize,
  currentPageSize,
  currentPageNumber,
}: ProductLocationTableProps<T>) => {
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const [currentLocations, setCurrentLocations] = useState<ReservationSeatInputItemValue[]>([{
    value: '',
    id: uuidv4(),
    seatMaps: null,
    label: '',
    productCode: '',
    qty: 0
  }])
  const [productCode, setProductCode] = useState('')
  const [productName, setProductName] = useState('')
  const [locationsByProduct, setLocationsByProduct] = useState<Record<string, ReservationSeatInputItemValue[]>>({});
  const [previousProductCode, setPreviousProductCode] = useState<string | null>(null) // Retain
  const [count, setCount] = useState(0)
  // const {hiddenColumns, setHiddenColumns} = useTableOptions({
  //   tableName: 'reservation-product-location-table',
  // })
  const tableColumns = useMemo(() => {
    return columns
  }, [])

  const {locationSearchResults, getLocations} = useReservationProductData({
    productCode: productCode,
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: ProductLocationModel) => {
      openModal()
      setProductCode(data.code)
      setProductName(data.name)
      setCount(data.qty)
    },
    [openModal]
  )
  useOnChange(productCode, () => {
    if (productCode) {
      if (locationsByProduct[productCode]) {
        setCurrentLocations(locationsByProduct[productCode]);
      } else {
        setCurrentLocations([{
          value: '',
          id: uuidv4(),
          seatMaps: null,
          label: '',
          productCode: '',
          qty: 0
        }]);
      }
      getLocations();
      setPreviousProductCode(productCode);
    }
  });

  const rowActions = useCallback(
    (data: ProductLocationModel) => (
      <ReservationProductLocationTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ),
    [getSeatAssignmentHandler, onDelete, onEdit]
  )
  const idExtractor = useCallback((data: ProductLocationModel) => {
    return data.code + uuidv4
  }, [])

  //LOCATIONS
  const handleOnChangeLocation = useCallback(
    (newValues: ReservationSeatInputItemValue[]) => {
      setCurrentLocations(newValues);
      setLocationsByProduct(prev => ({
        ...prev,
        [productCode]: newValues
      }));
    },
    [setCurrentLocations, productCode]
  );

  const mapProduct = useCallback(async (code: string) => {
    try {
      const {data} = await GetProductByCode(code)
      return data
    } catch (e) {
      return null
    }
  }, [])

  const handleSeatedProductSave = useCallback(async () => {
    const fetchedProducts = await Promise.all(
      currentLocations.map((location) => mapProduct(location.productCode))
    )

    const updatedProducts = [...formik.values.products]

    currentLocations.forEach((location, index) => {
      const fetchedProduct = fetchedProducts[index]
      const transformedLocation = {
        id: location.id,
        data: fetchedProduct,
        count: location.qty,
        isSeated: true,
        type: 'product',
        seatMap: location.seatMaps,
        locationCode: location.value,
        isNew: false,
        isFromAddProd: false
      }

      const existingIndex = updatedProducts.findIndex(
        (p) => p.data?.code === location.productCode && p.locationCode === location.value
      )

      if (existingIndex !== -1) {
        updatedProducts[existingIndex] = transformedLocation // Update the existing product
      } else {
        updatedProducts.push(transformedLocation) // Add new product
      }
    })

    // formik.values.products = updatedProducts
    formik.setFieldValue("products", updatedProducts);
    hideModal()
  }, [currentLocations, formik, hideModal, mapProduct])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        // hiddenColumns={hiddenColumns}
        // onHiddenColumnsChange={setHiddenColumns}
        data={data?.data}
        currentPageNumber={data?.page}
        columns={tableColumns}
        totalItems={data?.total || 10}
        actions={rowActions}
        currentPageSize={currentPageSize}
        onChangePageNumber={onChangePageNumber}
        onChangePageSize={onChangePageSize}
      />
      <PortalAssignSeatsLocationModal
        onModalClose={hideModal}
        eventCode={eventCode}
        productCode={productCode}
        productName={productName}
        locations={locationSearchResults || []}
        selectedLocations={currentLocations}
        onAdd={handleOnChangeLocation}
        onSave={handleSeatedProductSave}
        {...getModalState()}
        qty={count}
      />
    </>
  )
}

const columns: TableColumnOptions<ProductLocationModel>[] = [
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    cellStyle: ColumnStyle.CODE,
  },
  {
    field: 'qty',
    label: 'Qty',
    sortable: true,
    hideable: true,
    cellStyle: ColumnStyle.NAME,
  },
]
