import {useCallback, useState} from 'react'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {ActivityModel} from '../../../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {LocationModel} from '../../../../../../models/acs/LocationModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {FilterModel} from '../../../../../../models/FilterModel'
import {
  GetCustomerList,
  GetLocationsReservationByEvent,
  GetProductsReservationByEvent,
  GetReservationByCode,
} from '../../../../redux/CustomerPortalCRUD'

import {useOnChange} from '../../../../../../components/hooks/useOnChange'
import {ReservationPortalModel} from '../../../../../../models/ems/ReservationMedel'

export interface UseReservationFormDataParams {
  eventCode?: string | null
  reservationCode?: string
  event?: EventModel
  activity?: ActivityModel
}

export const useReservationFormData = ({
  eventCode,
  reservationCode,
}: UseReservationFormDataParams) => {
  const {setIsLoading, isLoading} = useLoadingState()
  const [products, setProducts] = useState<ProductModel[]>([])
  const [locations, setLocations] = useState<LocationModel[]>([])
  const [customers, setCustomers] = useState<GlobalSearchModel<CustomerModel>>()
  const [reservation, setReservation] = useState<ReservationPortalModel>()
  const {pushError} = useAlerts()

  const searchCustomers = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetCustomerList({
          ...filter,
          filters: {
            ...filter?.filters,
            type: ['reseller'],
            status: ['active', 'pending'],
          },
        })
        if (Array.isArray(data.data))
          setCustomers(data as unknown as GlobalSearchModel<CustomerModel>)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  const refreshProductsList = useCallback(
    async (filter?: FilterModel) => {
      if (eventCode) {
        const doneLoading = setIsLoading('products')
        try {
          // const {data} = await GetEventNonSeatedProducts(eventCode, newProdFilters)
          // setProducts(data.products)
          const {data} = await GetProductsReservationByEvent(eventCode)
          setProducts(data)
        } catch (e: any) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [eventCode, pushError, setIsLoading]
  )

  const refreshLocationsList = useCallback(async () => {
    if (eventCode) {
      const doneLoading = setIsLoading('locations')
      try {
        const {data} = await GetLocationsReservationByEvent(eventCode)
        setLocations(data)
      } catch (e: any) {
        pushError(e)
      } finally {
        doneLoading()
      }
    }
  }, [eventCode, pushError, setIsLoading])

  const getReservation = useCallback(async () => {
    const doneLoading = setIsLoading('reservation')
    if (reservationCode) {
      const {data} = await GetReservationByCode(reservationCode)
      setReservation(data)
      doneLoading()
    }
  }, [reservationCode, setIsLoading])

  useOnChange(reservationCode, () => {
    getReservation()
  })

  const refreshData = useCallback(() => {
    refreshProductsList()
    refreshLocationsList()
  }, [refreshLocationsList, refreshProductsList])

  return {
    refreshData,
    products,
    locations,
    customers,
    searchCustomers,
    isLoading,
    reservation,
  }
}
