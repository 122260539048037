import {useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {actions} from '../../../redux/CustomerPortalRedux'
import {
  bulkBookingValidationSchema,
  EMPTY_FORM_VALUES,
  getBulkPayload,
} from '../../../../../components/BookingWizard/hook/BulkBookingWizardHelper'

import {useBookingWiard} from './hook/useBookingWizard'
import {BookingBulkWizard} from './BookingBulkWizard'
import {PostBulkBooking} from '../../../redux/CustomerPortalCRUD'

export interface BookingCreateBulkWizardProps {
  onSuccess?: () => void
  event?: EventModel | ActivityModel | null
  step?: number
}

interface RouterProps {
  params: {
    eventCode: string
    customerCode: string
  }
}

export const BookingCreateBulkWizard = ({onSuccess, event, step}: BookingCreateBulkWizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {pushError, push} = useAlerts()

  const dispatch = useDispatch()
  const {
    params: {customerCode},
  }: RouterProps = useRouteMatch()

  const {customer, getCustomer} = useBookingWiard()

  useOnChange(customerCode, () => {
    if (customerCode) getCustomer(customerCode)
  })

  useOnChange(step, () => {
    if (step) setCurrentStep(step)
  })

  const formik = useFormik({
    initialValues: EMPTY_FORM_VALUES,
    validationSchema: bulkBookingValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const payload = getBulkPayload(values)
        const {data} = await PostBulkBooking(payload)
        if (data) {
          push({
            message: `Booking created successfully`,
            variant: 'success',
            timeout: 3000,
          })
          dispatch(actions.bookingForm.resetBookingForm())
          onSuccess?.()
        }
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useOnChange(customer, () => {
    if (customer) formik.setFieldValue('customer', customer)
  })

  const handleStepChange = useCallback(
    async (step: number) => {
      dispatch(actions.bookingBulkForm.setBookingBulkForm(formik.values))
      setCurrentStep(step)
    },
    [dispatch, formik.values]
  )

  return (
    <BookingBulkWizard
      formik={formik}
      step={currentStep}
      onStepChange={handleStepChange}
      event={event}
      customer={customer}
    />
  )
}
