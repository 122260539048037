import {useMemo} from 'react'
import styles from './ColorLegend.module.scss'

export interface ColorLegendItem {
  color: string
  label: string
  key: string
}

export interface ColorLegendProps {
  legends: ColorLegendItem[]
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  className?: string
}

export const ColorLegend = ({legends, onMouseMove, onMouseLeave, className}: ColorLegendProps) => {
  const colors = useMemo(() => {
    return legends.map((legend) => (
      <div
        key={legend.color}
        className={styles.item}
        onMouseMove={(e) => onMouseMove?.(e, legend.key)}
        onMouseLeave={(e) => onMouseLeave?.(e, legend.key)}
      >
        <div style={{backgroundColor: legend.color}} className={styles.colorBox}></div>
        <div>{legend.label}</div>
      </div>
    ))
  }, [legends, onMouseLeave, onMouseMove])

  return <div className={className}>{colors}</div>
}
