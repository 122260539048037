import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {ProductLocationModalType} from '../steps/ReservationWizardProductVenueStep'
import {Button} from '../../../../../../../components/inputs/Button'
import {LoadingSpinner} from '../../../../../../../components/utils/LoadingSpinner'
import {Modal} from 'react-bootstrap'
import { MetronicIconButton } from '../../../../../../../components/inputs/MetronicIconButton'
import { ProductLocationModel } from '../../../../../../../models/ems/ReservationModel'

interface EditProductLocationModalProps {
  modalType: ProductLocationModalType
  onModalClose: () => void
  onEdit: (type: ProductLocationModalType, value: ProductLocationModel) => void
  data: ProductLocationModel
}

export const EditProductLocationModal = ({
  modalType,
  onModalClose,
  onEdit,
  data,
}: EditProductLocationModalProps) => {
  const [value, setValue] = useState<ProductLocationModel>(data)

  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ProductLocationModel = {...data, qty: Number(count)}
      setValue(newValue)
    },
    [data]
  )

  const handleEdit = useCallback(() => {
    if (modalType === 'product' && value) onEdit(modalType, value)
    if (modalType === 'voucher' && value) onEdit(modalType, value)

  }, [modalType, onEdit, value])

  const updatedData = useMemo(() => {
    return {...data, qty: value.qty}
  }, [data, value])

  return (
    <Modal
      className='center-modal overflow-visible'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={modalType !== undefined}
      onHide={onModalClose}
      animation={false}
    >
      <div className='px-7 py-5'>
        <>
        <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>{`Edit ${data.name || ''}`}</h2>
          <label className='text-start mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={0}
          />
        </>
        <div className='d-flex gap-3 justify-content-end mt-5'>
          <Button className='me-1' onClick={onModalClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleEdit}>
            <LoadingSpinner loading={false}>Save</LoadingSpinner>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
