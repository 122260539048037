import moment from 'moment'
import {DateRange} from '../../../utils/DateRange'

export const getStartAndEndDateOfMonthOfDate = (date: Date): DateRange => {
  const start = moment(date).startOf('month')
  const end = moment(date).endOf('month')

  return new DateRange(start.toDate(), end.toDate())
}

export const addDays = (date: Date, number: number): Date => {
  return moment(date).add(number, 'days').toDate()
}

export const subtractDays = (date: Date, number: number): Date => {
  return moment(date).subtract(number, 'days').toDate()
}

export const isSameDay = (a: Date, b: Date) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}
