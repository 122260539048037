import {forwardRef} from 'react'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {Drawer} from '../../../Drawer/Drawer'
import {NavDrawerMenuItem} from './NavDrawerMenuItem'

export interface NavDrawerProps {
  menus: NavigationSectionMenuItem[]
}

export const NavDrawer = forwardRef<Drawer, NavDrawerProps>(({menus}, ref) => {
  return (
    <Drawer ref={ref}>
      <div
        className='menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-bold'
        data-kt-menu='true'
      >
        {menus.map((item, i) => (
          <NavDrawerMenuItem key={i} item={item} />
        ))}
      </div>
    </Drawer>
  )
})
