import clsx from 'clsx'
import {ReactNode, useCallback, useEffect, useRef} from 'react'
import {createPopper, Instance} from '@popperjs/core'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import styles from './PopupFilterButton.module.scss'
import {useClickAwayListener} from '../../../hooks/useClickAwayListener'
import {RootPortal} from '../../../utils/RootPortal'

export interface PopupFilterButtonProps {
  hasAdvancedFilters?: boolean
  children?: ReactNode
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const PopupFilterButton = ({
  children,
  hasAdvancedFilters,
  isOpen,
  onClose,
  onOpen,
}: PopupFilterButtonProps) => {
  const popperRef = useRef<Instance | null>(null)

  const iconButtonRef = useRef<HTMLButtonElement>(null)

  const handleClickAway = useCallback(() => {
    onClose()
  }, [onClose])

  const handleOpenAdvancedFilters = useCallback(() => {
    onOpen()
    popperRef.current?.update()
  }, [onOpen])

  const ref = useClickAwayListener<HTMLDivElement>({onClickAway: handleClickAway})

  useEffect(() => {
    if (iconButtonRef.current && ref.current) {
      const popper = createPopper(iconButtonRef.current, ref.current, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      })
      popperRef.current = popper
      return () => popper.destroy()
    }
  }, [ref])

  return (
    <>
      <MetronicIconButton
        ref={iconButtonRef}
        onClick={handleOpenAdvancedFilters}
        iconType='Text'
        iconName='Filter'
        activeColor={hasAdvancedFilters ? undefined : 'primary'}
        size='md'
        variant={isOpen ? 'primary' : undefined}
        color={hasAdvancedFilters ? 'warning' : undefined}
        tooltip='Open filters'
      />
      <RootPortal>
        <div
          ref={ref}
          className={clsx(
            'bg-light-primary p-5 m-0 rounded',
            {
              'd-none': !isOpen,
            },
            styles.menu
          )}
        >
          <div className={clsx(styles.arrow)} data-popper-arrow></div>
          {children}
        </div>
      </RootPortal>
    </>
  )
}
