import {FormikContextType, useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {CustomerForm, roleFormValidationSchema, CustomerFormValues} from '../forms/CustomerForm'

import {PostCustomer} from '../../../../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {Button} from '../../../../../../components/inputs/Button'
import {
  getCustomerPayload,
  initialValues,
  useFormDrawerCustomerData,
} from '../../../../hooks/useFormDrawerCustomerData'
import {useDispatch} from 'react-redux'
import * as RxCustPort from '../../../../redux/CustomerPortalRedux'
import {BookingWizardCustomerStepFormValues} from '../../../../../../models/booking-wizard/BookingWizard'

interface BookingWizardCreateCustomerStepProps<T extends BookingWizardCustomerStepFormValues> {
  formik: FormikContextType<T>
  handleCreateCustomer: () => void
}

export const BookingWizardCreateCustomerStep = <T extends BookingWizardCustomerStepFormValues>({
  formik,
  handleCreateCustomer,
}: BookingWizardCreateCustomerStepProps<T>) => {
  const formDrawer = useFormDrawerCustomerData()

  const [isExistedCustomer, setIsExistedCustomer] = useState<CustomerModel>()
  const {pushError} = useAlerts()

  const dispatch = useDispatch()

  const createNewCustomer = useCallback(
    async (values: CustomerFormValues) => {
      try {
        const payload = getCustomerPayload({values})
        const {data} = await PostCustomer(payload)
        if (data) {
          formik.setFieldValue('customer', data)
          handleCreateCustomer()
        }

        dispatch(RxCustPort.actions.customers.createSuccess())
      } catch (e) {
        dispatch(RxCustPort.actions.customers.createFailed())
        throw e
      }
    },
    [dispatch, formik, handleCreateCustomer]
  )

  const customerFormik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        await createNewCustomer(values)
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const disabledFields = useMemo(() => {
    return {
      type: true,
    }
  }, [])

  useEffect(() => {
    customerFormik.setFieldValue('type', 'customer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <form
        className='form-drawer-container drawer-container'
        noValidate
        onSubmit={customerFormik.handleSubmit}
      >
        <CustomerForm
          countries={formDrawer.countries}
          formik={customerFormik}
          setIsExistedCustomer={setIsExistedCustomer}
          isExistedCustomer={isExistedCustomer}
          disabledFields={disabledFields}
        />
        <div className='w-100 text-end'>
          <Button
            variant='primary'
            className='btn'
            disabled={customerFormik.isSubmitting || !customerFormik.isValid}
            type='submit'
          >
            Create & Next
          </Button>
        </div>
      </form>
    </div>
  )
}
