import {useCallback, useEffect, useMemo, useState} from 'react'
import {debounce, omit} from 'lodash'
import {AdvancedFilterTypes, FilterModel} from '../../models/FilterModel'
import {SortValue} from '../tables/SortValue'
import {useOnMount} from './useOnMount'

export interface UseFilterStateData {
  filter: FilterModel
  setPageNumber: (page: number) => void
  setPageSize: (size: number) => void
  setSearch: (keyword: string) => void
  setSortColumn: (sort: SortValue) => void
  pageNumber: number
  pageSize: number
  search: string
  sortColumn?: SortValue
  advancedFilters: Partial<Record<string, AdvancedFilterTypes>>
  getAdvancedFilterValue: (key: string) => AdvancedFilterTypes | undefined
  setAdvancedFilterValue: (key: string, value: AdvancedFilterTypes) => void
  setAdvancedFilterValues: (values: Partial<Record<string, AdvancedFilterTypes>>) => void
  clearAdvancedFilterValue: (key: string | string[]) => void
  clearFilters: () => void
  hasFilters: boolean
  groupBy?: string
  setGroupBy: (value?: string) => void
}

export interface UseFilterStateOptions {
  debounceDelay?: number
  initialFilters?: FilterModel
  filters?: FilterModel
  filterOnMount?: boolean
}

export const useFilterState = (
  onFilter: (filter: FilterModel) => void,
  options: UseFilterStateOptions = {}
): UseFilterStateData => {
  const {debounceDelay, initialFilters, filters} = options
  const [sortColumn, setSortColumn] = useState<SortValue | undefined>(
    getInitialSortColumn(initialFilters)
  )
  const [pageNumber, setPageNumber] = useState(initialFilters?.page || 1)
  const [pageSize, setPageSize] = useState(initialFilters?.limit || 10)
  const [search, setSearch] = useState<string>(
    typeof initialFilters?.filters?.search === 'string' ? initialFilters?.filters?.search : ''
  )
  const [advancedFilters, setAdvancedFilters] = useState<
    Partial<Record<string, AdvancedFilterTypes>>
  >(omit(initialFilters?.filters || {}, 'search'))

  const debouncedOnFilter = useMemo(() => {
    return debounce(onFilter, debounceDelay === undefined ? 500 : debounceDelay)
  }, [onFilter, debounceDelay])

  const stateFilters = useMemo(() => {
    const newFilter: FilterModel = {}

    if (pageNumber) newFilter.page = pageNumber
    if (pageSize) newFilter.limit = pageSize
    if (sortColumn) {
      newFilter.sortField = sortColumn[0]
      newFilter.sortOrder = sortColumn[1] ? 'ASC' : 'DESC'
    }
    newFilter.filters = {...advancedFilters, search}
    return newFilter
  }, [pageNumber, pageSize, sortColumn, search, advancedFilters])

  const getAdvancedFilterValue = useCallback(
    (key: string) => {
      if (filters) {
        return filters?.filters?.[key]
      }
      return advancedFilters[key]
    },
    [advancedFilters, filters]
  )

  const setAdvancedFilterValue = useCallback(
    (key: string, value: AdvancedFilterTypes) => {
      if (filters) {
        const newFilters = {
          ...filters,
          filters: {
            ...filters?.filters,
            [key]: value,
          },
        }
        onFilter(newFilters)
      } else {
        setAdvancedFilters((previousValue) => {
          const newValue = {...previousValue}
          newValue[key] = value
          return newValue
        })
      }
    },
    [filters, onFilter]
  )

  const setAdvancedFilterValues = useCallback(
    (values: Partial<Record<string, AdvancedFilterTypes>>) => {
      if (filters) {
        const newValue = {
          ...filters,
          filters: {
            ...filters.filters,
            ...values,
          },
        }
        onFilter(newValue)
      } else {
        const newValue = {...advancedFilters, ...values}
        setAdvancedFilters(newValue)
      }
    },
    [advancedFilters, filters, onFilter]
  )

  const clearFilters = useCallback(() => {
    if (filters) {
      onFilter({
        ...initialFilters,
        filters: {
          ...omit(initialFilters?.filters, 'search'),
        },
      })
    } else {
      setSearch('')
      setAdvancedFilters({
        ...omit(initialFilters?.filters, 'search'),
      })
    }
  }, [filters, initialFilters, onFilter])

  const clearAdvancedFilterValue = useCallback(
    (key: string | string[]) => {
      if (filters) {
        const newValue = {...filters, filters: {...filters.filters}}

        if (typeof key === 'string') {
          delete newValue.filters[key]
        } else {
          key.forEach((key) => {
            delete newValue.filters[key]
          })
        }
        onFilter?.(newValue)
      } else {
        setAdvancedFilters((previousValue) => {
          const newValue = {...previousValue}

          if (typeof key === 'string') {
            delete newValue[key]
          } else {
            key.forEach((key) => {
              delete newValue[key]
            })
          }

          return newValue
        })
      }
    },
    [filters, onFilter]
  )

  const hasFilters = useMemo(() => {
    const initialAdvancedFilters = omit(initialFilters?.filters || {}, 'search')
    if (filters) {
      return (
        Boolean(filters.filters?.search) ||
        hasAdvancedFilterChanged(initialAdvancedFilters, omit(filters?.filters, 'search'))
      )
    } else {
      return (
        Boolean(search) ||
        hasAdvancedFilterChanged(initialAdvancedFilters, omit(advancedFilters, 'search'))
      )
    }
  }, [advancedFilters, filters, initialFilters?.filters, search])

  const handleSetPageNumber = useCallback(
    (page: number) => {
      if (filters) {
        onFilter({
          ...filters,
          page,
        })
      } else {
        setPageNumber(page)
      }
    },
    [filters, onFilter]
  )

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      if (filters) {
        onFilter({
          ...filters,
          limit: pageSize,
        })
      } else {
        setPageSize(pageSize)
      }
    },
    [filters, onFilter]
  )

  const handleSearch = useCallback(
    (search: string) => {
      if (filters) {
        onFilter({
          ...filters,
          filters: {
            ...filters?.filters,
            search,
          },
        })
      } else {
        setSearch(search)
      }
    },
    [filters, onFilter]
  )

  const handleSetSortColumn = useCallback(
    (sort: SortValue) => {
      if (filters) {
        onFilter({
          ...filters,
          sortField: sort[0],
          sortOrder: sort[1] ? 'ASC' : 'DESC',
        })
      } else {
        setSortColumn(sort)
      }
    },
    [filters, onFilter]
  )

  const sortColumnValue = useMemo((): SortValue | undefined => {
    if (filters) {
      if (filters.sortField && filters.sortOrder) {
        return [filters.sortField, filters.sortOrder === 'ASC']
      }
      return
    }
    return sortColumn
  }, [filters, sortColumn])

  const setGroupBy = useCallback(
    (value: string | undefined) => {
      onFilter({
        ...filters,
        groupBy: value,
      })
    },
    [filters, onFilter]
  )

  useEffect(() => {
    if (!filters) {
      debouncedOnFilter(stateFilters)
    }
  }, [debouncedOnFilter, stateFilters, filters])

  useOnMount(() => {
    if (filters && options.filterOnMount) {
      onFilter(filters)
    }
  })

  return {
    hasFilters,
    filter: stateFilters,
    setPageNumber: handleSetPageNumber,
    setPageSize: handleSetPageSize,
    setSearch: handleSearch,
    setSortColumn: handleSetSortColumn,
    advancedFilters,
    pageNumber,
    pageSize,
    search: filters ? String(filters.filters?.search || '') : search,
    sortColumn: sortColumnValue,
    getAdvancedFilterValue,
    setAdvancedFilterValue,
    setAdvancedFilterValues,
    clearAdvancedFilterValue,
    clearFilters,
    groupBy: filters?.groupBy,
    setGroupBy,
  }
}

const getInitialSortColumn = (filter?: FilterModel): SortValue | undefined => {
  if (filter?.sortField && filter?.sortOrder) {
    return [filter.sortField, filter.sortOrder === 'ASC' ? true : false]
  }
}

const hasAdvancedFilterChanged = (
  previous: Required<FilterModel>['filters'],
  current: Required<FilterModel>['filters']
): boolean => {
  return compareEquality(previous, current) || compareEquality(current, previous)
}

// Returns true if b has all fields of a
const compareEquality = (
  a: Required<FilterModel>['filters'],
  b: Required<FilterModel>['filters']
) => {
  return Object.entries(a).some(([key, aValue]) => {
    if (key in b) {
      const bValue = b[key]
      if (typeof bValue === 'string') {
        return bValue !== aValue
      } else if (Array.isArray(bValue) && Array.isArray(aValue)) {
        return bValue.length !== aValue.length || bValue.some((value) => !aValue.includes(value))
      }
    }
    return true
  })
}
