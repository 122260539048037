import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {SwalUtils} from '../../../utils/SwalUtils'
import {DisableVoucher, DownloadVouchers} from '../redux/CustomerPortalCRUD'
import { BlobUtils } from '../../../utils/BlobUtils'

interface Props {
  onRefresh?: () => void
  onRefreshCallback?: () => void
}
export const useVoucherTableActions = ({onRefresh, onRefreshCallback}: Props) => {
  const {isKeyLoading, isLoading, setIsLoading} = useLoadingState()
  const {pushError, push} = useAlerts()

  const disable = useCallback(
    async (codes: string[]) => {
      const confirmation = await SwalUtils.confirm({
        titleText: 'Disable Voucher',
        text: 'Are you sure?',
        confirmButtonText: "Don't Disable!",
        icon: 'question',
        cancelButtonText: 'Disable!',
      })
      if (!confirmation.isConfirmed) {
        const doneLoading = setIsLoading(codes)
        try {
          await DisableVoucher(codes)
          push({
            message: `successfully disabled.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleDownload = useCallback(
    async (codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        const {data, headers} = await DownloadVouchers(codes)
       if(data) {
        let name = headers['content-disposition']
        name = name
          .replace('attachment;', '')
          .replace('filename=', '')
          .split('(')[0]
          .replaceAll('"', '')
          .trim()
        BlobUtils.downloadBlob(data, name)}
        push({
          message: `Download initiated.`,
          timeout: 5000,
          variant: 'success',
        })
      } catch (e: any) {
        pushError(e)
      } finally {
        onRefresh && onRefresh()
        doneLoading()
      }
    },
    [onRefresh, push, pushError, setIsLoading]
  )

  const handleDisableBulk = useCallback(
    (data: VoucherModel[]) => {
      disable(data.map((bookings) => bookings.code))
    },
    [disable]
  )

  const handleDisableSingle = useCallback(
    (data: VoucherModel) => {
      disable([data.code])
    },
    [disable]
  )

  const downloadSingle = useCallback(
    (data: VoucherModel) => {
      handleDownload([data.code])
    },
    [handleDownload]
  )

  const handleDownloadBulk = useCallback(
    async (data: VoucherModel[]) => {
      for (let item of data) {
        await handleDownload([item.code])
      }
    },
    [handleDownload]
  )


  return useMemo(
    () => ({
      isKeyLoading,
      isLoading,
      handleDisableBulk,
      handleDisableSingle,
      downloadSingle,
      handleDownloadBulk,
    }),
    [isKeyLoading, isLoading, handleDisableBulk, handleDisableSingle, downloadSingle, handleDownloadBulk]
  )
}

export const isVoucherShareable = (ticket: VoucherModel) => {
  return ticket.status === 'active' || ticket.status === 'sent'
}

export const isVoucherDownloadable = (voucher: VoucherModel) => {
  return voucher.status === 'active' && voucher.voucherIsDownloadable
}