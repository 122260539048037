import {useMemo} from 'react'
import {
  HorizontalBarChart,
  BarChartDatum,
} from '../../../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {WidgetF07Model} from '../../../../../../models/fnb/WidgetModel'
import {VoucherIssuedTooltip} from './VoucherIssuedTooltip'

export interface VoucherIssuedBarChartWidgetProps {
  data?: WidgetF07Model[]
}

export const VoucherIssuedBarChartWidget = ({data}: VoucherIssuedBarChartWidgetProps) => {
  const datum = useMemo((): BarChartDatum[] => {
    if (data) {
      return data.map((d) => ({
        key: d.voucherName,
        label: d.voucherName,
        value: d.quantity,
      }))
    }
    return []
  }, [data])

  return (
    <HorizontalBarChart
      datum={datum}
      xLabel='Vouchers'
      yLabel='Total Issued'
      tooltip={<VoucherIssuedTooltip data={data} />}
    />
  )
}
